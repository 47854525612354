import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getAllBudgets(obj){
    console.log(`${api_url}dashboard/budgets?${obj}`);
    return await https.get(`${api_url}dashboard/budgets?${obj}`);
}

export async function getBudget(id){
    return await https.get(`${api_url}dashboard/budgets/${id}`);
}

export async function deleteBudget(id){
    return await https.delete(`${api_url}dashboard/budgets/${id}`);
}

export async function updateBudget(id, data){
    return await https.put(`${api_url}dashboard/budgets/${id}`, data);
}

export async function createBudget(data){
    return await https.post(`${api_url}dashboard/budgets`, data);
}