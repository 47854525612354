import React, { useContext, useState } from "react";
import "./overview.scss";
import { useEffect } from "react";
import { useLocation } from "react-router";
// import { Link } from "react-router-dom";
import TitleContext from "../../Contexts/TitleContext";
// import { basename } from "../../Configurations/config";
// import PermissionsContext from '../../Contexts/permissionsContext';
import { getStatistics } from "../../Apis/overview";
import Charts from "../../Components/Charts/Charts";
export default function Overview() {
	const { pathname } = useLocation();
	let { changeTitle } = useContext(TitleContext);
	const [services, setServices] = useState();
	const [projects, setProjects] = useState();
	const [orders, setOrders] = useState();
	const [users, setUsers] = useState();
	// const { Permissions } = useContext(PermissionsContext);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	// console.log(services);

	useEffect(() => {
		getStatistics().then((res) => {
			setServices(res.data.data.services);
			setProjects(res.data.data.projects);
			setOrders(res.data.data.orders);
			setUsers(res.data.data.users);
		});
		changeTitle(`overview`);

		// eslint-disable-next-line
	}, []);

	const serviceChart = () => {
		return <Charts title="Services Overview"
			data={[
				services ? services["status_active_count"] : 0,
				services ? services["status_pending_count"] : 0,
				services ? services["status_rejected_count"] : 0,
				services ? services["status_archived_count"] : 0,
				services ? services["total"] : 0
			]}
			categories={["Pending", "Active", "Rejected", "Archived", "Total"]} />
	}

	const ProjectsCahrt = () => {
		return <Charts title="Projects Overview" data={[
			projects && projects["status_pending_count"],
			projects && projects["status_active_count"],
			projects && projects["status_rejected_count"],
			projects && projects["status_archived_count"],
			projects && projects["total"]
		]}
			categories={["Pending", "Active", "rejected", "archived", "Total"]} />
	}
	const orderCharts = () => {
		return <Charts title="Orders Overview"
			data={[
				orders && orders["stage_progress_count"],
				orders && orders["status_complete_count"],
				orders && orders["status_pending_count"],
				orders && orders["stage_close_count"],
				orders && orders["total"]
			]}
			categories={["progress", "complete", "pending", "close", "Total"]} />

	}
	const usersCharts = () => {
		return <Charts title="Users Overview"
			data={[
				users && users["verified_users"],
				users && users["unverified_users"],
				users && users["newUsersIn24h"],
				users && users["lastSeen24h"],

			]}
			categories={["verified", "unverified", "New-Users / 24h", "Last-Seen / 24h", "Total"]} />

	}

	useEffect(() => {
		serviceChart()
		ProjectsCahrt()
		orderCharts()
		usersCharts()
	}, [projects, services, orders, users])

	return (
		// Permissions?.overview?.view ?
		<div className="componentGlobalWrapper d-flex flex-wrap justify-content-evenly align-items-evenly p-1">
			{services && serviceChart()}
			{projects && ProjectsCahrt()}
			{orders && orderCharts()}
			{users && usersCharts()}
		</div>
	);
}
