import React from "react";
import "./dashboard.scss";
import AllRoutes from "./../../Routes/Routes";
import Header from "../../Components/Header/Header";
import Aside from "../../Components/Aside/Aside";

export default function DashboardLayout() {
  return (
    <div className="d-flex justify-content-end mx-0 px-0">
      <div
        className={`asidePart offcanvas d-lg-none offcanvas-start`}
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabIndex="-1"
        id="offcanvasScrolling"
      >
        <Aside />
      </div>
      <div className="asidePart d-none d-lg-block">
        <Aside />
      </div>
      <div className="remainPart">
        <div>
            <Header />
        </div>
        <div className="renderdComponents">
          <AllRoutes />
        </div>
      </div>
    </div>
  );
}
