import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getProjects(obj){
    return await https.get(`${api_url}dashboard/projects?${obj}`);
}

export async function getProject(id){
    return await https.get(`${api_url}dashboard/projects/${id}`);
}

export async function acceptProject(id){
    return await https.post(`${api_url}dashboard/projects/${id}/accept`);
}

export async function rejectProject(id,obj){
    return await https.post(`${api_url}dashboard/projects/${id}/reject`,obj);
}

export async function deleteProject(id){
    return await https.delete(`${api_url}dashboard/projects/${id}`);
}