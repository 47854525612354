import "./App.css";
import React from "react";
import { Route, Routes } from "react-router";
import ProtectedRoutes from "./protectedRoutes";
import DashboardLayout from "./Layouts/dashboardLayout/dashboardLayout";
import AuthLayout from "./Layouts/authLayout/authLayout";
import { basename } from "./Configurations/config";
import { TitleContextProvider } from "./Contexts/TitleContext";
import { PermissionsContextProvider } from "./Contexts/permissionsContext";
import { NotificationContextProvider } from "./Contexts/notificationContext";
function App() {
  return (
    <React.Fragment>
      <PermissionsContextProvider>
        <TitleContextProvider>
          <NotificationContextProvider>
            <div className={`englishWrapper`}>
              <Routes>
                {/* ----- Admin Login ------*/}
                <Route path={`${basename}/*`} element={<AuthLayout />} />
                {/* protected routes */}
                <Route element={<ProtectedRoutes />}>
                  <Route
                    path={`${basename}/dashboard/*`}
                    element={<DashboardLayout />}
                  />
                </Route>
              </Routes>
            </div>
          </NotificationContextProvider>
        </TitleContextProvider>
      </PermissionsContextProvider>
    </React.Fragment>
  );
}

export default App;
