import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import { basename } from '../../Configurations/config';
import authImg from '../../assets/images/dashboardBackground.png';
import Login from '../../Components/Login/Login';
import ResetPassword from '../../Components/Login/ResetPassword';
import logo from '../../assets/images/logo.png';
import './auth.scss';
import { getAdminProfileInfo } from '../../Apis/profile';

export default function AuthLayout() {
    let navigate = useNavigate();
    const [authorized, setauthorized] = useState('')

    useEffect(() => {
        let token = localStorage.getItem("need4_Admin_Token");
        if (token !== null) {
            getAdminProfileInfo().then(() => {
                navigate(`${basename}/dashboard/overview`);
                setauthorized('authorized');
            }).catch(() => {
                setauthorized('not-authorized');
            })
        } else {
            setauthorized('not-authorized');
        }
        // eslint-disable-next-line
    }, [])
    return (
        <>
            {authorized === 'not-authorized' && <div className='position-relative' >
                <div className="login-bg-img">
                    <section className='login-wrapper'>
                        <img className='login-form-image d-none d-md-block' src={authImg} alt="login form" />
                        <div className='login-form'>
                            <div className='formHeader'>
                                <img src={logo} alt="logo" />
                                {/* <h1>At</h1> */}
                            </div>
                            <Routes>
                                <Route path={`${basename}/`} element={<Login />} />
                                <Route path={`${basename}/reset-password`} element={<ResetPassword />} />
                                <Route path={`${basename}/*`} element={<Login />} />
                            </Routes>
                        </div>
                    </section>
                </div>
                <div className='login-bg-layer'></div>
            </div>}
        </>
    )
}
