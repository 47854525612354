import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateFee } from '../../../Apis/fees';

export default function UpdateFees() {
  const { id } = useParams();
    // onsubmit
    const onSubmit = async (data) => {
        //to send request
          console.log(data,id)
        return await updateFee(id,data).then(res => {
            return 'done';          
        }).catch(err => {
          console.log(err.response)
            return err.response.data.errors;
        })
    };

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
