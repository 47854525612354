import React from 'react'
import { useParams } from 'react-router';
import { createMainFaq } from '../../../Apis/faq';
import Form from './Form';

export default function CreateMainFaqs() {
  const {id} = useParams();
  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createMainFaq(data, id ? 'faqs':'section-faqs').then(res => {
      return 'done';     
    }).catch(err =>{ 
      console.log(err.response.data);
      return err.response.data.errors; 
    })
}

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
