import React, { useState, useContext, useEffect, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import search from "../../assets/icons/search.svg";
import delete_icon from "../../assets/icons/delete.svg";
import '../tables.scss';
import '../forms.scss';
import { useLocation } from 'react-router-dom';
import Pagination from '../../Components/Pagination/Pagination';
import { deleteContactMsg, getContactMsgs, toggleRead } from '../../Apis/contactus';
import DeleteModal from '../../Utilities/deleteModal';
import moment from 'moment';
import TitleContext from '../../Contexts/TitleContext';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useReactToPrint } from 'react-to-print';
/* import { BsEnvelope, BsEnvelopeOpen } from "react-icons/bs"; */
import './Contact.scss'
import PermissionsContext from '../../Contexts/permissionsContext';
import MessageModal from '../../Utilities/messageModal';
import read from "../../assets/icons/read.svg";
import unread from "../../assets/icons/unread.svg";

export default function ContactUs() {
    const [allContacts, setAllContacts] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', date_from: '', date_to: '' });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '', date_from: '', date_to: '' });
    const [deleted, setDeleted] = useState("");
    const [deletedName, setDeletedName] = useState('msg');
    const [description, setDescription] = useState('')
    const { Permissions } = useContext(PermissionsContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        changeTitle(`contact msgs`) 
        // eslint-disable-next-line
    }, [pathname]);

    function getAllContacts(obj) {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&date_from=${filter.date_from}&date_to=${filter.date_to}`;
        getContactMsgs(params).then((res) => {
            setAllContacts(res.data.data);
            console.log(res?.data.data);
            let total = res?.data?.pagination?.total;
            changeTitle(`contact msgs (${total})`);
            setPageCount(Math.ceil(total / filter?.per_page));
        }).catch((err) => {
            console.log(err.response)
        })
    }

    useEffect(() => {
        getAllContacts(filterObj);

        // eslint-disable-next-line
    }, []);

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        thisObj.date_from = filterObj2.date_from;
        thisObj.date_to = filterObj2.date_to;
        setfilterObj(thisObj);
        getAllContacts(thisObj)
    }

    async function onSubmitDelete() {
        return await deleteContactMsg(deleted).then(res => {
            getAllContacts(filterObj);
            return "done";
        }).catch(err => {
            console.log(err.response)
            return err.response.data.message;
        })
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getAllContacts(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        thisObj2.date_from = filter.date_from;
        thisObj2.date_to = filter.date_to;
        setfilterObj2(thisObj2);
    }

    function clickDelete(item) {
        setDeleted(item.id)
        console.log(item)
        let msg = `this msg ${item?.message?.message?.substring(0, 20)}`
        setDeletedName(msg)
    }

    /* ******************** export pdf **************************** */
    const componentRef = useRef();
    const { afterToday } = DateRangePicker;
    let cloneRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {
            cloneRef = componentRef.current.cloneNode(true);
            //cloneRef.firstChild.firstChild.firstChild.classList.add('d-none');
            cloneRef.firstChild.firstChild.lastChild.classList.add('d-none');
            for (let i = 0; i < cloneRef.lastChild.childNodes.length; i++) {
                //if (i % 2 === 0) {
                //cloneRef.lastChild.childNodes[i].firstChild.classList.add('d-none');
                cloneRef.lastChild.childNodes[i].lastChild.classList.add('d-none');
                //}
            }
        },
        content: () => cloneRef,
    });
    /* *********************************************************** */

    function markContactMsgRead(id, status) {
        if (status === 'unread') {
            toggleRead(id).then(() => {
                getAllContacts(filterObj)
            })
        }
    }

    function getDateValue(data) {
        let thisObj = { ...filterObj2 }
        if (data !== null) {
            thisObj.date_from = moment(data[0]).format('YYYY-MM-DD');
            thisObj.date_to = moment(data[1]).format('YYYY-MM-DD');
        } else {
            thisObj.date_from = '';
            thisObj.date_to = '';
        }
        setfilterObj2(thisObj);
    }


    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    <div className='searchActionsBar w-100 justify-content-between flex-wrap'>
                        <div className='searchActionsBar mb-0'>
                            <div className='mt-0 mb-0'>
                                {/*** search input ***/}
                                <div className='searchInputWrapper'>
                                    <div>
                                        <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                    </div>
                                    <img src={search} alt='search-icon' className='barSearchIcon' />
                                </div>
                            </div>

                            <div className='dataRange'>
                                <DateRangePicker disabledDate={afterToday()} showOneCalendar={true} appearance="default" placeholder="from : to" onChange={(value) => getDateValue(value)} />
                            </div>

                            {/* show select */}
                            <div className='perPageContainer'>
                                <label htmlFor='selectShow' className='perPageLabel'>
                                    show
                                </label>
                                <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                    <option value='20'>20</option>
                                </select>
                            </div>

                            {/*** apply search btn  ***/}
                            <button className='applySearchBtn' onClick={getFiltered}>search</button>
                        </div>
                        {/*** export as pdf ***/}
                        <div className="dropdown">
                            <button className="dropdown-toggle exportDropdown confirmBtn" type="button" id="exportButton" data-bs-toggle="dropdown" aria-expanded="false">
                                export
                            </button>
                            <ul className="dropdown-menu custom-bulk-dropdown" aria-labelledby="exportButton">
                                <li className='' onClick={handlePrint}>
                                    <span className=' px-2 py-1'>pdf</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {Permissions['contact-us']?.view &&
                        <div className='tableWrapper'>
                            <table ref={componentRef} className="w-100 table table-striped" >
                                <thead>
                                    <tr>
                                        {/*<th>
                                            <input type="checkbox"  {...(selectAll ? {'checked': true} :{}) } onChange={toggleSelectAllCheck} />
                                        </th> */}
                                        <th>request details</th>
                                        <th>subject</th>
                                        <th className='dateCol'>date</th>
                                        <th>actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allContacts && allContacts.map((contact, index) => (
                                        <React.Fragment key={index}>
                                            <tr className='stripedRow'>
                                                {/* <td>
                                                    check
                                                </td> */}
                                                {/* <td className='pe-0 hideAtPrint' style={{ 'maxWidth': '2rem' }}>
                                                <input type="checkbox" checked={checkedInputs.find((p) => p === contact.id) ? true : false} onChange={(e) => toggleCheckbox(e, contact)}  />
                                            </td> */}
                                                <td>
                                                    <div className='row contactPersonal m-0 p-0 mb-2'>
                                                        <div className='col-4 ps-0 contactDetails'>
                                                            phone :
                                                        </div>
                                                        <div className='col-6 ps-0'>
                                                            {contact.phone}
                                                        </div>
                                                    </div>
                                                    <div className='row contactPersonal m-0 p-0 mb-2'>
                                                        <div className='col-4 ps-0 contactDetails'>
                                                            email :
                                                        </div>
                                                        <div className='col-6 ps-0'>
                                                            {contact.email}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='desc'>
                                                    <div className='descriptionTd'>
                                                        {contact.message}
                                                    </div>
                                                </td>
                                                <td className='dateCol'>
                                                    <div className='d-flex'>
                                                        {moment(contact.created_at).format('DD-MM-YY h:mm A')}
                                                    </div>
                                                </td>
                                                <td className='hideAtPrint'>
                                                    <div className='d-flex'>
                                                        {Permissions['contact-us']?.read &&
                                                            <Tooltip title='read the msg content'>
                                                                <div className='contactActions' data-bs-toggle="modal" data-bs-target={`#readMsgModel`} onClick={() => { markContactMsgRead(contact.id, contact.read); setDescription(contact.message) }} >
                                                                    {contact?.read === 'read' ? 
                                                                    <img className='iconActions' src={read} alt="edit" />
                                                                    :
                                                                    <img className='iconActions' src={unread} alt="edit" />
                                                                }
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                        {/*  { contact.is_blocked ?
                                                    <>
                                                        {Permissions?.blockedIp?.delete && <Tooltip title='unblock'>
                                                            <img src={unblock_icon} alt="unblock ip" data-bs-toggle="modal" data-bs-target='#toggleblockIpModal' onClick={()=> toggleBlock(false , 1 , contact.ip)}/>
                                                        </Tooltip>}
                                                    </>
                                                    :
                                                    <>
                                                        {Permissions?.blockedIp?.create && <Tooltip title='block'>
                                                            <img src={block_icon} alt="block ip" data-bs-toggle="modal" data-bs-target='#toggleblockIpModal' onClick={()=> toggleBlock(true , 1 , contact.ip)}/>
                                                        </Tooltip>}
                                                    </>
                                                } */}
                                                        {Permissions['contact-us']?.delete &&
                                                            <Tooltip title='delete msg'>
                                                                <div className='contactActions'>
                                                                    <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => clickDelete(contact)} />
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            {/* <tr className="collapse contactMsgRow" id={`collapse${index}`}>
                                        <td colSpan="8">{contact.message}</td>
                                    </tr> */}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>
                <DeleteModal onSubmitDelete={onSubmitDelete} name={deletedName} ></DeleteModal>
                <MessageModal description={description} />

            </section>}
        </>
    )
}
