import React from 'react'
import { Rating } from '@mui/material'
import './rateCard.scss'
import eyeSlash from '../../assets/icons/eyeSlash.svg'
import CancelSuspend from '../SuspendModal/CancelSuspend'
import { useState } from 'react'
import { Gallery, Item } from 'react-photoswipe-gallery'
import vector from '../../assets/icons/Vector.svg'
import 'photoswipe/dist/photoswipe.css';

export default function RateCard({ rateObj }) {

    const [openHideModal, setOpenHideModal] = useState(false)


    return (
        <div className="OfferSection border px-3 bg-transparent">
            <section className={`offercard `}>
                {/* offer user */}
                <div className='d-flex justify-content-between align-items-center'>
                    <section className='projectDetailsSection '>
                        <div className='d-flex align-items-start flex-column'>
                            <div className='offerUser'>
                                <span> {rateObj?.user?.username}</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-between mt-2 w-100'>
                                <span className='offerTime'> {rateObj?.created_at}</span>
                            </div>
                        </div>
                    </section>
                </div>
                {/* offer details */}
                <div className='offerDetails rateDetails d-flex justify-content-between flex-wrap px-lg-4 bg-light'>
                    <div className='d-flex flex-column offerDetailsSec'>
                        <span className='offertitle mb-2'>{'work quality'}</span>
                        <Rating name="read-only" size="small" value={rateObj?.professionality} readOnly />
                    </div>
                    <div className='d-flex flex-column offerDetailsSec'>
                        <span className='offertitle mb-2'>{'Communication and cooperation'}</span>
                        <Rating name="read-only" size="small" value={rateObj?.communication} readOnly />
                    </div>
                    <div className='d-flex flex-column offerDetailsSec'>
                        <span className='offertitle mb-2'>{'Punctuality'}</span>
                        <Rating name="read-only" size="small" value={rateObj?.time_commitment} readOnly />
                    </div>
                    <div className='d-flex flex-column offerDetailsSec'>
                        <span className='offertitle mb-2'>{'Total Rate'}</span>
                        <Rating name="read-only" size="small" value={rateObj?.average} readOnly />
                    </div>
                </div>
                <div className='rateDescription'>
                    {!rateObj?.isHidden && <img className='hideRateMsg' onClick={() => setOpenHideModal(true)} src={eyeSlash} alt="" />}
                    <p className='mb-1 pb-0'>
                        {rateObj?.note}
                    </p>
                </div>
                {console.log(rateObj)}
                {rateObj?.media && rateObj?.media.length > 0 ? rateObj?.media.map((med, i) => {
                    return (
                        <div className=' d-flex justify-content-evenly align-items-center flex-wrap' key={i}>
                            <Gallery>
                                <Item
                                    original={med.url}
                                    thumbnail={vector}
                                    width="1500"
                                    height="800"
                                >
                                    {({ ref, open }) => (
                                        <div ref={ref} onClick={open} className='imgZoom d-flex justify-content-center'>
                                            <img style={{ objectFit: "cover" }} className='w-25' src={med.url} alt='' />
                                            {/* <img style={{ objectFit: "cover"}} className='w-25' src={med.url} alt='' />                           */}
                                        </div>)}
                                </Item>
                            </Gallery>
                        </div>
                    )
                }) : ""}
            </section>
            <CancelSuspend hideRating={true} open={openHideModal} setOpen={setOpenHideModal} rateId={rateObj?.id} />

        </div>
    )
}
