import React, { useState } from "react";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import './charts.css'
export default function Charts(props) {

    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: "basic-bar",
                width: '50%',
                height: 200,
                type: "bar",
                offsetY: 16,
                toolbar: {
                    show: false
                }
            },
        },
        xaxis: {
            categories: ["Pending", "Active", "Rejected", "Archived", "Total"]
        },
        series: [{
            name: "Count",
            data: [0, 0, 0, 0, 0],
        }],
        colors: [
                "#33b2df",
                "#546E7A",
                "#d4526e",
                "#13d8aa",
                "#A5978B",
                "#2b908f",
                "#f9a3a4",
                "#90ee7e",
                "#f48024",
                "#69d2e7"
        ]
    })
    useEffect(() => {

        const newData = [...props.data];
        const newCategories = [...props.categories];
        setChartData(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                xaxis: {
                    categories: newCategories
                }
            },
            series: [
                {
                    ...prevState.series[0],
                    data: newData
                }
            ]
        }))
            
    }, [props.categories, props.data])

    return (
        <div className="chart p-1 py-0 app d-flex flex-column justify-contnet-center- align-items-center bg-light m-2 flex-grow-1 ">
            <h3>{props.title}</h3>
            <div className="row">
                <div className="mixed-chart ">
                    <Chart
                        className="flex-grow-1 singlechart apex-charts"
                        options={chartData.options}
                        series={chartData.series}
                        type="bar"
                        
                    />
                </div>
            </div>
        </div>
    );
}
