import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router';
import { getWithdraw } from '../../Apis/withdraws';
import TitleContext from '../../Contexts/TitleContext';
import 'photoswipe/dist/photoswipe.css';
import moment from 'moment/moment';

export default function WithdrawDetails() {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState();
    const { id } = useParams();
    const [loader, setLoader] = useState()
    useEffect(() => {
        window.scrollTo(0, 0);
        changeTitle(`withdraw details`);
    }, [pathname])

    useEffect(() => {
        console.log(id)
        id && getWithdraw(id).then((res) => {
            console.log(res.data.data);
            setModel(res.data.data);
        }).catch((err) => {
            console.log(err.response);
        })
        // eslint-disable-next-line
    }, [id]);

    return (
        <section className='componentGlobalWrapper addBlog'>
            {model && <form>
                <div className='formWidth'>
                    <div className='d-flex'>
                        <div className='w-100 d-flex flex-column mb-4 '>
                            <label className='formLabel'>username</label>
                            <input type='text' value={`${model?.user?.username}`} readOnly />
                        </div>
                    </div>

                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>name</label>
                        <input type='text' value={model?.name} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>iban</label>
                        <input type='text' value={model?.iban} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>swift code</label>
                        <input type='text' value={model?.swift} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>bank</label>
                        <input type='text' value={model?.bank} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>address</label>
                        <input type='text' value={model?.address} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>amount</label>
                        <input type='text' value={model?.amount} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>created at</label>
                        <input type='text' value={model?.created_at ? moment(model?.created_at).format("DD-MM-YYYY") : ''} readOnly />
                    </div>
                </div>
            </form>}
        </section>
    )
}
