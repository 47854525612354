import React, { useContext, useEffect ,useState } from 'react';
import "../Aboutus/Aboutus.scss";
import "./HomeSections.scss";
import { useLocation} from 'react-router';
import TitleContext from '../../../Contexts/TitleContext';
import HomeSectionsForm from './../../../Components/HomeSections/HomeSections';
import { getHomeSections } from '../../../Apis/homePage';
import ContactUsSectionForm from './../../../Components/ConatctUsSection/ContactUsSection';
import { getContactUsSection } from '../../../Apis/pages';

export default function HomeSections() {
    let {changeTitle} = useContext(TitleContext);
    const { pathname } = useLocation();
    const [modelServiceSection ,setModelServiceSection] = useState({});
    const [modelContactUsSection ,setModelContactUsSection] = useState({});
    const [modelProjectsSection ,setModelProjectsSection] = useState({});
    const [modelHowWeWorkSection ,setModelHowWeWorkSection] = useState({});
    const [modelCategoriesSection ,setModelCategoriesSection] = useState({});
    const [modelStartYourProjectSection ,setModelStartYourProjectSection] = useState({});
    const [modelUploadAppSection ,setModelUploadAppSection] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function getSectionsData(){
        getHomeSections().then((res)=>{
            let x = {...res.data.data};
            console.log(x)
            setModelServiceSection(x.services);
            setModelProjectsSection(x.projects);
            setModelHowWeWorkSection(x.how_do_we_work);
            setModelCategoriesSection(x.categories);
            setModelStartYourProjectSection(x.start_your_project);
            setModelUploadAppSection(x.upload_app?.locales);
        })
    }

    function getContactUsSectionData(){
        getContactUsSection().then((res)=>{
            setModelContactUsSection(res.data.data.contact.locales)
        }).catch((err)=>{
            console.log(err.response)
        })
    }

    useEffect(()=>{
        changeTitle(`home page`);
        getSectionsData();
        getContactUsSectionData()
        // eslint-disable-next-line
    } , []);


    return (
        <>
            <section>
                <div className='componentGlobalWrapper border-bottom-0 mb-0 aboutus'>
                    <div className='mb-3'>
                        <h5 className='pageSubtitle mb-0' style={{"lineHeight" : "20px"}}>home settings</h5>
                    </div>
                </div>
            </section> 
            {/**** how we work  *****/}
            <HomeSectionsForm model={modelHowWeWorkSection} setModel={setModelHowWeWorkSection} pageName='how_do_we_work' sectionName={'how do we work section'}  />

            {/**** Categories  *****/}
            <HomeSectionsForm model={modelCategoriesSection} setModel={setModelCategoriesSection} pageName='categories' sectionName={'latest categories section'}  />

            {/**** start your project  *****/}
            <HomeSectionsForm model={modelStartYourProjectSection} setModel={setModelStartYourProjectSection} pageName='start_your_project' sectionName={'start your project section'}  />

            {/**** services  *****/}
            <HomeSectionsForm model={modelServiceSection} setModel={setModelServiceSection} pageName='services' sectionName={'latest services section'}  />

            {/**** projects  *****/}
            <HomeSectionsForm model={modelProjectsSection} setModel={setModelProjectsSection} pageName='projects' sectionName={'interisting projects section'}  />

            {/**** upload app  *****/}
            <HomeSectionsForm model={modelUploadAppSection} setModel={setModelUploadAppSection} pageName='upload_app' sectionName={'upload app section'} />
        
            <ContactUsSectionForm model={modelContactUsSection} setModel={setModelContactUsSection} pageName='contact-us' sectionName={'contact us section'} />
        </>
    )
}
