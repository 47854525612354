import React, { useContext, useState, useEffect } from "react";
import "./Header.scss";
import TitleContext from "../../Contexts/TitleContext";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { faCog, faSignOutAlt, faUser, faBars} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAdminProfileInfo } from "../../Apis/profile";
import { basename } from "../../Configurations/config";
import notification from "../../assets/icons/notification.svg";
import Tooltip from "@mui/material/Tooltip";
import NotificationContext from "../../Contexts/notificationContext";

export default function Header() {
    let { Title, btn } = useContext(TitleContext);
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const { pathname } = useLocation();
    const [notificationAlert, setNotificationAlert] = useState();
    let {numOfNotification,allNotifications,returnLink,handleReadNotification,} = useContext(NotificationContext);

    

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getAdminInfo();
    }, []);

    useEffect(() => {
        if(numOfNotification !== 0) setNotificationAlert(true)
    }, [numOfNotification]);

    function getAdminInfo() {
        getAdminProfileInfo().then((res) => {
            setProfile(res.data.data);
        });
    }

    function logoutHandler() {
        localStorage.removeItem("need4_Admin_Token");
        navigate(`/${basename}`);
    }
    function goToProfile() {
        navigate(`${basename}/dashboard/settings/account`);
    }
    function goToSettings() {
        navigate(`${basename}/dashboard/settings/security`);
    }

    return (
        <div className="headerWraper">
            <div className="d-flex align-items-center">
                <h3 className="mb-0">{`${Title}`}</h3>
                {btn && btn?.canAdd && (
                    <NavLink className="headerBtn" to={btn.url}>
                        {btn.text}
                    </NavLink>
                )}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 mt-md-0 mb-3 mb-md-0">
                {
                    <div className="dropdown">
                        <Tooltip title="Notifications">
                            <button
                                className="position-relative me-4 notification"
                                data-bs-toggle="dropdown"
                                onClick={() => setNotificationAlert(false)}
                            >
                                <img src={notification} alt="Notification" />
                                {notificationAlert && (
                                    <span className="position-absolute notificationDot">
                                        {/* {numOfNotification} */}
                                    </span>
                                )}
                            </button>
                        </Tooltip>
                        <ul
                            className="dropdown-menu dropdown-menu-end notificationDropdown"
                            aria-labelledby="dropdownMenuButton1"
                        >
                            <li className="notificationHeader">Notifications</li>
                            {allNotifications && allNotifications.length ?
                                allNotifications.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`linkNotification`}
                                        onClick={() =>{
                                            handleReadNotification(item.id) 
                                            console.log(item)}
                                        }
                                    >
                                        {returnLink(item)}
                                    </div>
                                )):''}
                        </ul>
                    </div>
                }

                <span className="userName">{profile && profile.name}</span>
                {profile && (
                    <div className="dropdown">
                        <div
                            className="userImg"
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                        >
                            {profile?.name?.charAt(0)}
                        </div>
                        <ul className="dropdown-menu py-0 settingsDropdown">
                            <li onClick={goToProfile} className="py-0">
                                <span className="dropdown-item">
                                    <FontAwesomeIcon className="iconProfileMenu" icon={faUser} />
                                    profile
                                </span>
                            </li>
                            <li onClick={goToSettings} className="py-0">
                                <span className="dropdown-item">
                                    <FontAwesomeIcon icon={faCog} className="iconProfileMenu" />
                                    settings
                                </span>
                            </li>
                            <li onClick={logoutHandler} className="py-0">
                                <span className="dropdown-item">
                                    <FontAwesomeIcon
                                        icon={faSignOutAlt}
                                        className="iconProfileMenu"
                                    />
                                    logout
                                </span>
                            </li>
                        </ul>
                    </div>
                )}

                {/* <div className='dropdown dashboardLanguage'  >
                    <button className='position-relative ms-auto' data-bs-toggle="dropdown">
                        <img src={langIcon} alt="language icon"/>
                        <span>EN</span>
                        <img src={arrow} alt="arrow"/>
                    </button>
                    <div className="dropdown-menu custom-lang-dropdown">
                        <ul className="nav nav-items languageTabs d-flex flex-column">
                            {supportedLanguages.map((language, index)=>(
                                <li key={index} className='nav-item' >
                                    <span className={`${index === 0 && `active`} dropdownItem px-2 py-1 w-100`} id={`${language.name}Form`} data-bs-toggle="pill" data-bs-target={`#pills-${language.name}`} type="button" role="tab" aria-controls={`pills-${language.name}`} {...(index === 0 ? {'aria-selected': "true"} :{})}>
                                        {language.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div> */}
                {/* <Tooltip title={sideBar-Toggler')}> */}
                <button
                    className="btn sideBarToggler d-lg-none"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasScrolling"
                    aria-controls="offcanvasScrolling"
                >
                    {" "}
                    <FontAwesomeIcon icon={faBars} />
                </button>
                {/* </Tooltip> */}
            </div>
        </div>
    );
}
