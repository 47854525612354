import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateLevel } from '../../../Apis/levels';

export default function UpdateLevels() {
  const { id } = useParams();
    // onsubmit
    const onSubmit = async (data) => {
        //to send request
          console.log(data,id)
        return await updateLevel(id,data).then(res => {
            return 'done';          
        }).catch(err => {
          console.log(err.response)
            return err.response.data.errors;
        })
    };

  return (
    <div>
        <Form onSubmit={onSubmit} />
    </div>
  )
}
