import { https } from './https';
import { api_url } from './../Configurations/config';
import axios from "axios";

//get terms and conditions
export async function getTermsAndConditions() {
    return await https.get(`${api_url}dashboard/settings/terms-and-conditions`);
}
//get terms and conditions
export async function getContactUsSection() {
    return await https.get(`${api_url}dashboard/settings/contact-us`);
}

//update terms and conditions
export async function updateTermsAndConditions(data) {
    return await https.put(`${api_url}dashboard/settings/terms-and-conditions`, data);
}

//get terms and conditions
export async function getPrivacyPolicy() {
    return await https.get(`${api_url}dashboard/settings/privacy-policy`);
}
//update terms and conditions
export async function updatePrivacyPolicy(data) {
    return await https.put(`${api_url}dashboard/settings/privacy-policy`, data);
}
//update terms and conditions
export async function getPage(page) {
    return await https.get(`${api_url}dashboard/settings/${page}`);
}
//update terms and conditions
const AdminToken = `Bearer ` + localStorage.getItem('need4_Admin_Token');
export let https2 = axios.create({
    headers: {
        Authorization: AdminToken,
        AccessControlAllowOrigin: '*',
        accept: 'application/json',
        "Content-Type": 'application/json'
    }
})

export async function editPage(page, data) {
    return await https2.put(`${api_url}dashboard/settings/${page}`, data);
}