import { https } from './https';
import { api_url } from '../Configurations/config';

//get loggedin admin profile info
export async function getAdminProfileInfo(){
    return await https.get(`${api_url}dashboard`);
}

//edit loggedin admin profile info
export async function editAdminProfileInfo(data){
    return await https.post(`${api_url}dashboard/update` , data);
}

