import React, { useContext, useEffect, useState} from 'react';
import { useLocation} from 'react-router';
import TitleContext from './../../Contexts/TitleContext';
import { setAdminSecurityData } from './formHandler';
import formModel from './formModel';
import closedeye from "./../../assets/icons/closeEye.svg";
import openeye from "./../../assets/icons/openEye.svg";
import '../forms.scss';

export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    let [passType,setPasstype]=useState({current_password:'password' , new_password:'password' , confirm_password:'password' }); 
    

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle('security settings');
        // eslint-disable-next-line
    } , []);

    // onchange
    const setFormData = (e, property) => {
        setAdminSecurityData(model, setModel, e, property);
    }

    // onsubmit
    const confirmAdminSecurityInfo = async (e , model)=>{
        e.preventDefault();
        setLoading(true);
        setErrors([]);
        // send request
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === 'done'){
            setLoading(false);
        }else{
            setLoading(false);
            setErrors(submitProps);
        }
    }
    function showPasswordHandler(key) {
        let obj = {...passType};
        if (obj[key] === 'password') {
            obj[key] = 'text';
        }else {
            obj[key] = 'password';
        }
        setPasstype(obj);
    }

    return (
        <>
        <section className='componentGlobalWrapper'>
            {/*** change logged in admin password ***/}
            <div className=''>
                <h5 className='pageSubtitle'>{'change password'}</h5>
            </div>
            <form className='pagesForm' onSubmit={(e) => confirmAdminSecurityInfo(e, model)}>
                <div className='mb-4'>
                    <div className='d-flex flex-column'>
                        <label htmlFor='old-password' className='formLabel'>{'current password'}</label>
                        <div className='position-relative'>
                            <input className='w-100' minLength={8} name="current_password" id='old-password' autoComplete='' type={passType?.current_password} placeholder={'current password'} onChange={(e) => setFormData(e, 'current_password')} required pattern="\S(.*\S)?" title="This field is required"/>
                            <span onClick={()=>showPasswordHandler('current_password')} className={`eyeIcon`}  >
                                {passType?.current_password === "password" ? <img src={closedeye} alt="eye icon"/>:
                                <img src={openeye} alt='eye icon'/>
                                }
                            </span> 
                        </div>
                    </div>
                    {errors && errors.current_password && <div className="validateToDelete text-danger mt-1">{errors.current_password[0]}</div>}
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='new-password' className='formLabel'>{'new password'}</label>
                    <div className='position-relative '>
                        <input className='w-100' minLength={8} name="password" id='new-password' autoComplete='new-password'  type={passType?.new_password} placeholder={'new password'} onChange={(e) => setFormData(e, 'password')} required pattern="\S(.*\S)?" title="This field is required"/>
                        <span onClick={()=>showPasswordHandler('new_password')} className={`eyeIcon`}  >
                            {passType?.new_password === "password" ? <img src={closedeye} alt="eye icon"/>:
                                <img src={openeye} alt='eye icon'/>
                            }
                        </span> 
                    </div>
                </div>
                <div className='mb-5'>
                    <div className='d-flex flex-column '>
                        <label htmlFor='confirm-password' className='formLabel'>{'confirm password'}</label>
                        <div className='position-relative'>
                            <input className='w-100' minLength={8} name="password_confirmation" autoComplete='new-password' id='confirm-password' type={passType?.confirm_password} placeholder={'confirm password'} onChange={(e) => setFormData(e, 'password_confirmation')} required pattern="\S(.*\S)?" title="This field is required" />
                            <span onClick={()=>showPasswordHandler('confirm_password')} className={`eyeIcon`}  >
                                {passType?.confirm_password === "password" ? <img src={closedeye} alt="eye icon"/>:
                                    <img src={openeye} alt='eye icon'/>
                                }
                            </span> 
                        </div>
                    </div>
                    {errors && errors.password && <div className="validateToDelete text-danger mt-1">{errors.password[0]}</div>}
                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>{`save changes`}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {'saving'}...
                    </button>
                }
            </form>
        </section>
        </>
    )
}
