import React, { useContext, useEffect, useRef, useState } from 'react';
import removeTag from "../../assets/icons/removetag.svg";
import "./GeneralSettings.scss";
import { useLocation } from 'react-router';
import { getGeneralSettings, updateGeneralSettings } from '../../Apis/generalSettings';
import { supportedLanguages } from '../../Utilities/SupportedLanguages';
import TitleContext from '../../Contexts/TitleContext';
import { preventEnter } from '../../Utilities/preventEnter';

export default function GeneralSettings() {
    let { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState({meta_description: { ar: {}, en: {} },
        meta_keywords: { ar: {}, en: {} }})
    const { pathname } = useLocation();
    const [loader, setLoader] = useState(false);
    //const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        changeTitle(`General settings`);
        getGeneralSettings().then((res) => {
            console.log(res.data.data)
            let generalSettings = {};
            generalSettings['meta_description'] = res.data.data.meta_description;
            generalSettings['meta_keywords'] = res.data.data.meta_keywords;
            let thiskeywords = { ...keywords };
            supportedLanguages.map((language) => {
                // set keywords
                if (generalSettings?.meta_keywords[language.slug].description !== 'null') {
                    thiskeywords[language.slug] = generalSettings?.meta_keywords[language.slug].description.replace(/ /g, '').split(',');
                }
                return true
            })
            setKeywords(thiskeywords);
            setModel(generalSettings);
        })

        // eslint-disable-next-line
    }, []);

    /****************** set keywords ************************/
    let metaKeywordsRef = useRef();
    let [keywords, setKeywords] = useState({});

    function AddKeywordHandler(e, lang) {
        let propKeyword = { ...keywords };
        if (e.target.value.length > 30) {
            e.preventDefault();
        }
        // console.log(e)
        if (e.keyCode === 32) {
            if (!propKeyword[lang]) {
                propKeyword[lang] = []
            }
            let thisModel = { ...model };
            if (e.target.value.trim()) {
                let thisKeywords = { ...keywords };
                if (!thisKeywords[lang]) {
                    thisKeywords[lang] = []
                }
                thisKeywords[lang] = [...thisKeywords[lang], e.target.value.trim()];
                setKeywords(thisKeywords);
                e.target.value = "";
                thisModel.meta_keywords[lang].description = thisKeywords[lang].join(',');
                setModel(thisModel);
            }
            setModel(thisModel);
        }
    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i, lang) {
        let thisKeywords = { ...keywords };
        thisKeywords[lang].splice(i, 1);
        setKeywords(thisKeywords);

        let thisModel = { ...model };
        thisModel.meta_keywords[lang].description = thisKeywords[lang].join(',');
        setModel(thisModel);
    }

    function changeHandler(e, lang = null) {
        let thisModel = { ...model };
        if(lang){
            thisModel[e.target.name][lang]['description'] = e.target.value
        }else{
            thisModel[e.target.name] = e.target.value
        }
        // console.log(thisModel)
        setModel(thisModel);
    }

    /* **************************** IMAGES ************************** */

    function onConfirmEdit(e) {
        e.preventDefault();
        setLoader(true);
        updateGeneralSettings(model).then((res) => {
            setLoader(false);
            console.log(res)
        }).catch((err) => {
            console.log(err.response);
            //setErrors(err.response.data.errors)
            setLoader(false);
        })
    }


    return (
        <>
            <section className='componentGlobalWrapper generalSettings addBlog' >
                {/* <div className='mb-5'>
                    <h5 className='pageSubtitle'>site settings</h5>
                </div> */}

                {/* {(model && model.length) ? */}
                <form onSubmit={onConfirmEdit} onKeyDown={preventEnter}>
                    <div className='generalSettingsForm'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div style={{ "width": "48%" }} >
                                {/** meta keywords  ***/}
                                {supportedLanguages.map((language, index) => {
                                    return (
                                        <div key={index} className='d-flex flex-column mb-4' >
                                            <label htmlFor='metaKeyword' className='formLabel'>meta keywords ({language.name})</label>
                                            <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                                <div className="">
                                                    {
                                                        keywords[language.slug] && keywords[language.slug].map((keyword, index) => (
                                                            <div key={index} className={`tagContainer`}>
                                                                {keyword}
                                                                <img src={removeTag} onClick={() => removeKeywordHandler(index, language.slug)} className='removeTag' alt='remove' />
                                                            </div>
                                                        ))
                                                    }
                                                    <input onKeyDown={(e) => AddKeywordHandler(e, language.slug)} maxLength='50' type="text" name="metaKeyword" className={`tagInput border-0`} id="tags" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/*** meta description ***/}
                                <div className='d-flex flex-column '>
                                    {supportedLanguages.map((language, index) => {
                                        return (
                                            <div key={index} className='d-flex flex-column mb-4'>
                                                <label htmlFor={`meta-description${language}`} className='formLabel'>{`meta description in ${language.name}`}</label>
                                                <textarea maxLength='500' name="meta_description" id={`meta-description${language.slug}`} type='text' value={model ? model?.meta_description[language.slug].description : ""}
                                                    onChange={(e) => changeHandler(e, language.slug)} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div>
                            {!loader ?
                                <div>
                                    <button className='confirmBtn' type='submit'>save changes</button>
                                </div>
                                :
                                <button className='confirmBtn' disabled>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    saving...
                                </button>
                            }
                        </div>
                    </div>
                </form>
                {/* : ''
            } */}
            </section>
        </>
    )
}
