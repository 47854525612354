export const setData = (model, setModel, ele, property, locale = false, language = null) => {
    console.log('FROM MODAL HANDLER::::', model);
    let newData = Object.assign({}, model);
    if (locale) {
        newData.locales[language] = {}
        newData.locales[language][property] = ele.target.value;
    } else {

        newData[property] = ele.target.value;
        console.log('FROM color HANDLER::::', ele.target.value);
    }
    setModel(newData);
}