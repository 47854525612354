import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import '../tables.scss';
import '../forms.scss';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { useEffect } from 'react';
import Pagination from '../../Components/Pagination/Pagination';
import { deleteSkill, getSkills } from '../../Apis/skills';
import DeleteModal from '../../Utilities/deleteModal';
import moment from 'moment';
import { basename } from './../../Configurations/config';
import TitleContext from '../../Contexts/TitleContext';
import PermissionsContext from '../../Contexts/permissionsContext';

export default function Skills() {
    const [allSkills, setAllSkills] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '' });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '' });
    const [deletedSkill, setDeletedSkill] = useState("");
    const [deletedName, setDeletedName] = useState('skill');
    const { Permissions } = useContext(PermissionsContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        changeTitle(`Skills`, { url: `${basename}/dashboard/skills/create`, text: 'add Skill' ,canAdd:Permissions?.skill?.create});
        // eslint-disable-next-line
    }, [pathname]);

    function getAllSkills(obj) {
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}`;
        getSkills(params).then((res) => {
            setAllSkills(res.data.data);
            console.log(res.data.data);
            let total = res.data?.pagination?.total;
            changeTitle(`Skills (${total})`, { url: `${basename}/dashboard/skills/create`, text: 'add Skill' ,canAdd:Permissions?.skill?.create});
            setPageCount(Math.ceil(total / filter.per_page));
        })
    }

    useEffect(() => {
        getAllSkills(filterObj);

        // eslint-disable-next-line
    }, [Permissions]);

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        getAllSkills(thisObj)
    }

    async function onSubmitDelete() {
        return await deleteSkill(deletedSkill).then(res => {
            getAllSkills(filterObj);
            return "done";
        }).catch(err => {
            console.log(err.response.data)
            return err.response.data.message;
        })
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getAllSkills(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    }

    function clickDelete(item) {
        setDeletedSkill(item.id)
        setDeletedName(item?.name)
    }

    return (
        <>
            {<section className='componentGlobalWrapper d-flex flex-column'>
                <div>
                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                show
                            </label>
                            <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>
                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>search</button>
                    </div>
                    {<div className='tableWrapper'>
                        {
                        <table className="w-100 table table-striped ">
                            <thead>
                                <tr>
                                    <th>name</th>
                                    <th>created at</th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allSkills && allSkills.map((skill, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {skill?.name ? skill?.name : '-'}
                                            </td>
                                            <td>
                                                {skill?.created_at ? moment(skill?.created_at).format('DD-MM-YYYY') : '-'}
                                            </td>
                                            <td>
                                                <div>
                                                    {Permissions?.skill?.edit && <Tooltip title='edit'>
                                                        <Link to={`${basename}/dashboard/skills/${skill.id}/update`}>
                                                            <img className='iconActions' src={edit_icon} alt="edit" />
                                                        </Link>
                                                    </Tooltip>}
                                                    {Permissions?.skill?.delete && <Tooltip title="delete this skill">
                                                        <img data-bs-toggle="modal" onClick={() => clickDelete(skill)} data-bs-target='#deleteModal' className='iconActions' src={delete_icon} alt="edit" />
                                                    </Tooltip>}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
}
                    </div>}
                </div>
                {<div className='d-flex justify-content-end mt-auto'>
                    <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                </div>}
                <DeleteModal onSubmitDelete={onSubmitDelete} name={deletedName} ></DeleteModal>
            </section>}
        </>
    )
}