export const setData = (model, setModel, ele, property ,locale=false, language=null) =>{
    let newData = Object.assign({}, model);
    if(locale){
        if(newData.locales[language]){
            newData.locales[language][property] = ele.target.value;
        }else{
            newData.locales[language] = {}
            newData.locales[language][property] = ele.target.value;
        }
    }else{
        if(property === 'is_percentage'){
            newData[property] = Number(ele.target.value);
        }
        else{
            newData[property] = ele.target.value;
        }
    }
    console.log(newData);
    setModel(newData);
}