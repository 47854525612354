import React, { useRef } from 'react'
import { updateBudget } from '../../../Apis/budgets';
import Form from './Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function UpdateBudget(props) {
  const closeUpdateModalRef = useRef();
    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateBudget(props.id,data).then(res => {
          closeUpdateModalRef.current.click();
          props.getNewBudgets(props.obj)
          return 'done';          
        }).catch(err => {
            return err.response.data.errors;
        })
    };

  return (
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className='pageSubtitle mb-0'>update budget</h5>
                <div ref={closeUpdateModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" >
                    <FontAwesomeIcon icon={faXmark}/>
                </div>
            </div>
            <div className="modal-body">
              {props.id && <Form onSubmit={onSubmit} id={props.id} />}
            </div>
          </div>
    </div>
  )
}
