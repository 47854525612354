import React, { useState } from "react";
import { Rating } from "@mui/material";
import RateCard from "../rateCard/rateCard";
import "../rateCard/rateCard.scss";
import filledStar from "../../assets/icons/filledStar.svg";

function RateSection({ userRatings, totalRating }) {
  console.log(userRatings);
  return (
    <div className="userRateWrapper sectionPadding pt-3">
      <div className="d-flex justify-content-between w-25  mx-auto">
        <p className="title">Total Rating</p>
        <div>
          <Rating readOnly value={totalRating} dir="rtl" />
        </div>
      </div>
      <div className="d-flex justify-content-between flex-row flex-wrap">
        <div className="allRates p-0">
          {userRatings?.avgs_count?.map((rate, i) => {
            return (
              <div key={i} className="rate">
                <Rating name="read-only" value={rate?.star} readOnly />
                <div className="rateBar progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${rate?.percentage}%` }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <span className="rateNum">{rate?.count} </span>
              </div>
            );
          })}
        </div>
        <div className="w-25">
          <div className="secTitle">
            <p className="title pb-2">Criteria Evaluation</p>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <span className="standardsTitle">{"Work quality"}</span>
            <span className="d-flex justify-content-end align-items-center">
              <span className="standardsTitle">
                {(userRatings?.avgs_criteria?.professionality)?.toFixed(1)}
              </span>
              <img className="rateStarConatiner" src={filledStar} alt="" />
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <span className="standardsTitle">{"Communication"}</span>
            <span className="d-flex justify-content-end align-items-center">
              <span className="standardsTitle">
                {(userRatings?.avgs_criteria?.communication)?.toFixed(1)}
              </span>
              <img className="rateStarConatiner" src={filledStar} alt="" />
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 ">
            <span className="standardsTitle">{"Punctuality"}</span>
            <span className="d-flex justify-content-end align-items-center">
              <span className="standardsTitle">
                {(userRatings?.avgs_criteria?.time_commitment)?.toFixed(1)}
              </span>
              <img className="rateStarConatiner" src={filledStar} alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RateSection;
