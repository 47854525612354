import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import search from "../../assets/icons/search.svg";
import details from "../../assets/icons/details.svg";
import archive from "../../assets/icons/archive.svg";
import noImg from '../../assets/images/noImg.jpg';
import '../tables.scss';
import '../forms.scss';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { useEffect } from 'react';
import Pagination from '../../Components/Pagination/Pagination';
import { acceptService, deleteService, getServices, getStatus, rejectService } from '../../Apis/services';
import ArchiveModal from '../../Utilities/archiveModal';
import moment from 'moment';
import { basename } from '../../Configurations/config';
import TitleContext from '../../Contexts/TitleContext';
import acceptIcon from "../../assets/icons/accept.svg";
import unArchive from "../../assets/icons/unarchive.svg";
import rejectIcon from "../../assets/icons/reject.svg";
import RejectModal from '../../Utilities/rejectModal';
import PermissionsContext from '../../Contexts/permissionsContext';
import Loader from '../../Components/Loader/Loader'
import NotDataFound from '../../Components/NotDataFound/notDataFound';
/* import PermissionsContext from '../../Contexts/permissionsContext'; */

export default function Services() {
    let [searchParams] = useSearchParams()
    const [status, setStatus] = useState(Number(searchParams.get("status")) ?? 1);
    const [statuses, setStatuses] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const { pathname } = useLocation();
    const location = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', status: status });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '' });
    const [deletedService, setdeletedService] = useState("");
    const [deletedName, setDeletedName] = useState('service');
    const navigate = useNavigate();
    const { Permissions } = useContext(PermissionsContext);
    const [servicesCount, setServicesCount] = useState({});
    const [Loading, setLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
        changeTitle(`Services`);
    }, [pathname]);
    useEffect(() => {
        setStatus(Number(searchParams.get("status")))
    }, [location])


    function getAllServices(obj) {
        setLoading(true)
        let filter = { ...obj }
        let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}`;
        getServices(params).then((res) => {
            setAllServices(res.data.data);
            let total = res.data?.pagination?.total;
            changeTitle(`Services (${res?.data?.overview?.total})`);
            setPageCount(Math.ceil(total / filter.per_page));
            let servicesOverView = res.data.overview
            servicesOverView['need action'] = servicesOverView['need_action']
            delete servicesOverView['need_action']
            setServicesCount(servicesOverView)
            setLoading(false)
        })
    }
    useEffect(() => {
        getStatus().then((res) => {
            setStatuses(res.data.data)
        })
        getAllServices(filterObj);
        setStatus(Number(searchParams.get("status")) ?? 1)
        // eslint-disable-next-line
    }, [Permissions]);

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        getAllServices(thisObj)
    }

    function changeStatus(status) {
        let thisObj = { ...filterObj };
        thisObj.status = status;
        thisObj.page = 1;
        setfilterObj(thisObj)
        getAllServices(thisObj);
        setStatus(status)
        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = thisObj.per_page;
        thisObj2.search = thisObj.search;
        setfilterObj2(thisObj2);
        navigate(`${basename}/dashboard/services?status=${status}`);
    }

    async function onSubmitDelete() {
        return await deleteService(deletedService).then(res => {
            getAllServices(filterObj);
            return "done";
        }).catch(err => {
            console.log(err.response)
            return err.response.data.message;
        })
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getAllServices(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    }

    function acceptServiceHandler(id) {
        acceptService(id).then(res => {
            console.log("WHAT THIS IS DOING :::::::::::::::::", res)
            getAllServices(filterObj);
        }).catch(err => {
            console.log(err.response)
        })
    }

    function clickDeleteOrReject(item, handler) {
        if (handler === 'delete') {
            setdeletedService(item.id)
            setDeletedName(item?.title.substring(0, 25))
        } else {
            setOpen(true);
            setdeletedService(item.id)
            setDeletedName(item?.title.substring(0, 25))
        }
    }

    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState({});
    async function rejectServiceHandler() {
        let obj = { 'reason': reason }
        console.log(obj)

        return await rejectService(deletedService, obj).then(res => {
            getAllServices(filterObj);
            return 'done'
        }).catch(err => {
            console.log(err.response);
            return false;
        })
    }

    return (
        <>
            {
                <section className='componentGlobalWrapper d-flex flex-column'>
                    <div>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                            {
                                status && statuses && statuses?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index} >
                                            {index ? <span className='separator'></span> : ''}
                                            <div onClick={() => changeStatus(item.id)} className={`tablinks ${status === item.id && `active`}`} id={`${item.slug}-tab`}>{item?.name} <span className='tabcounter'>({servicesCount[`${item?.name}`]})</span></div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <div className='searchActionsBar w-100'>
                            <div className='searchActionsBar mt-0 mb-0'>
                                {/*** search input ***/}
                                <div className='searchInputWrapper'>
                                    <div>
                                        <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                    </div>
                                    <img src={search} alt='search-icon' className='barSearchIcon' />
                                </div>
                            </div>

                            {/* show select */}
                            <div className='perPageContainer'>
                                <label htmlFor='selectShow' className='perPageLabel'>
                                    show
                                </label>
                                <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                    <option value='20'>20</option>
                                </select>
                            </div>
                            {/*** apply search btn  ***/}
                            <button className='applySearchBtn' onClick={getFiltered}>search</button>
                        </div>
                        {Permissions?.service?.view && <div className='tableWrapper'>
                            <table className="w-100 table table-striped serviceTable">
                                <thead>
                                    <tr>
                                        <th>image</th>
                                        <th>name</th>
                                        <th>user</th>
                                        <th>created at</th>
                                        <th>Orders count</th>
                                        <th>actions</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                {Loading ?
                                        <tr>
                                            <td className='w-100' colSpan={6}><Loader/></td>
                                        </tr>
                                    : allServices && allServices?.length !== 0 ? allServices.map((service, index) => {
                                        return (
                                                <tr key={index}>
                                                    <td className='sliderImg'>
                                                        <img src={`${service?.image !== null ? service?.image?.webp : noImg}`} alt="slider" />
                                                    </td>
                                                    <td>
                                                        {service?.title ? service?.title : 'no data added'}
                                                    </td>
                                                    <td>   {service?.user?.username ?? 'no data added'}</td>
                                                    <td>
                                                        {service?.created_at ? moment(service?.created_at).format('DD-MM-YYYY') : 'no data added'}
                                                    </td>
                                                    <td>
                                                        {service?.orders_count}
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <Tooltip title='details'>
                                                                <Link to={`${basename}/dashboard/services/${service.id}`}>
                                                                    <img className='iconActions' src={details} alt="details" />
                                                                </Link>
                                                            </Tooltip>
                                                            {(service?.status?.id === 2 || service?.status?.id === 4) &&
                                                                <>
                                                                    {Permissions?.service?.accept &&
                                                                        <Tooltip title='accept'>
                                                                            <div className='d-flex' onClick={() => acceptServiceHandler(service.id)} >
                                                                                <img className='iconActions' src={acceptIcon} alt="accept" />
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                    {Permissions?.service?.reject &&
                                                                        <Tooltip title='reject'>
                                                                            <div className='d-flex' onClick={() => clickDeleteOrReject(service, 'reject')} >
                                                                                <img className='iconActions' src={rejectIcon} alt="accept" />
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                </>
                                                            }
                                                            {service?.status?.id === 3 ?
                                                                <Tooltip title="Archive">
                                                                    <img data-bs-toggle="modal"
                                                                        onClick={() => clickDeleteOrReject(service, 'delete')} data-bs-target='#deleteModal' className='iconActions' src={archive} alt="edit" />
                                                                </Tooltip>
                                                                : ''}
                                                            {service?.status?.id === 5 ?
                                                                <Tooltip title='unarchive'>
                                                                    <div className='d-flex' onClick={() => acceptServiceHandler(service.id)} >
                                                                        <img className='iconActions' src={unArchive} alt="accept" />
                                                                    </div>
                                                                </Tooltip>
                                                                : ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                        ) 
                                    }
                                    )
                                    : <td className='w-100' colSpan={6}><NotDataFound /></td>
                                }
                                </tbody>
                            </table>
                        </div>}
                    </div>
                    {
                        <div className='d-flex justify-content-end mt-auto'>
                            <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                        </div>
                    }
                    <ArchiveModal onSubmitDelete={onSubmitDelete} name={deletedName}></ArchiveModal>
                </section>
            }
            <RejectModal open={open} setOpen={setOpen} title={deletedName} onSubmitReject={rejectServiceHandler} reason={reason} setReason={setReason} errors={errors} setErrors={setErrors} />
        </>
    )
}