let formModel = {
    locales: {
        ar:{
            name:"",
            description:""
        },
        en:{
            name:"",
            description:""
        }
    }
}
export default formModel;