import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import { preventEnter } from './preventEnter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function RejectModal({ open, setOpen, title ,reason ,setReason ,errors, setErrors,onSubmitReject}) {
  const [loader ,setLoader] = useState(false);

  function handleClose() {
    setOpen(false);
    setReason('');
    setErrors({});
  }

  function handleChange(e){
    setReason(e.target.value);
    let err = {...errors};
    err['note'] = ''
    setErrors(err)
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    setLoader(true);
    let reject = await onSubmitReject() 
    if(reject === 'done'){
      setOpen(false);
      setLoader(false);
      setReason('');
    }else{
      setLoader(false);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true} >
      <div className=" modalStyle d-flex justify-content-center flex-column align-items-center w-100 ">
        <div className="modal-header w-100 d-flex justify-content-between">
          
          <div className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className='d-flex justify-content-center deleteModal w-100 modal-body'>
          <form className='w-100' onSubmit={handleSubmit} onKeyDown={preventEnter}>
            <p className='mb-3'>
              do you want to reject {title} ?
            </p>
            <div className='d-flex flex-column w-100 justify-content-end' >
              <label htmlFor={`reason`} className='formLabel'>reason</label>
              <textarea name="reason" id={`reason`} type='text' placeholder='reason...' value={reason} onChange={(e) => handleChange(e)} minLength='3' maxLength={500} required />
              <div className='text-danger validateToDelete'>
                {errors?.note}
              </div>
              {
                loader ? 
                    <button className='confirmBtn mt-3 deleteBtn ms-auto'  disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                :
              <button className='deleteBtn mt-3 ms-auto' >submit</button> 
              }
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  )
}
