import { https } from './https';
import { api_url } from './../Configurations/config';
export async function getUsers(obj) {
    return await https.get(`${api_url}dashboard/users?${obj}`);
}
export async function getUser(id) {
    return await https.get(`${api_url}dashboard/users/${id}`);
}
export async function acceptIdentityDocs(id) {
    return await https.patch(`${api_url}dashboard/identity-documentations/accept/${id}`);
}
export async function rejectIdentityDocs(id) {
    return await https.patch(`${api_url}dashboard/identity-documentations/reject/${id}`);
}
export async function getUserPortfolios(id) {
    return await https.get(`${api_url}dashboard/portfolios?user_id=${id}`);
}
export async function getUserRating(id) {
    return await https.get(`${api_url}dashboard/users/${id}/ratings`);
}
export async function hideRate(rateId) {
    return await https.post(`${api_url}dashboard/ratings/${rateId}/hide`);
}
export async function getSuspendCases() {
    return await https.get(`${api_url}dashboard/users/suspend-cases`);
}
export async function suspendUser(obj) {
    return await https.post(`${api_url}dashboard/users/suspend`, obj);
}
export async function canselSuspention(id) {
    return await https.post(`${api_url}dashboard/users/cancel-suspend`, id);
}
export async function userCreate(obj) {
    return await https.post(`${api_url}dashboard/users`, obj);
}