import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getFaqsSections(obj){
    console.log(`${api_url}dashboard/faqs-sections?${obj}`);
    return await https.get(`${api_url}dashboard/faqs-sections?${obj}`);
}

export async function getFaqSection(id){
    return await https.get(`${api_url}dashboard/faqs-sections/${id}`);
}

export async function deleteFaqSection(id){
    return await https.delete(`${api_url}dashboard/faqs-sections/${id}`);
}

export async function updateFaqSection(id, data){
    return await https.put(`${api_url}dashboard/faqs-sections/${id}`, data);
}

export async function createFaqSection(data){
    return await https.post(`${api_url}dashboard/faqs-sections`, data);
}

export async function toggleStatusFaqSection(id){
    return await https.patch(`${api_url}dashboard/faqs-sections/${id}/toggle-active`);
}

export async function getMainFaqs(obj='',url){
    console.log(`${api_url}dashboard/${url}?${obj}`)
    return await https.get(`${api_url}dashboard/${url}?${obj}`);
}

export async function getMainFaq(id,url){
    console.log(`${api_url}dashboard/${url}/${id}`)
    return await https.get(`${api_url}dashboard/${url}/${id}`);
}

export async function deleteMainFaq(id,url){
    return await https.delete(`${api_url}dashboard/${url}/${id}`);
}

export async function updateMainFaq(id, data,url){
    return await https.put(`${api_url}dashboard/${url}/${id}`, data);
}

export async function createMainFaq(data,url){
    return await https.post(`${api_url}dashboard/${url}`, data);
}

export async function toggleStatusMainFaq(id,url){
    return await https.patch(`${api_url}dashboard/${url}/${id}/toggle-active`);
}