import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router';
import { getOrder } from '../../Apis/orders';
import TitleContext from '../../Contexts/TitleContext';
import pdfImg from '../../assets/images/pdfImg.jpg';
import { Gallery, Item } from 'react-photoswipe-gallery';
import vector from '../../assets/icons/Vector.svg';
import 'photoswipe/dist/photoswipe.css';
import { t } from 'i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function OrderDetails() {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState();
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(id)
        getOrder(id).then((res) => {
            console.log(res.data.data);
            setModel(res.data.data);
        })

        changeTitle(`order details`);

        // eslint-disable-next-line
    }, [pathname]);

    return (
        <section className='componentGlobalWrapper addBlog'>
            {model &&
                <div className=''>
                    {/* users */}
                    <div className='row mb-3'>
                        <div className='owner col-md-6 d-flex flex-column justify-content-center align-items-center border-end'>
                            <div className='mb-3 fs-6'>
                                <span>{t('owner')}</span>
                            </div>
                            <div className='pp rounded-circle' style={{ width: '100px', height: '100px', backgroundImage: `url(${model?.owner?.media?.url})` }}>
                            </div>
                            <div>
                                <a href={`/dashboard/users/${model?.owner?.id}`}>
                                    {model?.owner?.first_name} {model?.owner?.last_name}
                                </a>
                            </div>
                        </div>
                        <div className='freelancer col-md-6 d-flex flex-column justify-content-center align-items-center'>
                            <div className='mb-3 fs-6'>
                                <span>{t('freelancer')}</span>
                            </div>
                            <div className='pp rounded-circle' style={{ width: '100px', height: '100px', backgroundImage: `url(${model?.freelancer?.media?.url})` }}>
                            </div>
                            <div>
                                <a href={`/dashboard/users/${model?.freelancer?.id}`}>
                                    {model?.freelancer?.first_name} {model?.freelancer?.last_name}
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* type */}
                    <div className='row mb-3'>
                        <div className='col-12 text-center'>
                            <a className='btn btn-success me-3'> {t(`${model?.item?.type?.name}`)} </a>
                            <span className='btn btn-info'> {t(`${model?.stage?.name}`)} </span>
                            <div className='mt-3'>
                                <a href={`/dashboard/${model?.item?.type?.id === 1 ? 'services' : 'projects'}/${model?.item?.id}`} className='btn btn-light'>{model?.item?.title}</a>
                            </div>
                            {/* plan */}
                            {/* model?.item?.plan &&
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <strong className='text-muted'>{t('title')}:</strong>
                                    <span className='mb-3'>{model?.item?.plan?.title}</span>
                                    <strong className='text-muted'>{t('description')}:</strong>
                                    <span className='mb-3'>{model?.item?.plan?.description}</span>
                                    <strong className='text-muted'>{t('price')}:</strong>
                                    <span className='mb-3'>{model?.item?.plan?.price}</span>
                                    <strong className='text-muted'>{t('duration')}:</strong>
                                    <span className='mb-3'>{model?.item?.plan?.duration}</span>
                                    <strong className='text-muted'>{t('features')}:</strong>
                                    <span className='mb-3'>{model?.item?.plan?.features}</span>
                                </div> */
                            }
                        </div>
                    </div>


                    <div className='d-flex flex-column mb-4'>
                        <Link to={`/dashboard/orders/${model?.id}/discussions`}>
                            <button className='btn btn-link'>View Discussion</button>
                        </Link>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>order N.</label>
                        <input type='text' value={model?.id} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>title</label>
                        <input type='text' value={model?.item?.title} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>Category</label>
                        <input type='text' value={model?.item?.subsection?.section?.name} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>Subcategory</label>
                        <input type='text' value={model?.item?.subsection?.locales?.en?.name} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>price</label>
                        <input type='text' value={model?.price} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>site profit</label>
                        <input type='text' value={model?.profit} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>duration</label>
                        <input type='text' value={model?.duration} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>created_at</label>
                        <input type='text' value={moment(model?.created_at).format('llll')} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>delivery_date</label>
                        <input type='text' value={moment(model?.delivery_date).format('llll')} readOnly />
                    </div>
                </div>
            }
        </section>
    )
}
