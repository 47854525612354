import React, {  useEffect,  useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setData } from './formHandler';
import { supportedLanguages } from '../../../Utilities/SupportedLanguages';
import { getSubSection } from '../../../Apis/subSections';
import { preventEnter } from '../../../Utilities/preventEnter';

export default function Form(props) {
    const { pathname } = useLocation();
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id , sub_id} = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);   
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(()=>{
        console.log(model);
        // eslint-disable-next-line
    } , [model]);

    useEffect(() => {
        if (sub_id) {
            getSubSection(sub_id).then((res) => {
                let x = res.data.data;
                setModel({
                    locales: x.locales,
                    section: id
                });
            })
        }else{
            setModel({locales: {},section: id});
        }
        // eslint-disable-next-line
    }, [sub_id]);

    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setData(model, setModel, e, property , locale , language);
        let err = {...errors};
        err[property] = '';
        setErrors(err)
    }

    /* upload image */

    // onsubmit
    const confirmSubSection = async (e , model)=>{
        e.preventDefault();
        setLoading(true);
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
            navigate(-1);
            if(!sub_id){
                setModel({});
            }                
        }else{
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }




  return (
    <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{sub_id ? `update Subsection` : `add new Subsection`}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirmSubSection(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div  className="w-100">
                    {supportedLanguages.map((language)=>{
                        return(
                            <div key={language.slug} className='mb-4' style={{"width" : "50%"}}>
                                <div className='d-flex flex-column'>
                                    <label htmlFor={`name_${language.slug}`} className='formLabel'>name ({language.name})</label>
                                    <input name="name" id={`name_${language.slug}`} type='text' placeholder='name' value={model?.locales ? model?.locales[language.slug]?.name : " " } onChange={(e) => setFormData(e, 'name' , true , language.slug)} minLength={3} maxLength={255} required />
                                </div>
                                <div className='text-danger validateToDelete'>   
                                    {errors[`locales.${language.slug}.name`]}
                                </div>
                        </div>)})}
                    </div>
                </div>

                {!loading ? 
                    <button className='confirmBtn mt-5' type='submit'>{sub_id ? `save changes` : `add Subsection`}</button>
                    :
                    <button className='confirmBtn mt-5' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }

            </form>
    </section>
  )
}
