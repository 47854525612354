import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import edit_icon from "../../assets/icons/edit.svg";
import "../tables.scss";
import "../forms.scss";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
/* import Pagination from '../../Components/Pagination/Pagination'; */
import { getLevels } from "../../Apis/levels";
import moment from "moment";
import TitleContext from "../../Contexts/TitleContext";
import { basename } from "./../../Configurations/config";
import PermissionsContext from "../../Contexts/permissionsContext";

export default function Levels() {
	const [allLevels, setAllLevels] = useState([]);
	/* const [pageCount, setPageCount] = useState(0); */
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	/* const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1 });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10 }); */
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line
		changeTitle(`Levels`, {
			url: `${basename}/dashboard/levels/create`,
			text: "add Level",canAdd:Permissions?.level?.create
		});
	}, [pathname]);

	function getAllLevels(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&page=${filter.page}`;
		getLevels(params).then((res) => {
			setAllLevels(res.data.data);
			console.log(res.data.data);
			let total = res.data?.pagination?.total;
			changeTitle(`Levels (${total})`, {
				url: `${basename}/dashboard/levels/create`,
				text: "add Level",canAdd:Permissions?.level?.create
			});
			/*  setPageCount(Math.ceil(total / filter.per_page)); */
		});
	}

	useEffect(() => {
		getAllLevels({ per_page: 10, page: 1 });

		// eslint-disable-next-line
	}, []);

	/*  function changeFilterObj(e) {
        let thisObj = { ...filterObj2 }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj }
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        getAllLevels(thisObj)
    }


    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getAllLevels(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        setfilterObj2(thisObj2);
    } */

	return (
		<div>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						{/* <div className='searchActionsBar w-100'>
                            <div className='perPageContainer'>
                                <label htmlFor='selectShow' className='perPageLabel'>
                                    show
                                </label>
                                <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                    <option value='20'>20</option>
                                </select>
                            </div>
                            <button className='applySearchBtn' onClick={getFiltered}>search</button>
                        </div> */}
						{Permissions?.level?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>name</th>
											<th>created at</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allLevels &&
											allLevels.map((Level, index) => {
												return (
													<tr key={index}>
														<td>
															{Level?.name ? Level?.name : "no data added"}
														</td>
														<td>
															{Level?.created_at
																? moment(Level?.created_at).format("DD-MM-YYYY")
																: "no data added"}
														</td>
														<td>
															<div>
																{Permissions?.level?.edit && (
																	<Tooltip title="edit">
																		<Link
																			to={`${basename}/dashboard/levels/${Level.id}/update`}
																		>
																			<img
																				className="iconActions"
																				src={edit_icon}
																				alt="edit"
																			/>
																		</Link>
																	</Tooltip>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>
					{/* {<div className='d-flex justify-content-end mt-auto'>
                        <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
                    </div>} */}
				</section>
			}
		</div>
	);
}
