import React from "react";
import RateCard from "../../Components/rateCard/rateCard";
import TitleContext from '../../Contexts/TitleContext';
import acceptIcon from '../../assets/icons/accept-with-bg.svg';
import rejectIcon from '../../assets/icons/reject-with-bg.svg';
import RejectModal from '../../Utilities/rejectModal';
import RateSection from '../../Components/rateSection/rateSection';
import { getUserRating } from "../../Apis/user";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { useContext } from "react";

function UserRatings() {

  const { id } = useParams();
  const { pathname } = useLocation();
  const [userRatings, setUserRatings] = useState()
  let {changeTitle} = useContext(TitleContext)
  useEffect(() => {
    window.scrollTo(0, 0);
    changeTitle(`User Ratings`);
    getUserRating(id).then((res)=> {
      setUserRatings(res.data.data.ratings)
        console.log('userRatings', userRatings );
    })
    // eslint-disable-next-line
  }, []);
  console.log({userRatings})

  return (
    <div className="componentGlobalWrapper addBlog ">
      {userRatings &&
        userRatings.length > 0 &&
        userRatings.map((item) => {
          return (
            <div  key={item.id}>
              {<div className="p-4">
            {/* <div>{item.id}</div> */}
                <RateCard rateObj={item} />
              </div>
              }
            </div>
          );
        })}
    </div>
  );
}

export default UserRatings;
