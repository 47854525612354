import React, { useContext, useEffect, useState } from 'react'
import TitleContext from '../../Contexts/TitleContext';
import { supportedLanguages } from '../../Utilities/SupportedLanguages';
import { t } from 'i18next';
import { getGuidePageDetails, updateGuidePageDetails } from '../../Apis/guideSections';
import PermissionsContext from '../../Contexts/permissionsContext';

export default function Guide() {
    const { changeTitle } = useContext(TitleContext);
    const { Permissions } = useContext(PermissionsContext);
    let [guideLocales, setGuideLocales] = useState()
    let [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        changeTitle(`Guide Page`)
        getGuidePageDetails().then((res) => {
            setGuideLocales(res.data.data?.locales)
        })
        // eslint-disable-next-line
    }, []);

    function handleChange(e, lang) {
        let newData = Object.assign({}, guideLocales);
        /* if (newData[lang]) {
            newData[lang][e.target.name] = e.target.value;
        } else { */
            newData[lang] = {...newData[lang]}
            newData[lang][e.target.name] = e.target.value;
        // }
        setGuideLocales(newData)

        let err = { ...errors };
        err[`locales.${lang}.${e.target.name}`] = '';
        err[`locales`] = ''
        setErrors(err)
    }

    function submitHandler(e) {
        e.preventDefault()
        console.log(guideLocales)
        let x = { locales: guideLocales }
        setLoader(true)
        updateGuidePageDetails(x).then(() => {
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            setErrors(err.response.data.errors);
        })
    }
    return (
        <div>
            <section className='componentGlobalWrapper aboutus'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>page content</h5>
                    <form className='pagesForm' onSubmit={submitHandler} >
                        {supportedLanguages?.map((language, index) => {
                            return (
                                <div key={index} className='d-flex flex-column mb-4'>
                                    <label htmlFor='name' className='formLabel'>{`${t('name')} (${t(`${language.name}`)})`}</label>
                                    <div {...(language.slug === 'ar' ? { 'dir': 'rtl' } : {})}>
                                        <input id='name' name='name' value={guideLocales ? guideLocales[language.slug]?.name : " "} className='w-100 mb-3' onChange={(e) => handleChange(e, language.slug)} />
                                    </div>
                                    <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.name`]}
                                    </div>

                                    <label htmlFor='content' className='formLabel'>{`${t('description')} (${t(`${language.name}`)})`}</label>
                                    <div {...(language.slug === 'ar' ? { 'dir': 'rtl' } : {})}>
                                        <textarea className='textArea' name='description' value={guideLocales ? guideLocales[language.slug]?.description : " "} onChange={(e) => handleChange(e, language.slug)} />
                                    </div>
                                    <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.description`]}
                                    </div>
                                </div>
                            )
                        })}
                        {Permissions?.appearance?.edit && <>
                            {!loader ?
                                <div>
                                    <button className='confirmBtn' type='submit' >
                                        {t('save-changes')}
                                    </button>
                                </div>
                                :
                                <button className='confirmBtn' disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {t('saving')}...
                                </button>
                            }
                        </>}
                    </form>
                </div>
            </section>

        </div>
    )
}
