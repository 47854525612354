import React from 'react'
import { supportedLanguages } from './../../Utilities/SupportedLanguages';
import { useState } from 'react';
import { editPage } from '../../Apis/pages';

export default function ContactUsSectionForm({ model, setModel, sectionName, pageName }) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    console.log(model)
    const setFormData = (e, property, locale = false, language = null) => {
        let newDate = Object.assign({}, model);
        if (locale) {
            if (newDate[language]) {
                newDate[language][property] = e.target.value;
            } else {
                newDate[language] = {};
                newDate[language][property] = e.target.value;
            }
        }
        setModel(newDate)

        let err = { ...errors };
        if (language) {
            err[`locales.${language.slug}.${property}`] = ''
        } else {
            err[property] = '';
        }
        setErrors(err)
    }




    function submitSectionData(e) {
        e.preventDefault();
        setLoading(true);
        let data = { 'locales': model }
        editPage(pageName, data).then((res) => {
            console.log(res.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err.response)
            setErrors(err.response.data.data)
        })
    }




    return (
        <div className='homeSectionWrapper'>
            <form onSubmit={submitSectionData}>
                <div className='sectionTitle mb-2'>
                    <div className='d-flex align-items-center'>
                        <h5 className='formLabel mb-0 me-3'>{sectionName}</h5>
                    </div>
                    {!loading ?
                        <button className='confirmBtn updateBtn' type='submit'>update</button>
                        :
                        <button className='confirmBtn updateBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>

                <div className='formContainer'>
                    {supportedLanguages.map((language) => {
                        return (
                            <div key={language.slug} style={{ "width": "75%" }}>
                                <div className='d-flex flex-column mb-4'>
                                    <label htmlFor={`description_${language.slug}`} className='formLabel'>description ({language.name})</label>
                                    <textarea name="description" id={`description_${language.slug}`} type='text' placeholder='description' value={model?.[language.slug]?.description} onChange={(e) => setFormData(e, 'description', true, language.slug)} minLength={3} maxLength={500} required />
                                    <div className='text-danger validateToDelete'>
                                        {errors[`${language.slug}.description`]}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </form>
        </div>
    )
}
