import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getGuidePageDetails(){
    return await https.get(`${api_url}dashboard/settings/guide`);
}
export async function updateGuidePageDetails(data){
    return await https.put(`${api_url}dashboard/settings/guide`,data);
}
export async function getGuideSections(obj){
    return await https.get(`${api_url}dashboard/guide-sections?${obj}`);
}

export async function getGuideSection(id){
    return await https.get(`${api_url}dashboard/guide-sections/${id}`);
}

export async function deleteGuideSection(id){
    return await https.delete(`${api_url}dashboard/guide-sections/${id}`);
}

export async function updateGuideSection(id, data){
    return await https.put(`${api_url}dashboard/guide-sections/${id}`, data);
}

export async function createGuideSection(data){
    return await https.post(`${api_url}dashboard/guide-sections`, data);
}

export async function toggleStatusGuideSection(id){
    return await https.patch(`${api_url}dashboard/guide-sections/toggle-active/${id}`);
}
export async function activateSection(id){
    return await https.post(`${api_url}dashboard/guide-sections/${id}/activate`);
}
export async function deactivateSection(id){
    return await https.post(`${api_url}dashboard/guide-sections/${id}/deactivate`);
}
export async function activateArticle(id){
    return await https.post(`${api_url}dashboard/articles/${id}/activate`);
}
export async function deactivateArticle(id){
    return await https.post(`${api_url}dashboard/articles/${id}/deactivate`);
}