import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getSkills(obj){
    console.log(`${api_url}dashboard/skills?${obj}`);
    return await https.get(`${api_url}dashboard/skills?${obj}`);
}

export async function getSkill(id){
    return await https.get(`${api_url}dashboard/skills/${id}`);
}

export async function deleteSkill(id){
    return await https.delete(`${api_url}dashboard/skills/${id}`);
}

export async function updateSkill(id, data){
    return await https.put(`${api_url}dashboard/skills/${id}`, data);
}

export async function createSkill(data){
    return await https.post(`${api_url}dashboard/skills`, data);
}