import { https } from './https';
import { api_url } from '../Configurations/config';

//get loggedin admin profile info
export async function getAllSliders(){
    return await https.get(`${api_url}dashboard/slider-images`);
}

export async function getSlider(id){
    return await https.get(`${api_url}dashboard/slider-images/${id}`);
}

export async function changeSliderStatus(id){
    return await https.patch(`${api_url}dashboard/slider-images/${id}/toggle-active`);
}

export async function createSlider(data){
    return await https.post(`${api_url}dashboard/slider-images`,data);
}

export async function updateSlider(id,data){
    return await https.put(`${api_url}dashboard/slider-images/${id}`,data);
}

export async function deleteSlider(id){
    return await https.delete(`${api_url}dashboard/slider-images/${id}`);
}

