import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import pause_icon from "../../assets/icons/pause.svg";
import play_icon from "../../assets/icons/play.svg";
import '../tables.scss';
import '../forms.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useEffect } from 'react';
import Pagination from '../../Components/Pagination/Pagination';
import { deleteGuideArticle, getGuideArticles } from '../../Apis/guideArticles';
import DeleteModal from '../../Utilities/deleteModal';
import moment from 'moment';
import { basename } from './../../Configurations/config';
import TitleContext from '../../Contexts/TitleContext';
import { activateArticle, deactivateArticle } from '../../Apis/guideSections';
import PermissionsContext from '../../Contexts/permissionsContext';

export default function GuideArticles() {
	const [allGuideArticles, setAllGuideArticles] = useState([]);
	const { id } = useParams();
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		search: "",
		guide_section: id,
		status: 1,
	});
	const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
	const [deletedArticle, setDeletedArticle] = useState("");
	const [deletedName, setDeletedName] = useState("article");
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, [pathname]);

	function getAllGuideArticles(obj) {
		let filter = { ...obj }
		let params = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}&guide_section=${filter.guide_section}`;
		console.log(params)
		getGuideArticles(params).then((res) => {
			console.log(res?.data?.data);
			setAllGuideArticles(res.data.data.articles);
			let total = res.data?.pagination?.total;
			changeTitle(`Guide Articles at ${res?.data?.data?.guide_section?.name} (${total})`, { url: `${basename}/dashboard/guide/guide-sections/${id}/articles/create`, text: 'add Article', canAdd: Permissions?.article?.create });
			setPageCount(Math.ceil(total / filter.per_page));
		})
	}

	useEffect(() => {
		getAllGuideArticles(filterObj);

		// eslint-disable-next-line
	}, []);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		setfilterObj(thisObj);
		getAllGuideArticles(thisObj);
	}

	async function onSubmitDelete() {
		return await deleteGuideArticle(deletedArticle)
			.then((res) => {
				getAllGuideArticles(filterObj);
				return "done";
			})
			.catch((err) => {
				console.log(err.response);
				return err.response.data.message;
			});
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getAllGuideArticles(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		setfilterObj2(thisObj2);
	};

	function toggleActivate(id, action) {
		if (action === "activate") {
			activateArticle(id).then((res) => {
				console.log(res.data);
				getAllGuideArticles(filterObj);
			});
		} else {
			deactivateArticle(id).then((res) => {
				console.log(res.data);
				getAllGuideArticles(filterObj);
			});
		}
	}

	function changeStatus(status) {
		let thisObj = { ...filterObj };
		thisObj.status = status;
		thisObj.page = 1;
		setfilterObj(thisObj);
		getAllGuideArticles(thisObj);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = thisObj.per_page;
		thisObj2.search = thisObj.search;
		setfilterObj2(thisObj2);
	}

	function clickDelete(item) {
		setDeletedArticle(item.id);
		setDeletedName(item?.locales?.en?.title.substring(0, 25));
	}

	return (
		<>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						<div
							className="tabs-container nav nav-tabs d-flex align-items-center"
							id="myTab"
							role="tablist"
						>
							<div
								onClick={() => changeStatus(1)}
								className="tablinks active"
								id="Gallery-tab"
								data-bs-toggle="tab"
								data-bs-target="#allnews"
							>
								active
							</div>
							<span className="separator"></span>
							<div
								onClick={() => changeStatus(0)}
								className="tablinks"
								id="published-tab"
								data-bs-toggle="tab"
								data-bs-target="#published-Img"
							>
								inactive
							</div>
						</div>

						<div className="searchActionsBar w-100">
							<div className="searchActionsBar mt-0 mb-0">
								{/*** search input ***/}
								<div className="searchInputWrapper">
									<div>
										<input
											type="text"
											className="barSearchInput"
											name="search"
											onChange={changeFilterObj}
										/>
									</div>
									<img
										src={search}
										alt="search-icon"
										className="barSearchIcon"
									/>
								</div>
							</div>

							{/* show select */}
							<div className="perPageContainer">
								<label htmlFor="selectShow" className="perPageLabel">
									show
								</label>
								<select
									name="per_page"
									onChange={changeFilterObj}
									id={`selectShow`}
									className="form-select selectShow"
									value={filterObj2?.per_page}
								>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
								</select>
							</div>
							{/*** apply search btn  ***/}
							<button className="applySearchBtn" onClick={getFiltered}>
								search
							</button>
						</div>

						{Permissions?.article?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>image</th>
											<th>title</th>
											<th>created at</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allGuideArticles &&
											allGuideArticles.map((item, index) => {
												return (
													<tr key={index}>
														<td className="sliderImg">
															<img
																src={`${item?.media !== null ? item?.media?.url : ""
																	}`}
																alt=""
															/>
														</td>
														<td className="desc">
															<div className="descriptionTd">
																{item?.name ?? "no data added"}
															</div>
														</td>
														<td>
															{item?.created_at
																? moment(item?.created_at).format("DD-MM-YYYY")
																: "no data added"}
														</td>
														<td>
															<div>
																{Permissions?.article?.edit && (
																	<Tooltip title="edit">
																		<Link
																			to={`${basename}/dashboard/guide/guide-sections/${id}/articles/${item?.id}/update`}
																		>
																			<img
																				className="iconActions"
																				src={edit_icon}
																				alt="edit"
																			/>
																		</Link>
																	</Tooltip>
																)}
																{Permissions?.article?.activate && (
																	<>
																		{item?.status === 1 ? (
																			<Tooltip title="deactivate">
																				<img
																					onClick={() =>
																						toggleActivate(item.id, "deactivae")
																					}
																					className="iconActions"
																					src={pause_icon}
																					alt=""
																				/>
																			</Tooltip>
																		) : (
																			<Tooltip title="activate">
																				<img
																					onClick={() =>
																						toggleActivate(item.id, "activate")
																					}
																					className="iconActions"
																					src={play_icon}
																					alt=""
																				/>
																			</Tooltip>
																		)}
																	</>
																)}
																{Permissions?.article?.delete && (
																	<Tooltip title="delete this article">
																		<img
																			data-bs-toggle="modal"
																			onClick={() => clickDelete(item)}
																			data-bs-target="#deleteModal"
																			className="iconActions"
																			src={delete_icon}
																			alt="edit"
																		/>
																	</Tooltip>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>

					{
						<div className="d-flex justify-content-end mt-auto">
							<Pagination
								pagesNumber={pageCount ?? 1}
								page={filterObj.page - 1 ?? 1}
								pageChangeHandler={handlepageChange}
							/>
						</div>
					}

					<DeleteModal
						onSubmitDelete={onSubmitDelete}
						name={deletedName}
					></DeleteModal>
				</section>
			}
		</>
	);
}
