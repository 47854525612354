import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import TitleContext from '../../../Contexts/TitleContext';
import formModel from './formModel';
import { basename } from '../../../Configurations/config';
import { setAdminData } from './formHandler';
import { getAdmin } from '../../../Apis/admins';
import { getRoles } from '../../../Apis/roles'; 
import { t } from 'i18next';

export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [allRoles, setAllRoles] = useState([]);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    let navigate = useNavigate();
    let [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get all roles to fill selectbox options
    function getAllRoles(){
        let myallRoles = [...allRoles];
        getRoles().then(res => {
            myallRoles = res.data.data;
            setAllRoles(myallRoles);
        }).catch(err => 
            console.log(err))
    }

    useEffect(()=>{
        changeTitle(`${id ? t('edit-admin') : t(`add-admin`)} `);
        getAllRoles();
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        if (id) {
            getAdmin(id).then((res) => {
                let x = res.data.data;
                console.log(x)
                let m ={
                    username: x?.username,
                    name: x?.name,
                    email: x?.email,
                    role: x?.role,
                }
                setModel({...m});
            })
        }else{
            setModel({
                username: '',
                name: '',
                email: '',
                role: '',
                password: '',
                password_confirmation: ''
            })
        }
    }, [id]);

    // onchange
    const setFormData = (e, property) => {
        console.log(property)
        setAdminData(model, setModel, e, property);
    }



    // onsubmit
    const confirmAdmin = async (e , model)=>{
        e.preventDefault();
        setLoading(true);

        // send request
        let submitProps = await props.onSubmit(model);
        if(submitProps === "done"){
            setLoading(false);
            navigate(`${basename}/dashboard/admins/admins-list`);
            if(!id){
                setModel({
                    "name" : '',
                    "email" : '',
                    "password" : '',
                    "role" : '',
                    "username" : '',
                });
            }                
        }else{
            setLoading(false);
            setErrors(submitProps);
        }
        
    }

    return (
        <>
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{t('admin-details')}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirmAdmin(e, model)}>
                <div className='d-flex justify-content-start'>
                    <div className='pagesForm'>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='firstname' className='formLabel'>{t('name')}</label>
                            <input name="name" id='firstname' type='text' value={(model && model.name) } onChange={(e) => setFormData(e, 'name')} required pattern="\S(.*\S)?" title="This field is required" minLength='3' maxLength='255' />
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='username' className='formLabel'>{t('username')}</label>
                            <input name="username" id='username' type='text' value={(model && model.username) ? model.username : ""} onChange={(e) => setFormData(e, 'username')} required pattern="\S(.*\S)?" title="This field is required"/>
                            {errors?.username && <div className='text-danger validateToDelete'>   
                                {errors?.username[0]}
                            </div>}
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='email' className='formLabel'>{t('email')}</label>
                            <input name="email" id='email' type='email' value={(model && model.email) ? model.email : ""} onChange={(e) => setFormData(e, 'email')} required pattern="\S(.*\S)?" title="This field is required"/>
                            {errors?.email && <div className='text-danger validateToDelete'>   
                                {errors?.email[0]}
                            </div>}
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='role' className='formLabel'>{t('role')}</label>
                            <div className='selectParent'>
                                <select name='role' value={(model && model.role) ? model.role : ""} onChange={(e) => setFormData(e, 'role')} required>
                                    <option value='' disabled>{t('select-role')}</option>
                                    {allRoles && allRoles.map((role , index)=> (
                                        <option value={role.id} key={index}>{role.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='password' className='formLabel'>{t('password')}</label>
                            <input name="password" minLength={8} maxLength='50' id='password' type='text' onChange={(e) => setFormData(e, 'password')} pattern="\S(.*\S)?" {... !id ? {'required':true} : {}} />
                        </div>
                        <div className='d-flex flex-column mb-5'>
                            <label htmlFor='confirm_password' className='formLabel'>{t('confirm-password')}</label>
                            <input name="password_confirmation" minLength={8} maxLength='50' id='confirm_password' type='text' onChange={(e) => setFormData(e, 'password_confirmation')}  pattern="\S(.*\S)?" {... !id ? {'required':true} : {}} />
                            {errors?.password?.map((err,index) =>( <div key={index} className='text-danger validateToDelete'>   
                                {err}
                            </div>))}
                        </div>
                    </div>
                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>{id ? t('save-changes') : t('add-admin')}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {t('saving')}...
                    </button>
                }
            </form>
        </section>
        </>
    )
}
