import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getSubSections(obj) {
    console.log(`${api_url}dashboard/subsections?${obj}`);
    return await https.get(`${api_url}dashboard/subsections?${obj}`);
}

export async function getSubSection(id) {
    return await https.get(`${api_url}dashboard/subsections/${id}`);
}

export async function deleteSubSection(id) {
    return await https.delete(`${api_url}dashboard/subsections/${id}`);
}

export async function updateSubSection(id, data) {
    return await https.patch(`${api_url}dashboard/subsections/${id}`, data);
}

export async function createSubSection(data) {
    return await https.post(`${api_url}dashboard/subsections`, data);
}
