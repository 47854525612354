import moment from 'moment/moment';
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { 
  getWithdraws,
  acceptWithdraw,
  rejectWithdraw,
} from '../../Apis/withdraws';
import Pagination from '../../Components/Pagination/Pagination';
import { basename } from '../../Configurations/config';
import PermissionsContext from '../../Contexts/permissionsContext';
import TitleContext from '../../Contexts/TitleContext';
import { Tooltip } from '@mui/material';
import rejectIcon from "../../assets/icons/reject.svg";
import acceptIcon from "../../assets/icons/accept.svg";
import details from "../../assets/icons/details.svg";
import RejectModal from '../../Utilities/rejectModal';
import { Link, useSearchParams } from 'react-router-dom';
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import Loader from '../../Components/Loader/Loader';

const Withdraws = () => {
    const [allWithdraws, setAllWithdraws] = useState([]);
    
    const [pageCount, setPageCount] = useState(0);
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({
        per_page: 10,
        page: 1,
    });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
    let [searchParams] = useSearchParams();
    const [status, setStatus] = useState(Number(searchParams.get("status")) ?? 1);
    const location = useLocation();
    const navigate = useNavigate();

  const [withdrawsCount, setWithdrawsCount] = useState({});

    const { Permissions } = useContext(PermissionsContext);

    const [deleted, setdeleted] = useState(false);
    const [deletedName, setDeletedName] = useState("withdraw");

    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setStatus(Number(searchParams.get("status")));
      setStatus(Number(searchParams.get("status")));
      setfilterObj({ ...filterObj, status: Number(searchParams.get("status"))});
    }, [location]);

    const getAllWithdraws = async (filterObj) => {
        setLoading(true)
        let filter = { ...filterObj };
        let params = `per_page=${filter.per_page}&page=${filter.page}&status=${filter.status}`;
        const res = await getWithdraws(params)
        const data = await res.data
        setAllWithdraws(data.data)
        let total = data?.pagination?.total;
        changeTitle(`Withdraws (${total})`);
        setWithdrawsCount(res?.data?.overview);
        setPageCount(Math.ceil(total / filter.per_page));
        setLoading(false)
    }
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
        
        changeTitle(`Withdraws`);
        // eslint-disable-next-line
    }, [pathname]);


    useEffect(() => {
        getAllWithdraws(filterObj);

        // eslint-disable-next-line
    }, []);

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 };
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj };
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        getAllWithdraws(thisObj);
    }

    function changeStatus(status) {
      let thisObj = { ...filterObj };
      thisObj.status = status;
      thisObj.page = 1;
      setfilterObj(thisObj);
      getAllWithdraws(thisObj);

      let thisObj2 = { ...filterObj2 };
      thisObj2.per_page = thisObj.per_page;
      thisObj2.search = thisObj.search;
      setfilterObj2(thisObj2);

      navigate(`${basename}/dashboard/withdraws?status=${status}`);
      setStatus(status);
    }

    const handlepageChange = async (data) => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getAllWithdraws(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    };

    function acceptWithdrawHandler(id) {
        acceptWithdraw(id)
          .then((res) => {
            getAllWithdraws(filterObj);
          })
          .catch((err) => {
            console.log(err.response);
          });
    }

    async function rejectWithdrawHandler() {
        return await rejectWithdraw(deleted, { reason: reason })
          .then((res) => {
            getAllWithdraws(filterObj);
            return "done";
          })
          .catch((err) => {
            console.log(err.response);
          });
    }

    function clickDelete(item, handler) {
      if (handler === "delete") {
        setdeleted(item.id);
        setDeletedName(item?.user?.username?.substring(0, 25));
      } else {
        setOpen(true);
        setdeleted(item.id);
        setDeletedName(item?.user?.username?.substring(0, 25));
      }
    }

    return (
        <>{
            <section className="componentGlobalWrapper d-flex flex-column">
                <div>
                    <div
                      className="tabs-container nav nav-tabs d-flex align-items-center"
                      id="myTab"
                      role="tablist"
                    >
                      <div
                        onClick={() => changeStatus(0)}
                        className={`tablinks ${status === 0 && `active`}`}
                        id="published-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#published-Img"
                      >
                        Pending{" "}
                        <span className="tabcounter">
                          {" "}
                          ({withdrawsCount["pending"]}){" "}
                        </span>
                      </div>
                      <span className="separator"></span>

                      <div
                        onClick={() => changeStatus(1)}
                        className={`tablinks ${status === 1 && `active`}`}
                        id="Gallery-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#allnews"
                      >
                        accepted{" "}
                        <span className="tabcounter">({withdrawsCount["accepted"]})</span>
                      </div>
                      <span className="separator"></span>

                      <div
                        onClick={() => changeStatus(2)}
                        className={`tablinks ${status === 2 && `active`}`}
                        id="published-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#published-Img"
                      >
                        rejected{" "}
                        <span className="tabcounter">
                          {" "}
                          ({withdrawsCount["rejected"]}){" "}
                        </span>
                      </div>
                    </div>
                    <div className="searchActionsBar w-100">
                        {/* show select */}
                        <div className="perPageContainer">
                            <label htmlFor="selectShow" className="perPageLabel">
                                show
                            </label>
                            <select
                                name="per_page"
                                onChange={changeFilterObj}
                                id={`selectShow`}
                                className="form-select selectShow"
                                value={filterObj2?.per_page}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/*** apply search btn  ***/}
                        <button className="applySearchBtn" onClick={getFiltered}>
                            show
                        </button>
                    </div>
                    {
                        <div className="tableWrapper">
                            {Permissions?.withdraw?.view && (
                                <table className="w-100 table table-striped ">
                                    <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>name</th>
                                            <th>amount</th>
                                            <th>created at</th>
                                            {filterObj?.status === 2 ? (
                                              <th>reason</th>
                                            ) : (
                                              ""
                                            )}
                                            <th>control</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                          loading? <td className='w-100' colSpan={6}><Loader/></td>
                                          : allWithdraws && allWithdraws.length ?
                                            allWithdraws.map((withdraw, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {withdraw?.id ? withdraw?.id : "no data added"}
                                                        </td>
                                                        <td>
                                                            {withdraw?.user?.username ? withdraw?.user?.username : "no data added"}
                                                        </td>
                                                        <td>
                                                            {withdraw?.amount}
                                                        </td>
                                                        <td>
                                                            {withdraw?.created_at
                                                                ? moment(withdraw?.created_at).format(
                                                                    "DD-MM-YYYY"
                                                                )
                                                                : "no data added"}
                                                        </td>
                                                        {(withdraw?.status === 2) && (
                                                            <td>
                                                              {withdraw?.status === 2
                                                                  ? withdraw?.reject_reasons?.message
                                                                  : "no data added"}
                                                            </td>
                                                          )}
                                                        <td>
                                                          <Tooltip title="details">
                                                            <Link
                                                              to={`${basename}/dashboard/withdraws/${withdraw.id}`}
                                                            >
                                                              <img
                                                                className="iconActions"
                                                                src={details}
                                                                alt="details"
                                                              />
                                                            </Link>
                                                          </Tooltip>
                                                            {(withdraw?.status === 0) && (
                                                                <div className='d-flex justify-content-start align-items-center'>
                                                                  {/* {Permissions?.withdraw?.accept && ( */}
                                                                    <Tooltip title="accept">
                                                                      <div
                                                                        className="d-flex"
                                                                        onClick={() =>
                                                                          acceptWithdrawHandler(withdraw.id)
                                                                        }
                                                                      >
                                                                        <img
                                                                          className="iconActions"
                                                                          src={acceptIcon}
                                                                          alt="accept"
                                                                        />
                                                                      </div>
                                                                    </Tooltip>
                                                                  {/* )} */}
                                                                  {/* {Permissions?.withdraw?.reject && ( */}
                                                                    <Tooltip title="reject">
                                                                      <div
                                                                        className="d-flex"
                                                                        onClick={() =>
                                                                          clickDelete(withdraw, "reject")
                                                                          // console.log('clickDelete')
                                                                        }
                                                                      >
                                                                        <img
                                                                          className="iconActions"
                                                                          src={rejectIcon}
                                                                          alt="accept"
                                                                        />
                                                                      </div>
                                                                    </Tooltip>
                                                                  {/* )} */}
                                                                </div>
                                                              )}
                                                        </td>
                                                    </tr>
                                                );
                                            }) : <td className='w-100' colSpan={5}><NotDataFound /></td>}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    }
                </div>
                {
                    <div className="d-flex justify-content-end mt-auto">
                        <Pagination
                            pagesNumber={pageCount}
                            page={filterObj.page - 1}
                            pageChangeHandler={handlepageChange}
                        />
                        <RejectModal
                          open={open}
                          setOpen={setOpen}
                          title={deletedName}
                          onSubmitReject={rejectWithdrawHandler}
                          reason={reason}
                          setReason={setReason}
                          errors={errors}
                          setErrors={setErrors}
                        />
                    </div>
                }
            </section>
        }
        </>
    )
}

export default Withdraws