import { https } from "./https";
import { api_url } from "../Configurations/config";

//login
export async function getAllNotifications() {
  return await https.get(`${api_url}dashboard/admins/notifications`);
}
export async function readMyNotifications(data) {
  return await https.post(`${api_url}dashboard/admins/read-notification`, {"id" : data});
}
