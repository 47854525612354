import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import edit_icon from "../../assets/icons/edit.svg";
import "../tables.scss";
import "../forms.scss";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import { getFees } from "../../Apis/fees";
import moment from "moment";
import TitleContext from "../../Contexts/TitleContext";
import { basename } from "./../../Configurations/config";
import search from "../../assets/icons/search.svg";
import PermissionsContext from "../../Contexts/permissionsContext";
import NotDataFound from "../../Components/NotDataFound/notDataFound";

export default function Fees() {
	const [allFees, setAllFees] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		search: "",
		status: "1",
	});
	const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, [pathname]);

	function getAllFees(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&page=${filter.page}&search=${filter.search}&status=${filter.status}`;
		getFees(params).then((res) => {
			setAllFees(res.data.data);
			console.log(res.data.data);
			let total = res.data?.pagination?.total;
			changeTitle(`Fees (${total})`);
			setPageCount(Math.ceil(total / filter.per_page));
		});
	}

	useEffect(() => {
		getAllFees(filterObj);

		// eslint-disable-next-line
	}, []);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		setfilterObj(thisObj);
		getAllFees(thisObj);
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getAllFees(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		setfilterObj2(thisObj2);
	};

	function changeStatus(status) {
		let thisObj = { ...filterObj };
		thisObj.status = status;
		thisObj.page = 1;
		setfilterObj(thisObj);
		getAllFees(thisObj);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = thisObj.per_page;
		thisObj2.search = thisObj.search;
		setfilterObj2(thisObj2);
	}

	return (
		<div>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						<div
							className="tabs-container nav nav-tabs d-flex align-items-center"
							id="myTab"
							role="tablist"
						>
							<div
								onClick={() => changeStatus("1")}
								className="tablinks active"
								id="Gallery-tab"
								data-bs-toggle="tab"
								data-bs-target="#allnews"
							>
								active Fees<span className="tabcounter">(2)</span>
							</div>
							<span className="separator"></span>
							<div
								onClick={() => changeStatus("0")}
								className="tablinks"
								id="published-tab"
								data-bs-toggle="tab"
								data-bs-target="#published-Img"
							>
								inactive Fees <span className="tabcounter"> (0) </span>
							</div>
						</div>
						<div className="searchActionsBar w-100">
							<div className="searchActionsBar mt-0 mb-0">
								{/*** search input ***/}
								<div className="searchInputWrapper">
									<div>
										<input
											type="text"
											className="barSearchInput"
											name="search"
											onChange={changeFilterObj}
										/>
									</div>
									<img
										src={search}
										alt="search-icon"
										className="barSearchIcon"
									/>
								</div>
							</div>
							{/* show select */}
							<div className="perPageContainer">
								<label htmlFor="selectShow" className="perPageLabel">
									show
								</label>
								<select
									name="per_page"
									onChange={changeFilterObj}
									id={`selectShow`}
									className="form-select selectShow"
									value={filterObj2?.per_page}
								>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
								</select>
							</div>
							{/*** apply search btn  ***/}
							<button className="applySearchBtn" onClick={getFiltered}>
								search
							</button>
						</div>
						{Permissions?.fee?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>name</th>
											<th>value</th>
											<th>created at</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allFees &&
											allFees.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item?.name ? item?.name : "no data added"}</td>
														<td>
															{item?.value ? item?.value : "no data added"}{" "}
															{item?.is_percentage ? "%" : "AED"}
														</td>
														<td>
															{console.log("This is The Item", item)}
															{item?.updated_at 
																? (moment(item?.updated_at).format("DD-MM-YYYY")) 
																: item?.created_at 
																	? (moment(item?.created_at).format("DD-MM-YYYY")) 
																	: "No Created Date"}
														</td>
														<td>
															<div>
																{Permissions?.fee?.edit && (
																	<Tooltip title="edit">
																		<Link
																			to={`${basename}/dashboard/fees/${item.id}/update`}
																		>
																			<img
																				className="iconActions"
																				src={edit_icon}
																				alt="edit"
																			/>
																		</Link>
																	</Tooltip>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>
					{
						<div className="d-flex justify-content-end mt-auto">
							<Pagination
								pagesNumber={pageCount}
								page={filterObj.page - 1}
								pageChangeHandler={handlepageChange}
							/>
						</div>
					}
				</section>
			}
		</div>
	);
}
