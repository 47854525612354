import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { archiveValidation, resetInput } from './deleteHandler';
import { basename } from '../Configurations/config';
import { useNavigate } from 'react-router';
import { t } from 'i18next';

export default function DeleteModal(props) {
    const [loading, setLoading] = useState(false);
    const [validateDelete, setValidateDelete] = useState("");
    const deleteModalRef = useRef();
    const closeDeleteModalRef = useRef();
    let [errors, setErrors] = useState('');
    const navigate = useNavigate();

    const confirmDelete = async (e) => {
        e.preventDefault();
        if (validateDelete === "ARCHIVE") {
            setLoading(true);
            let submitProps = await props.onSubmitDelete();
            console.log(submitProps)
            if (submitProps === "done") {
                setLoading(false);
                closeDeleteModalRef.current.click();
                document.getElementById('deleteForm').reset();
            } else if (submitProps === 'deleteRole') {
                setLoading(false);
                closeDeleteModalRef.current.click();
                navigate(`${basename}/dashboard/admins/roles/add`);
            } else {
                setLoading(false);
                setErrors(submitProps);
            }
        } else {
            setLoading(false);
            deleteModalRef.current.classList.remove("d-none");
        }
    }

    return (
        <div>
            <div className="modal fade" id="deleteModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div ref={closeDeleteModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetInput("deleteForm", deleteModalRef, setValidateDelete, setErrors)}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </div>
                        <div className="modal-body">
                            <form id="deleteForm" onSubmit={confirmDelete} style={{ 'width': '100%' }}>
                                <div className='formInputsWrapper' style={{ 'width': '100%' }}>
                                    <p>{t('do-you-want-to-archive-this')} {t(`${props.name}`)} {t('?')}</p>
                                    <div className='mb-4'>
                                        <div className="form-floating">
                                            <input autoComplete='off' onChange={(e) => archiveValidation(e, setValidateDelete, deleteModalRef)} className='w-100 form-control' name="validate" id='floatingInput2' type='text' placeholder='archive' required pattern="\S(.*\S)?" title="This field is required" />
                                            <label htmlFor="floatingInput2">{t('please-type')} <span>"ARCHIVE"</span> {t('to-confirm')}</label>
                                        </div>
                                        <div ref={deleteModalRef} className='validateToDelete d-none text-danger mt-1'>
                                            {t('please-type-"ARCHIVE"-in-capital-letters')}.
                                        </div>
                                        {(errors) ? <div className="validateToDelete text-danger mt-1">{errors}</div> : ''}
                                    </div>
                                    {/*** modal footer ***/}
                                    <div className='d-flex justify-content-end align-items-end'>
                                        <div className='cancelBtn' onClick={() => resetInput("deleteForm", deleteModalRef, setValidateDelete, setErrors)} data-bs-dismiss="modal">{t('cancel')}</div>
                                        {!loading ?
                                            <button className={validateDelete === "ARCHIVE" ? `deleteBtn` : `confirmBtn`} type='submit'>{t('archive')}</button>
                                            :
                                            <button className='confirmBtn deleteBtn' disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {t('archiving')}...
                                            </button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
