import { https } from './https';
import { api_url } from './../Configurations/config';


//get settings
export async function getHomeSections(){
    return await https.get(`${api_url}dashboard/settings/home`);
}

//post settings
export async function updateHomeSections(data){
    return await https.put(`${api_url}dashboard/settings/home`,data);
}