import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getDeleteReqs(obj) {
    return await https.get(`${api_url}dashboard/delete-account-requests?${obj}`);
}

export async function toggleRead(id, type) {
    return await https.patch(`${api_url}dashboard/delete-account-requests/${id}/${type}`);
}

export async function deleteUser(id,data) {
    return await https.post(`${api_url}dashboard/users/${id}`,data);
}