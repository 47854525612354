import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import "../tables.scss";
import "../forms.scss";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import { deleteCoupon, getCoupons } from "../../Apis/coupons";
import moment from "moment";
import TitleContext from "../../Contexts/TitleContext";
import { basename } from "./../../Configurations/config";
import search from "../../assets/icons/search.svg";
import DeleteModal from "../../Utilities/deleteModal";
import PermissionsContext from "../../Contexts/permissionsContext";

export default function Coupons() {
	const [allCoupons, setAllCoupons] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		search: "",
	});
	const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
	const [deletedItem, setDeletedItem] = useState("");
	const [deletedName, setDeletedName] = useState("coupon");
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line
		changeTitle(`Coupons`, {
			url: `${basename}/dashboard/coupons/create`,
			text: "add Coupon",
			canAdd: Permissions?.coupon?.create,
		});
	}, [pathname]);

	function getAllCoupons(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&page=${filter.page}&search=${filter.search}`;
		getCoupons(params).then((res) => {
			setAllCoupons(res.data.data);
			let total = res.data?.pagination?.total;
			changeTitle(`Coupons (${total})`, {
				url: `${basename}/dashboard/coupons/create`,
				text: "add Coupon",
				canAdd: Permissions?.coupon?.create,
			});
			setPageCount(Math.ceil(total / filter.per_page));
		});
	}

	useEffect(() => {
		getAllCoupons(filterObj);

		// eslint-disable-next-line
	}, []);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		setfilterObj(thisObj);
		getAllCoupons(thisObj);
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getAllCoupons(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		setfilterObj2(thisObj2);
	};

	function clickDelete(item) {
		setDeletedItem(item.id);
		setDeletedName(item?.code);
	}

	async function onSubmitDelete() {
		return await deleteCoupon(deletedItem)
			.then((res) => {
				getAllCoupons(filterObj);
				return "done";
			})
			.catch((err) => {
				console.log(err.response);
				return err.response.data.message;
			});
	}

	return (
		<div>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						<div className="searchActionsBar w-100">
							<div className="searchActionsBar mt-0 mb-0">
								{/*** search input ***/}
								<div className="searchInputWrapper">
									<div>
										<input
											type="text"
											className="barSearchInput"
											name="search"
											onChange={changeFilterObj}
										/>
									</div>
									<img
										src={search}
										alt="search-icon"
										className="barSearchIcon"
									/>
								</div>
							</div>
							{/* show select */}
							<div className="perPageContainer">
								<label htmlFor="selectShow" className="perPageLabel">
									show
								</label>
								<select
									name="per_page"
									onChange={changeFilterObj}
									id={`selectShow`}
									className="form-select selectShow"
									value={filterObj2?.per_page}
								>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
								</select>
							</div>
							{/*** apply search btn  ***/}
							<button className="applySearchBtn" onClick={getFiltered}>
								search
							</button>
						</div>
						{Permissions?.coupon?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>coupon code</th>
											<th>value</th>
											<th>created at</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allCoupons &&
											allCoupons.map((Coupon, index) => {
												return (
													<tr key={index}>
														<td>
															{Coupon?.code ? Coupon?.code : "no data added"}
														</td>
														<td>
															{Coupon?.value ? Coupon?.value : "no data added"}{" "}
															{Coupon?.is_percentage ? "%" : "AED"}
														</td>
														<td>
															{Coupon?.created_at
																? moment(Coupon?.created_at).format(
																		"DD-MM-YYYY"
																  )
																: "no data added"}
														</td>
														<td>
															<div>
																{Permissions?.coupon?.edit && (
																	<Tooltip title="edit">
																		<Link
																			to={`${basename}/dashboard/Coupons/${Coupon.id}/update`}
																		>
																			<img
																				className="iconActions"
																				src={edit_icon}
																				alt="edit"
																			/>
																		</Link>
																	</Tooltip>
																)}
																{Permissions?.coupon?.delete && (
																	<Tooltip title="delete this section">
																		<img
																			data-bs-toggle="modal"
																			onClick={() => clickDelete(Coupon)}
																			data-bs-target="#deleteModal"
																			className="iconActions"
																			src={delete_icon}
																			alt="edit"
																		/>
																	</Tooltip>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>
					{
						<div className="d-flex justify-content-end mt-auto">
							<Pagination
								pagesNumber={pageCount}
								page={filterObj.page - 1}
								pageChangeHandler={handlepageChange}
							/>
						</div>
					}
					<DeleteModal
						onSubmitDelete={onSubmitDelete}
						name={deletedName}
					></DeleteModal>
				</section>
			}
		</div>
	);
}
