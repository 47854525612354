import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getCoupons(obj){
    return await https.get(`${api_url}dashboard/coupons?${obj}`);
}

export async function getCoupon(id){
    return await https.get(`${api_url}dashboard/coupons/${id}`);
}

export async function deleteCoupon(id){
    return await https.delete(`${api_url}dashboard/coupons/${id}`);
}

export async function updateCoupon(id, data){
    return await https.put(`${api_url}dashboard/coupons/${id}`, data);
}

export async function createCoupon(data){
    return await https.post(`${api_url}dashboard/coupons`, data);
}