import { createContext } from "react";
import { useState } from "react";

let TitleContext = createContext("");

export function TitleContextProvider(props){
    
    let[Title, setTitle] = useState("");
    let[btn, setBtn] = useState(null);

    function changeTitle(T , addBtn=null){
        setTitle(T);
        btn = addBtn;
        setBtn(btn);
    }

    return (
        <TitleContext.Provider value={{Title , btn , changeTitle}}>
            {props.children}            
        </TitleContext.Provider>
    )
}

export default TitleContext;