import React from 'react'
import { createSubSection } from '../../../Apis/subSections';
import Form from './Form';
import { useParams } from 'react-router';
import { useContext } from 'react';
import TitleContext from '../../../Contexts/TitleContext';
import { useEffect } from 'react';
import { getSection } from '../../../Apis/sections';

export default function CreateSubSections() {
  const { id } = useParams();
  const { changeTitle } = useContext(TitleContext);

  useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line

    changeTitle(`create subsection at`);
	}, [id]);

  useEffect(()=>{
    id && getSection(id).then((res)=>{
      console.log(res.data.data)
      changeTitle(`create subsection at ${res.data.data?.locales?.en?.name}`);
    })
  },[id])


  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createSubSection(data).then(res => {
      if (res.status === 200) {
        console.log(res);
        return 'done';          
      }
    }).catch(err =>{ 
      console.log(err.response.data);
      return err.response.data.errors; 
    })
}

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
