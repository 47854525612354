import React, { useEffect, useContext } from 'react'
import { useLocation, useParams } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import darkImage from '../../assets/images/darkImage.png';
import { getUserPortfolios } from '../../Apis/user';
import { useState } from 'react';
export const Works = () => {
    const { id } = useParams();
    const { pathname } = useLocation();
    const [userPortofolios, setUserPortofolios] = useState([]);
    const { changeTitle } = useContext(TitleContext);


    useEffect(() => {
        window.scrollTo(0, 0);
        changeTitle(`Works`);
        console.log('hello');
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        console.log(id);
        getUserPortfolios(id).then((res) => {
            const data = res.data.data
            console.log("data here is data:::", data);
            setUserPortofolios(data)
        })
    }, [])


    return (
        <div className="card d-flex  m-4 p-4  h-vh">
            <ul className='d-flex flex-row justify-content-evenly '>
                {userPortofolios && userPortofolios.map((item) => {
                    return (
                        <div className="card w-25 p-2 m-3">
                            <img src={item?.media && item?.media[0]?.url} className=' rounded p-1 ' alt="" />
                            <div className="card-body">
                                <h5 className="card-title text-center">{item.title}</h5>
                            </div>
                        </div>
                    )})
                }
            </ul>
        </div>
    )
}
