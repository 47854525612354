import { https } from './https';
import { api_url } from './../Configurations/config';

export async function getAdmins(){
    return await https.get(`${api_url}dashboard/admins`);
}
export async function getAdmin(id){
    return await https.get(`${api_url}dashboard/admins/${id}`);
}

export async function updateAdmin(id,data){
    return await https.put(`${api_url}dashboard/admins/${id}`, data);
}

export async function createAdmin(data){
    return await https.post(`${api_url}dashboard/admins`, data);
}

export async function deleteAdmin(id){
    return await https.delete(`${api_url}dashboard/admins/${id}`);
}