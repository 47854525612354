import { https } from './https';
import { api_url } from './../Configurations/config';

//get settings
export async function getGeneralSettings(){
    return await https.get(`${api_url}dashboard/settings`);
}
export async function updateGeneralSettings(data){
    return await https.put(`${api_url}dashboard/settings`, data);
}

export async function getFooterSettings() {
    return await https.get(`${api_url}dashboard/settings/footer`);
}
export async function updateFooterSettings(data){
    return await https.put(`${api_url}dashboard/settings/footer`, data);
}



