import React from 'react'
import { createGuideSection } from '../../../Apis/guideSections';
import Form from './Form';

export default function CreateGuideSection() {
  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createGuideSection(data).then(res => {
      if (res.status === 200) {
        console.log(res);
        return 'done';          
      }
    }).catch(err =>{ 
      console.log(err.response.data);
      return err.response.data.errors; 
    })
}

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
