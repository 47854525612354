import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setData } from './formHandler';
import { supportedLanguages } from './../../../Utilities/SupportedLanguages';
import { getGuideSection } from '../../../Apis/guideSections';
import TitleContext from '../../../Contexts/TitleContext';
import { preventEnter } from '../../../Utilities/preventEnter';

export default function Form(props) {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        changeTitle(``, '', { url: "guide-sections" });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(model);
        // eslint-disable-next-line
    }, [model]);

    useEffect(() => {
        if (id) {
            getGuideSection(id).then((res) => {
                let x = res.data.data;
                setModel({
                    locales: x.locales,
                    media: x.image?.id,
                });
            })
        } else {
            setModel({ locales: {}, media: '' });
        }
    }, [id]);

    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setData(model, setModel, e, property , locale , language);
        let err = {...errors};
        err[property] = '';
        setErrors(err)
    }
    // onsubmit
    const confirmSection = async (e, model) => {
        e.preventDefault();
        setLoading(true);
        console.log(model)
        let submitProps = await props.onSubmit(e, model);
        if (submitProps === "done") {
            setLoading(false);
            navigate(-1);
            if (!id) {
                setModel({});
            }
        } else {
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }

    return (
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{id ? `update Guide Section` : `add new Guide Section`}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirmSection(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div className="w-100">
                        {supportedLanguages.map((language) => {
                            return (
                                <div key={language.slug} className='mb-4' style={{ "width": "50%" }}>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor={`name_${language.slug}`} className='formLabel'>name ({language.name})</label>
                                        <input name="name" id={`name_${language?.slug}`} type='text' placeholder='name' value={(model && model.locales && model?.locales[language.slug]?.name) ?? "" } onChange={(e) => setFormData(e, 'name', true, language.slug)} minLength={3} maxLength={255} required />
                                    </div>
                                    <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.name`]}
                                    </div>
                                    <div className='d-flex flex-column mb-4'>
                                    <label htmlFor={`description_${language.slug}`} className='formLabel'>description ({language.name})</label>
                                    <textarea name="description" id={`description_${language.slug}`} type='text' placeholder='description' value={(model && model.locales && model?.locales[language.slug]?.description) ?? ""} onChange={(e) => setFormData(e, 'description', true, language.slug)} minLength={3} maxLength={500} required />
                                    <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.description`]}
                                    </div>
                                </div>
                                </div>)
                        })}
                    </div>
                </div>

                {!loading ?
                    <button className='confirmBtn mt-5' type='submit'>{id ? `save changes` : `add section`}</button>
                    :
                    <button className='confirmBtn mt-5' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }

            </form>
        </section>
    )
}
