import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getServices(obj){
    console.log(`${api_url}dashboard/services?${obj}`);
    return await https.get(`${api_url}dashboard/services?${obj}`);
}
export async function getService(id){
    return await https.get(`${api_url}dashboard/services/${id}`);
}
export async function acceptService(id){
    return await https.post(`${api_url}dashboard/services/${id}/accept`);
}
export async function rejectService(id,data){
    return await https.post(`${api_url}dashboard/services/${id}/reject`,data);
}
export async function deleteService(id){
    return await https.post(`${api_url}dashboard/services/${id}/archive`);
}
// get status
export async function getStatus(){
    return await https.get(`${api_url}dashboard/status`);
}