import React, { useContext, useState, useEffect } from 'react'
import { redirect, useLocation, useNavigate, useParams } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import 'photoswipe/dist/photoswipe.css';
import { t } from 'i18next';
import moment from 'moment';
import Select from 'react-select';
import { getCountries } from '../../Apis/country'
import { getSkills } from '../../Apis/skills'
import PhoneInput from 'react-phone-input-2';
import DatePicker from 'react-datepicker';
import 'react-phone-input-2/lib/style.css'
import { userCreate } from '../../Apis/user';

export const UserCreate = () => {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [user, setUser] = useState();
    const [errors, setErrors] = useState();
    const [countries, setCountries] = useState();
    const [skills, setSkills] = useState();
    const [dateOB, setDateOB] = useState();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(id)
        changeTitle(`Create User`);

        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        countriesData();
        skillsData()
    }, []);

    const countriesData = async () => {
        try {
            const countriesRes = await getCountries()
            const countriesData = countriesRes.data.data.map(country => ({
                value: country.id,
                label: country.locales.en.name,
                ...country,
            }));

            setCountries(countriesData);
        } catch (error) {
            // Handle the error
            console.error(error);
        }
    };

    const skillsData = async () => {
        const res = await getSkills()
        const skills = res.data.data.map(skill => ({
            value: skill.id,
            label: skill.name,
            ...skill,
        }));
        setSkills(skills);
    }

    const handleSelect = (selectedOptions, name) => {
        const userClone = { ...user };
        const err = { ...errors };

        if (name === 'skills') {
            const skillsArr = selectedOptions.map((x) => x.id);
            userClone[name] = skillsArr;
        } else {
            userClone[name] = selectedOptions;
        }
        setUser(userClone);
        err[name] = '';
        err.error = '';
        setErrors(err);
    }

    const OnchangePhone = (e) => {
        let userClone = { ...user };
        userClone.phone = e;
        setUser(userClone);
        let err = { ...errors };
        err.phone = ''
        setErrors(err);
    }

    const inputChangeHandler = (e) => {
        const { name, value, type, checked } = e.target;
        // setpasswordMatch('');
        const userClone = { ...user };
        const err = { ...errors };
        if (type === 'checkbox') {
            userClone[name] = checked;
        } else {
            userClone[name] = value.trim() !== '' ? value : undefined;
        }
        err[name] = '';
        err.error = '';
        err.verify = '';
        setUser(userClone);
        setErrors(err);
    }

    const formSubmit = async (e) => {
        e.preventDefault();
        // setLoading(true);

        let userClone = { ...user, password_confirmation: user?.password, terms_and_conditions: true };
        if (dateOB) {
            userClone = { ...userClone, "d_o_b": moment(dateOB).format('YYYY-MM-DD') };
        }

        console.log(userClone)
        const response = await userCreate(userClone).then(res => {
            navigate('/dashboard/users');
        }).catch(err => { setErrors(err.response.data.errors); })
        // setLoading(false);
    }

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value})
    }

    return (
        <section className='componentGlobalWrapper addBlog'>
            <form className='formWidth' onSubmit={formSubmit}>
                <div className='row'>
                    <div className='col-md-12 mb-4 d-flex flex-column'>
                        <label className='formLabel' >{t('username')}</label>
                        <input type='text' className='py-3' onChange={handleChange} name='username' value={user?.username}/>
                        <div className='text-danger errorText'>{errors?.username}</div>
                    </div>

                    <div className='col-md-6 mb-4 d-flex flex-column'>
                        <label className='formLabel'>{t('first-name')}</label>
                        <input type='text' className='py-3' onChange={handleChange} name='first_name' value={user?.first_name}/>
                        <div className='text-danger errorText'>{errors?.first_name}</div>
                    </div>

                    <div className='col-md-6 mb-4 d-flex flex-column'>
                        <label className='formLabel'>{t('last-name')}</label>
                        <input type='text' className='py-3' onChange={handleChange} name='last_name' value={user?.last_name}/>
                        <div className='text-danger errorText'>{errors?.last_name}</div>
                    </div>

                    <div className='col-md-12 mb-4 d-flex flex-column'>
                        <label className='formLabel'>{t('email')}</label>
                        <input type='email' className='py-3' onChange={handleChange} name='email' value={user?.email}/>
                        <div className='text-danger errorText'>{errors?.email}</div>
                    </div>

                    <div className='col-md-12 mb-4'>
                        <label className='formLabel'>{t('Country')}</label>
                        <Select
                            options={countries}
                            classNamePrefix='formSelect'
                            name='country'
                            placeholder={`${t('please enter')} ${t('country')}`}
                            onChange={(e) => handleSelect(e.id, 'country')}
                        />
                        <div className='text-danger errorText'>{errors?.country}</div>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <label className='formLabel' htmlFor='phone'> {t('phone-number')} </label>
                        <PhoneInput inputprops={{ name: 'phone' }} excludeCountries={['ir', 'cu', 'kp', 'ss', 'sd', 'ua', 'sy', 'il', 'ru', 'ye']} /* countryCodeEditable={false} country={'ae'} onlyCountries={['ae']} */ className=' p-0 w-100' name='phone' onChange={(x) => OnchangePhone(x)} />
                        <div className='text-danger errorText'>{errors?.phone}</div>
                    </div>

                    <div className='col-md-12 mb-4 d-flex flex-column'>
                        <label className='formLabel'>Birthday</label>
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={dateOB}
                            onChange={(date) => { setDateOB(date); setErrors({ ...errors, 'd_o_b': '' }) }}
                            placeholderText='DD/MM/YYYY'
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className=' w-100 py-3'
                            maxDate={new Date(moment().subtract(13, 'years'))}
                            minDate={new Date(moment().subtract(100, 'years'))}
                            onKeyDown={(e) => { e.preventDefault(); }}
                        />
                        <div className='text-danger errorText'>{errors?.d_o_b}</div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='formGroup'>
                            <label className='formLabel' htmlFor='gender'> {t('gender')} </label>
                            <div className='mt-2 d-flex'>
                                <div className='me-3'>
                                    <input type="radio" id="gender" name="gender" value={1} onChange={inputChangeHandler} className='me-2'/>
                                    <label htmlFor="gender">{t('male')}</label>
                                </div>
                                <div className='me-3'>
                                    <input type="radio" id="gender2" name="gender" value={2} onChange={inputChangeHandler} className='me-2'/>
                                    <label htmlFor="gender2">{t('female')}</label>
                                </div>
                            </div>
                            <div className='text-danger errorText'>{errors?.gender}</div>
                        </div>
                    </div>

                    <div className='col-md-12 mb-4'>
                        <label className='formLabel'>{t('skills')}</label>
                        <Select
                            options={skills}
                            classNamePrefix='formSelect'
                            isMulti
                            isSearchable={true}
                            placeholder={`${t('please enter')} ${t('skills')}`}
                            onChange={(e) => handleSelect(e, 'skills')}
                        />
                        <div className='text-danger errorText'>{errors?.skills}</div>
                    </div>

                    <div className='col-md-12 mb-4 d-flex flex-column'>
                        <label className='formLabel'>{t('password')}</label>
                        <input type='text' className='py-3' onChange={handleChange} name='password' value={user?.password}/>
                        <div className='text-danger errorText'>{errors?.password}</div>
                    </div>

                    <div className='col-md-12 mb-4 d-flex flex-column'>
                        <button type='submit' className='btn btn-success py-3'>{t('save')}</button>
                    </div>
                </div>
            </form>
        </section>
    )
}
