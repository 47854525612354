import React, { useEffect } from "react";
import { Route, useLocation } from "react-router";
import { Routes } from "react-router-dom";
import Overview from "./../Pages/Overview/overview";
import AdminsList from "./../Pages/Admins/AdminsList";
import AddAdmin from "../Pages/Admins/AdminsForm/Add";
import EditAdmin from "./../Pages/Admins/AdminsForm/Edit";
import Roles from "./../Pages/Roles/Roles";
import Skills from "../Pages/Skills/Skills";
import CreateSkills from "../Pages/Skills/Form/Create";
import UpdateSkills from "../Pages/Skills/Form/Update";
import Sections from "../Pages/Sections/Sections";
import CreateSections from "../Pages/Sections/Form/Create";
import UpdateSections from "../Pages/Sections/Form/Update";
import SubSections from "../Pages/SubSections/SubSections";
import CreateSubSections from "../Pages/SubSections/Form/Create";
import UpdateSubSections from "../Pages/SubSections/Form/Update";
import Budgets from "../Pages/Budgets/Budgets";
import EditSecuritySettings from "../Pages/Security/Edit";
import EditAccountSettings from "../Pages/Account/Edit";
import WebsitePages from "../Pages/Website pages/Allpages/WebsitePages";
import HomeSections from "../Pages/Website pages/HomeSections/HomeSections";
import AddSlider from "../Pages/Website pages/HomeSections/SliderForm/Add";
import EditSlider from "../Pages/Website pages/HomeSections/SliderForm/Edit";
import Services from "../Pages/Services/services";
import Projects from "../Pages/Projects/projects";
import Orders from "../Pages/Orders/orders";
import TermsAndConditions from "../Pages/Website pages/Terms and conditions/TermsAndConditions";
import PrivacyPolicy from "../Pages/Website pages/Privacy policy/PrivacyPolicy";
import GeneralSettings from "../Pages/Settings/generalSettings";
import Social from "../Pages/Settings/social";
import Aboutus from "../Pages/Website pages/Aboutus/Aboutus";
import Faqsections from "../Pages/FaqSections/FaqSections";
import CreateFaqSection from "../Pages/FaqSections/Form/Create";
import UpdateFaqSection from "../Pages/FaqSections/Form/Update";
import MainFaq from "../Pages/mainFaq/MainFaq";
import CreateMainFaqs from "../Pages/mainFaq/Form/Create";
import UpdateMainFaqs from "../Pages/mainFaq/Form/Update";
import ProjectDetails from "../Pages/Projects/Details";
import ServiceDetails from "../Pages/Services/Details";
import OrderDetails from "../Pages/Orders/Details";
import OrderDiscussions from "../Pages/Orders/OrderDiscussions";
/* import Competitions from '../Pages/Competitions/Competitions';
import CompetitionsDetails from '../Pages/Competitions/Details'; */
import GuideSections from "../Pages/GuideSections/GuideSections";
import GuideArticles from "../Pages/GuideArticles/GuideArticles";
import CreateGuideSection from "../Pages/GuideSections/Form/Create";
import UpdateGuideSections from "../Pages/GuideSections/Form/Update";
import CreateGuideArticle from "../Pages/GuideArticles/Form/Create";
import UpdateGuideArticle from "../Pages/GuideArticles/Form/Update";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Levels from "./../Pages/Levels/Levels";
import UpdateLevels from "./../Pages/Levels/Form/Update";
import Coupons from "../Pages/Coupons/Coupons";
import CreateCouponss from "../Pages/Coupons/Form/Create";
import UpdateCoupons from "../Pages/Coupons/Form/Update";
import Fees from "../Pages/Fees/Fees";
import UpdateFees from "../Pages/Fees/Form/Update";
import SpamOptions from "./../Pages/Spams/SpamOptions/SpamOptions";
import CreateSpamOptions from "./../Pages/Spams/SpamOptions/Form/Create";
import UpdateSpamOptions from "./../Pages/Spams/SpamOptions/Form/Update";
import PermissionsContext from "../Contexts/permissionsContext";
import { useContext } from "react";
import UserList from "../Pages/Users/users";
import SpamReports from "./../Pages/Spams/SpamReports/SpamReports";
import { UserDetails } from "../Pages/Users/Details";
import { UserCreate } from "../Pages/Users/Create";
import { Works } from "../Pages/Users/Works";
import Transactions from "../Pages/transactions/Transactions";
import Withdraws from "../Pages/withdraws/Withdraws";
import Guide from "../Pages/Guide/guide";
import UserRatings from "../Pages/Users/UserRatings";
import DeleteRequests from "../Pages/DeleteRequests/DeleteRequests";
import OrderCancelRequests from "../Pages/Orders/orderCancelRequests";
import WithdrawDetails from "../Pages/withdraws/Details";
export default function AllRoutes() {
	let { Permissions } = useContext(PermissionsContext);
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, [pathname]);
	return (
		<>
			{/* { Permissions && */}
			<Routes>
				<Route path="overview" element={<Overview />} />
				{/* {/* admins and roles   */}
				{(Permissions?.admin || Permissions?.role) && (
					<Route path="admins/">
						{Permissions?.admin?.view && (
							<Route path="admins-list" element={<AdminsList />} />
						)}
						{Permissions?.admin?.create && (
							<Route path="create" element={<AddAdmin />} />
						)}
						{Permissions?.admin?.edit && (
							<Route path=":id/update" element={<EditAdmin />} />
						)}
						{/* {Permissions?.role?.view && */}
						<Route path="roles/*" element={<Roles />} />
						{/* } */}
					</Route>
				)}
				{Permissions?.user && (
					<Route path="users/">
						{Permissions?.user?.view && (
							<>
								<Route path="" element={<UserList />} />
								<Route path=":id" element={<UserDetails />} />
								<Route path=":id/ratings" element={<UserRatings />} />
								<Route path="works/:id" element={<Works />} />
								<Route path="create" element={<UserCreate />} />
							</>
						)}
						{/* {Permissions?.user?.create &&
                            <Route path="create" element={<Adduser />} />
                        }
                        {Permissions?.user?.edit &&
                            <Route path=":id/update" element={<Edituser />} />
                        } */}
					</Route>
				)}
				{Permissions?.user && (

					<Route path="delete-requests">
						<Route path="" element={<DeleteRequests />} />
					</Route>
				)}
				<Route path="contactus-msgs">
					<Route path="" element={<ContactUs />} />
				</Route>
				{/* transaction */}

				{
					<Route path="transactions">
						{Permissions?.transaction?.view && (
							<Route path="" element={<Transactions />} />
						)}
					</Route>
				}

				{/* withdraw */}
				{
					<Route path="withdraws">
						{/* {Permissions?.withdraw?.view && ( */}
							<Route path="" element={<Withdraws />} />
							<Route path=":id" element={<WithdrawDetails />} />
						{/* )} */}
					</Route>
				}

				{/* ****skills****  */}
				{
					<Route path="skills/">
						{Permissions?.skill?.view && <Route path="" element={<Skills />} />}
						{Permissions?.skill?.create && (
							<Route path="create" element={<CreateSkills />} />
						)}
						{Permissions?.skill?.edit && (
							<Route path=":id/update" element={<UpdateSkills />} />
						)}
					</Route>
				}

				{Permissions?.level?.view && (
					<Route path="levels/">
						<Route path="" element={<Levels />} />
						{Permissions?.level?.edit && (
							<Route path=":id/update" element={<UpdateLevels />} />
						)}
					</Route>
				)}

				<Route path="coupons/">
					{Permissions?.coupon?.view && <Route path="" element={<Coupons />} />}
					{Permissions?.coupon?.create && (
						<Route path="create" element={<CreateCouponss />} />
					)}
					{Permissions?.coupon?.edit && (
						<Route path=":id/update" element={<UpdateCoupons />} />
					)}
				</Route>
				{/* sections */}
				<Route path="sections">
					<Route path="" element={<Sections />} />
					<Route path="create" element={<CreateSections />} />
					<Route path=":id/update" element={<UpdateSections />} />
					<Route path=":id/subsections">
						<Route path="" element={<SubSections />} />
						<Route path="create" element={<CreateSubSections />} />
						<Route path=":sub_id/update" element={<UpdateSubSections />} />
					</Route>
				</Route>
				{/* guide */}
				<Route path="guide">
					<Route path="guide-page" element={<Guide />} />
					{Permissions["guide-section"]?.view && (
						<Route path="guide-sections">
							<Route path="" element={<GuideSections />} />
							{Permissions["guide-section"]?.create && (
								<Route path="create" element={<CreateGuideSection />} />
							)}
							{Permissions["guide-section"]?.edit && (
								<Route path=":id/update" element={<UpdateGuideSections />} />
							)}
							<Route path=":id/Articles">
								{Permissions?.article?.view && (
									<Route path="" element={<GuideArticles />} />
								)}
								{Permissions?.article?.create && (
									<Route path="create" element={<CreateGuideArticle />} />
								)}
								{Permissions?.article?.edit && (
									<Route
										path=":article_id/update"
										element={<UpdateGuideArticle />}
									/>
								)}
							</Route>
						</Route>
					)}
				</Route>
				{/* services */}
				{Permissions?.service?.view && (
					<Route path="services">
						<Route path="" element={<Services />} />
						<Route path=":id" element={<ServiceDetails />} />
					</Route>
				)}
				{/* Projects */}
				{Permissions?.project?.view && (
					<Route path="projects">
						<Route path="" element={<Projects />} />
						<Route path=":id" element={<ProjectDetails />} />
					</Route>
				)}
				{/* Orders */}
				{Permissions?.order?.view && (
					<Route path="orders">
						<Route path="" element={<Orders />} />
						<Route path=":id" element={<OrderDetails />} />
					</Route>
				)}
				{Permissions?.['order-cancel-request']?.view && (
                    <Route path="order-cancel-requests">
						<Route path="" element={<OrderCancelRequests />} />
					</Route>
				)}
				{/* {Permissions?.['discussions']?.view && ( */}
                    <Route path="orders/:id/discussions">
						<Route path="" element={<OrderDiscussions />} />
					</Route>
				{/* )} */}
				{/* Competition */}
				{/* <Route path='competitions'>
                    <Route path='' element={<Competitions />} />
                    <Route path=':id' element={<CompetitionsDetails />} />
                </Route> */}

				{/* ****budgets****  */}

				{Permissions?.budget?.view && (
					<Route path="budgets/">
						<Route path="" element={<Budgets />} />
					</Route>
				)}

				{
					<Route path="fees/">
						<Route path="" element={<Fees />} />
						<Route path=":id/update" element={<UpdateFees />} />
					</Route>
				}

				{
					<Route path="spams/">
						<Route path="spam-options">
							<Route path="" element={<SpamOptions />} />
							<Route path="create" element={<CreateSpamOptions />} />
							<Route path=":id/update" element={<UpdateSpamOptions />} />
						</Route>
						<Route path="spam-reports" element={<SpamReports />} />
					</Route>
				}

				{
					<Route path="spams/">
						<Route path="spam-options">
							<Route path="" element={<SpamOptions />} />
							<Route path="create" element={<CreateSpamOptions />} />
							<Route path=":id/update" element={<UpdateSpamOptions />} />
						</Route>
						<Route path="spam-reports" element={<SpamReports />} />
					</Route>
				}

				{/* settings */}
				{Permissions?.setting?.view && (
					<Route path="settings/">
						<Route path="general" element={<GeneralSettings />} />
						<Route path="footer" element={<Social />} />
						<Route path="account" element={<EditAccountSettings />} />
						<Route path="security" element={<EditSecuritySettings />} />
					</Route>
				)}

				{/**** appearance ****/}
				{(Permissions?.appearance?.view ||
					Permissions?.faq?.view ||
					(Permissions["faq-section"] && Permissions["faq-section"]?.view)) && (
						<Route path="appearance/">
							<Route path="pages">
								{Permissions?.appearance?.view && (
									<>
										<Route path="" element={<WebsitePages />} />
										<Route
											path="terms-and-conditions"
											element={<TermsAndConditions />}
										/>
										<Route path="privacy-policy" element={<PrivacyPolicy />} />
										<Route path="about-us" element={<Aboutus />} />
										<Route path="home">
											<Route path="" element={<HomeSections />} />
											<Route path="add-slider" element={<AddSlider />} />
											<Route path="edit-slider/:id" element={<EditSlider />} />
										</Route>
									</>
								)}
								{Permissions["faq-section"] &&
									Permissions["faq-section"]?.view && (
										<Route path="faq-sections">
											<Route path="" element={<Faqsections />} />
											{Permissions["faq-section"]?.create && (
												<Route path="create" element={<CreateFaqSection />} />
											)}
											{Permissions["faq-section"]?.edit && (
												<Route path=":id/update" element={<UpdateFaqSection />} />
											)}
											{Permissions?.faq?.view && (
												<Route path=":id/faqs">
													<Route path="" element={<MainFaq />} />
													{Permissions?.faq?.create && (
														<Route path="create" element={<CreateMainFaqs />} />
													)}
													{Permissions?.faq?.edit && (
														<Route
															path=":faq_id/update"
															element={<UpdateMainFaqs />}
														/>
													)}
												</Route>
											)}
										</Route>
									)}
								{Permissions?.faq?.view && (
									<Route path="faqs">
										<Route path="" element={<MainFaq />} />
										{Permissions?.faq?.create && (
											<Route path="create" element={<CreateMainFaqs />} />
										)}
										{Permissions?.faq?.edit && (
											<Route path=":faq_id/update" element={<UpdateMainFaqs />} />
										)}
									</Route>
								)}
							</Route>
						</Route>
					)}
			</Routes>
			{/* // } */}
		</>
	);
}
