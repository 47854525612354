import React, { useState, useContext, useEffect, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import search from "../../assets/icons/search.svg";
import delete_icon from "../../assets/icons/delete.svg";
import '../tables.scss';
import '../forms.scss';
import { Link, useLocation } from 'react-router-dom';
import Pagination from '../../Components/Pagination/Pagination';
import { deleteUser, getDeleteReqs, toggleRead } from '../../Apis/deletereq';
import DeleteModal from '../../Utilities/deleteModal';
import moment from 'moment';
import TitleContext from '../../Contexts/TitleContext';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useReactToPrint } from 'react-to-print';
/* import { BsEnvelope, BsEnvelopeOpen } from "react-icons/bs"; */
// import './Contact.scss'
import PermissionsContext from '../../Contexts/permissionsContext';
import MessageModal from '../../Utilities/messageModal';
import read from "../../assets/icons/read.svg";
import unread from "../../assets/icons/unread.svg";
import { basename } from '../../Configurations/config';
import details from "../../assets/icons/details.svg";

export default function DeleteRequests() {
  const [allDeleteReqs, setallDeleteReqs] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const { pathname } = useLocation();
  const { changeTitle } = useContext(TitleContext);
  const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', date_from: '', date_to: '' });
  const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: '', date_from: '', date_to: '' });
  const [deleted, setDeleted] = useState("");
  const [deletedName, setDeletedName] = useState('msg');
  const [description, setDescription] = useState('')
  const { Permissions } = useContext(PermissionsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    changeTitle(`contact msgs`)
    // eslint-disable-next-line
  }, [pathname]);

  function getAllDeleteRequests(obj) {
    let filter = { ...obj }
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&date_from=${filter.date_from}&date_to=${filter.date_to}`;
    getDeleteReqs(params).then((res) => {
      setallDeleteReqs(res.data.data);
      console.log(res);
      let total = res?.data?.pagination?.total;
      changeTitle(`contact msgs (${total})`);
      setPageCount(Math.ceil(total / filter?.per_page));
    }).catch((err) => {
      console.log(err.response)
    })
  }

  useEffect(() => {
    getAllDeleteRequests(filterObj);

    // eslint-disable-next-line
  }, []);

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 }
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj }
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;
    thisObj.date_from = filterObj2.date_from;
    thisObj.date_to = filterObj2.date_to;
    setfilterObj(thisObj);
    getAllDeleteRequests(thisObj)
  }

  async function onSubmitDelete(pass) {
    return await deleteUser(deleted,{_method:'delete', password:pass}).then(res => {
      getAllDeleteRequests(filterObj);
      return "done";
    }).catch(err => {
      console.log(err.response)
      return err.response.data.message;
    })
  }

  const handlepageChange = async data => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    getAllDeleteRequests(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    thisObj2.date_from = filter.date_from;
    thisObj2.date_to = filter.date_to;
    setfilterObj2(thisObj2);
  }

  function clickDelete(item) {
    setDeleted(item.user_id)
    let msg = ` ${item?.full_name?.substring(0, 20)}`
    setDeletedName(msg)
  }

  /* ******************** export pdf **************************** */
  const componentRef = useRef();
  const { afterToday } = DateRangePicker;
  let cloneRef = useRef(null);
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      cloneRef = componentRef.current.cloneNode(true);
      cloneRef.firstChild.firstChild.lastChild.classList.add('d-none');
      for (let i = 0; i < cloneRef.lastChild.childNodes.length; i++) {
        cloneRef.lastChild.childNodes[i].lastChild.classList.add('d-none');
      }
    },
    content: () => cloneRef,
  });
  /* *********************************************************** */

  // function markContactMsgRead(id, status) {
  //   if (status === 'unread') {
  //     toggleRead(id).then(() => {
  //       getAllDeleteRequests(filterObj)
  //     })
  //   }
  // }

  function getDateValue(data) {
    let thisObj = { ...filterObj2 }
    if (data !== null) {
      thisObj.date_from = moment(data[0]).format('YYYY-MM-DD');
      thisObj.date_to = moment(data[1]).format('YYYY-MM-DD');
    } else {
      thisObj.date_from = '';
      thisObj.date_to = '';
    }
    setfilterObj2(thisObj);
  }


  return (
    <>
      {<section className='componentGlobalWrapper d-flex flex-column'>
        <div>
          <div className='searchActionsBar w-100 justify-content-between flex-wrap'>
            <div className='searchActionsBar mb-0'>
              <div className='mt-0 mb-0'>
                {/*** search input ***/}
                <div className='searchInputWrapper'>
                  <div>
                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                  </div>
                  <img src={search} alt='search-icon' className='barSearchIcon' />
                </div>
              </div>

              <div className='dataRange'>
                <DateRangePicker disabledDate={afterToday()} showOneCalendar={true} appearance="default" placeholder="from : to" onChange={(value) => getDateValue(value)} />
              </div>

              {/* show select */}
              <div className='perPageContainer'>
                <label htmlFor='selectShow' className='perPageLabel'>
                  show
                </label>
                <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' value={filterObj2?.per_page}>
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value='15'>15</option>
                  <option value='20'>20</option>
                </select>
              </div>

              {/*** apply search btn  ***/}
              <button className='applySearchBtn' onClick={getFiltered}>search</button>
            </div>
            {/*** export as pdf ***/}
            <div className="dropdown">
              <button className="dropdown-toggle exportDropdown confirmBtn" type="button" id="exportButton" data-bs-toggle="dropdown" aria-expanded="false">
                export
              </button>
              <ul className="dropdown-menu custom-bulk-dropdown" aria-labelledby="exportButton">
                <li className='' onClick={handlePrint}>
                  <span className=' px-2 py-1'>pdf</span>
                </li>
              </ul>
            </div>
          </div>
          {Permissions['contact-us']?.view &&
            <div className='tableWrapper'>
              <table ref={componentRef} className="w-100 table table-striped" >
                <thead>
                  <tr>
                    <th>request details</th>
                    <th>projects</th>
                    <th>services</th>
                    <th>orders</th>
                    <th>pruchases</th>
                    <th className='dateCol'>date</th>
                    <th>actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allDeleteReqs && allDeleteReqs.map((deletereq, index) => (
                    <React.Fragment key={index}>
                      <tr className={`stripedRow  ${deletereq?.full_name  }`}>
                        <td> {deletereq?.full_name} </td>
                        <td> {deletereq?.projects_count}</td>
                        <td> {deletereq?.services_count}</td>
                        <td> {deletereq?.orders_count}</td>
                        <td> {deletereq?.purchases_count}</td>
                        <td className='dateCol'><div className='d-flex'>{moment(deletereq.created_at).format('DD-MM-YY h:mm A')}</div></td>
                        <td className='hideAtPrint'>
                          <div className='d-flex'>
                            {/* <Tooltip title='read the msg content'>
                              <div className='contactActions' data-bs-toggle="modal" data-bs-target={`#readMsgModel`} onClick={() => { markContactMsgRead(deletereq.id, deletereq.read); setDescription(deletereq.message) }} >
                                {deletereq?.read === 'read' ?
                                  <img className='iconActions' src={read} alt="edit" />
                                  :
                                  <img className='iconActions' src={unread} alt="edit" />
                                }
                              </div>
                            </Tooltip> */}

                           { deletereq?.status === 0 &&
                            <>
                            <Tooltip title='delete user'>
                                <div className='contactActions'>
                                  <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => clickDelete(deletereq)} />
                                </div>
                              </Tooltip>
                            

                            <Tooltip title="details">
                              <Link to={`${basename}/dashboard/users/${deletereq?.user_id}`}>
                                <img
                                  className="iconActions"
                                  src={details}
                                  alt="details"
                                />
                              </Link>
                            </Tooltip>
                            </>
                            }
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
        <div className='d-flex justify-content-end mt-auto'>
          <Pagination pagesNumber={pageCount} page={filterObj.page - 1} pageChangeHandler={handlepageChange} />
        </div>
        <DeleteModal onSubmitDelete={onSubmitDelete} name={deletedName} deleteAccount={true}></DeleteModal>
        <MessageModal description={description} />

      </section>}
    </>
  )
}
