import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import search from "../../assets/icons/search.svg";
import details from "../../assets/icons/details.svg";
// import noImg from '../../assets/images/noImg.jpg';
import "../tables.scss";
import "../forms.scss";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import {
  getOrders,
} from "../../Apis/orders";
import moment from "moment";
import { basename } from "../../Configurations/config";
import TitleContext from "../../Contexts/TitleContext";
/* import PermissionsContext from '../../Contexts/permissionsContext'; */
import PermissionsContext from "../../Contexts/permissionsContext";
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import Loader from '../../Components/Loader/Loader'
import { t } from "i18next";
import { getSections } from "../../Apis/sections";
import { getSubSections } from "../../Apis/subSections";
import { DatePicker } from "rsuite";
import { Height } from "@mui/icons-material";

export default function Orders() {
  const [allOrders, setallOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const { pathname } = useLocation();
  const location = useLocation();
  const { changeTitle } = useContext(TitleContext);
  let [searchParams] = useSearchParams();
  // const { stage } = useParams();
  const [stage, setStage] = useState('2,3');
  const [date, setDate] = useState('');

  const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: "", stage: stage, section_id: '', subsection_id: '', price_from: '', price_to:''});
  const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "", section_id: '', subsection_id: '', price_from: '', price_to:'' });
  const { Permissions } = useContext(PermissionsContext);
  const [ordersCount, setOrdersCount] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
    changeTitle(`Orders`);
  }, [pathname]);
  
  function getallOrders(obj) {
    setLoading(true)
    let filter = { ...obj };
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&stage=${filter.stage}&section_id=${filter.section_id}&subsection_id=${filter.subsection_id}&date=${date}&price_from=${filter.price_from}&price_to=${filter.price_to}`;
    getOrders(params).then((res) => {
      setallOrders(res.data.data);
        console.log(res?.data)
      let total = res?.data?.overview?.total;
      changeTitle(`Orders (${total})`);
      setPageCount(Math.ceil(res?.data?.data?.length / filter.per_page));
      setOrdersCount(res?.data?.overview);
      setLoading(false)
    });
  }

  function getallSections() {
    getSections('').then((res) => {
      console.log(res.data.data);
      setSections(res.data.data);
    });
  }

  useEffect(() => {
    getallOrders({...filterObj, stage});
    // eslint-disable-next-line
  }, [stage]);

  useEffect(() => {
    getallSections();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSubSections(`section=${filterObj2.section_id}`).then((res) => {
      setSubsections(res.data.data);
    });
    // eslint-disable-next-line
  }, [filterObj2.section_id]);

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 };
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj };
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;
    thisObj.stage = stage;
    thisObj.date = date ;
    thisObj.section_id = filterObj2.section_id;
    thisObj.subsection_id = filterObj2.subsection_id;
    thisObj.price_from = filterObj2.price_from;
    thisObj.price_to = filterObj2.price_to;
    console.log(thisObj)
    setfilterObj(thisObj);
    getallOrders(thisObj);
  }

  function changeStage(s) {
    if(s === 2) {
        navigate(`${basename}/dashboard/orders?stage=${'2,3'}`);
        setStage('2,3');
    } else {
        navigate(`${basename}/dashboard/orders?stage=${s}`);
        setStage(s);
    }
  }

  const handlepageChange = async (data) => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    getallOrders(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    setfilterObj2(thisObj2);
  };

  return (
    <>
      {
        <section className="componentGlobalWrapper d-flex flex-column">
          <div>
            <div
              className="tabs-container nav nav-tabs d-flex align-items-center"
              id="myTab"
              role="tablist"
            >
              <div
                onClick={() => changeStage(2)}
                className={`tablinks ${stage === 2 && `active`}`}
                id="Gallery-tab"
                data-bs-toggle="tab"
                data-bs-target="#allnews"
              >
                active{" "}
                <span className="tabcounter">({(ordersCount?.["stage_progress_count"] ?? 0) + (ordersCount?.['stage_submission_count'] ?? 0)})</span>
              </div>
              <span className="separator"></span>

              <div
                onClick={() => changeStage(1)}
                className={`tablinks ${stage === 1 && `active`}`}
                id="published-tab"
                data-bs-toggle="tab"
                data-bs-target="#published-Img"
              >
                Pending{" "}
                <span className="tabcounter">
                  {" "}
                  ({ordersCount?.["status_pending_count"]}){" "}
                </span>
              </div>
              <span className="separator"></span>

              <div
                onClick={() => changeStage(4)}
                className={`tablinks ${stage === 4 && `active`}`}
                id="published-tab"
                data-bs-toggle="tab"
                data-bs-target="#published-Img"
              >
                Compelete{" "}
                <span className="tabcounter">
                  {" "}
                  ({ordersCount?.["stage_complete_count"]}){" "}
                </span>
              </div>
              <span className="separator"></span>

              <div
                onClick={() => changeStage(5)}
                className={`tablinks ${stage === 5 && `active`}`}
                id="published-tab"
                data-bs-toggle="tab"
                data-bs-target="#published-Img"
              >
                Closed{" "}
                <span className="tabcounter">
                  {" "}
                  ({ordersCount?.["stage_close_count"]}){" "}
                </span>
              </div>
              <span className="separator"></span>
            </div>
            <div className="searchActionsBar w-100">
              <div className="searchActionsBar mt-0 mb-0">
                {/*** section input ***/}
                <div className="searchInputWrapper">
                  <div>
                    <select
                      className="barSearchInput"
                      name="section_id"
                      onChange={changeFilterObj}
                    >
                      <option selected disabled>{t('section')}</option>
                      {sections?.map((section, key) => {
                        return(
                          <option key={key} value={section.id}>{section?.locales?.en?.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                {/*** subsection input ***/}
                <div className="searchInputWrapper">
                  <div>
                    <select
                      className="barSearchInput"
                      name="subsection_id"
                      onChange={changeFilterObj}
                    >
                      <option selected disabled>{t('subsection')}</option>
                      {subsections?.map((subsection, key) => {
                        return(
                          <option key={key} value={subsection.id}>{subsection?.locales?.en?.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
              
              <div className="searchActionsBar mt-0 mb-0">
                {/*** section input ***/}
                <div className="searchInputWrapper">
                  <div>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      selected={moment()}
                      onChange={(date) => { setDate(moment(date).format('YYYY-MM-DD')) }}
                      placeholderText='YYYY-MM-DD'
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      className=''
                      style={{height: '35px'}}
                      maxDate={new Date(moment())}
                      minDate={new Date(moment().subtract(100, 'years'))}
                      // onKeyDown={(e) => { e.preventDefault(); }}
                    />
                  </div>
                </div>
              </div>
              
              {/*** search input ***/}
              <div className="searchActionsBar mt-0 mb-0">
                {/*** search input ***/}
                <div className="searchInputWrapper">
                  <div>
                    <input
                      type="text"
                      className="barSearchInput"
                      name="search"
                      onChange={changeFilterObj}
                    />
                  </div>
                  <img
                    src={search}
                    alt="search-icon"
                    className="barSearchIcon"
                  />
                </div>
              </div>

              {/* price */}
              <div className="searchActionsBar mt-0 mb-0">
                <div className="searchInputWrapper">
                  <div>
                    <input
                      type="number"
                      min="0"
                      placeholder="Price from"
                      className="barSearchInput"
                      name="price_from"
                      onChange={changeFilterObj}
                    />
                  </div>
                </div>
              </div>
              <div className="searchActionsBar mt-0 mb-0">
                <div className="searchInputWrapper">
                  <div>
                    <input
                      type="number"
                      min="0"
                      placeholder="Price to"
                      className="barSearchInput"
                      name="price_to"
                      onChange={changeFilterObj}
                    />
                  </div>
                </div>
              </div>

              {/* show select */}
              <div className="perPageContainer">
                <label htmlFor="selectShow" className="perPageLabel">
                  show
                </label>
                <select
                  name="per_page"
                  onChange={changeFilterObj}
                  id={`selectShow`}
                  className="form-select selectShow"
                  value={filterObj2?.per_page}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
              {/*** apply search btn  ***/}
              <button className="applySearchBtn" onClick={getFiltered}>
                search
              </button>
            </div>
            {Permissions?.order?.view && (
              <div className="tableWrapper">
                <table className="w-100 table table-striped serviceTable">
                  <thead>
                    <tr>
                      {/* <th>image</th> */}
                      <th>user</th>
                      <th>created at</th>
                      <th>actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    loading? <td className='w-100' colSpan={6}><Loader/></td>
                    :allOrders && allOrders?.length !== 0 ?
                      allOrders.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td className='sliderImg'>
                                                    <img src={`${item?.media !== null ? item?.media[0]?.url : noImg}`} alt="slider" />
                                                </td> */}
                            <td> {item?.user?.username ?? "no data added"}</td>
                            <td>
                              {item?.created_at
                                ? moment(item?.created_at).format("DD-MM-YYYY")
                                : "no data added"}
                            </td>
                            <td>
                              <div className="d-flex">
                                <Tooltip title="details">
                                  <Link
                                    to={`${basename}/dashboard/orders/${item.id}`}
                                  >
                                    <img
                                      className="iconActions"
                                      src={details}
                                      alt="details"
                                    />
                                  </Link>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        );
                      }):<td className='w-100' colSpan={6}><NotDataFound /></td>}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end mt-auto">
            <Pagination
              pagesNumber={pageCount}
              page={filterObj.page - 1}
              pageChangeHandler={handlepageChange}
            />
          </div>
        </section>
      }
    </>
  );
}
