import React, { useContext, useEffect,  useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setCityData } from './formHandler';
import { supportedLanguages } from './../../../Utilities/SupportedLanguages';
import { getFaqSection } from '../../../Apis/faq';
import TitleContext from '../../../Contexts/TitleContext';

export default function Form(props) {
    const { pathname } = useLocation();
    const {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);   
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(()=>{
        changeTitle('','');
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        if (id) {
            getFaqSection(id).then((res) => {
                let x = res.data.data;
                setModel({
                    locales: x.locales,
                });
            })
        }else{
            setModel({locales: {}})
        }
    }, [id]);

    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setCityData(model, setModel, e, property , locale , language);
        let err = {...errors};
        if (locale) {
            err[`locales.${language}.${property}`] = '';
            err[`locales`]=''
        } else {
            err[property] = '';
        }
        setErrors(err)
    }

    // onsubmit
    const confirm = async (e , model)=>{
        e.preventDefault();
        setLoading(true);
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
            navigate(-1);
            if(!id){
                setModel({});
            }                
        }else{
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }

    function preventEnter(e) {
        if(e.keyCode===13){
            e.preventDefault();
        }
    }


  return (
    <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{id ? `update Faq Section` : `add new Faq Section`}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirm(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div  className="w-50">
                        {supportedLanguages.map((language)=>{
                        return(
                            <div key={language.slug} className='mb-4'>
                                <div className='d-flex flex-column'>
                                    <label htmlFor={`name_${language.slug}`} className='formLabel'>name ({language.name})</label>
                                    <input name="name" id={`name_${language.slug}`} type='text' placeholder='name' value={model?.locales ? model?.locales[language.slug]?.name : " " } onChange={(e) => setFormData(e, 'name' , true , language.slug)} minLength={3} maxLength={500} required />
                                </div>
                                <div className='text-danger validateToDelete'>   
                                    {errors[`locales.${language.slug}.name`]}
                                </div>
                            </div>)})}
                    </div>
                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>{id ? `save changes` : `add Faq Section`}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
    </section>
  )
}
