import { https } from './https';
import { api_url } from '../Configurations/config';

//get loggedin admin profile info
export async function getAllSpamOptions(){
    return await https.get(`${api_url}dashboard/report-options`);
}

export async function getSpamOption(id){
    return await https.get(`${api_url}dashboard/report-options/${id}`);
}

export async function createSpamOption(data){
    return await https.post(`${api_url}dashboard/report-options`,data);
}

export async function updateSpamOption(id,data){
    return await https.patch(`${api_url}dashboard/report-options/${id}`,data);
}

export async function deleteSpamOption(id){
    return await https.delete(`${api_url}dashboard/report-options/${id}`);
}

/// reports
export async function getAllSpamReports(obj){
    return await https.get(`${api_url}dashboard/reports?${obj}`);
}