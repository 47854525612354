import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getWithdraws(params) {
    return await https.get(`${api_url}dashboard/withdraws?${params}`);
}

export async function getWithdraw(id){
    return await https.get(`${api_url}dashboard/withdraws/${id}`);
}

export async function acceptWithdraw(id){
    return await https.post(`${api_url}dashboard/withdraws/${id}/accept`);
}

export async function rejectWithdraw(id,obj){
    return await https.post(`${api_url}dashboard/withdraws/${id}/reject`,obj);
}