import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router';
import { getOrder, getOrderDiscussions } from '../../Apis/orders';
import TitleContext from '../../Contexts/TitleContext';
import pdfImg from '../../assets/images/pdfImg.jpg';
import { Gallery, Item } from 'react-photoswipe-gallery';
import vector from '../../assets/icons/Vector.svg';
import 'photoswipe/dist/photoswipe.css';
import { t } from 'i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Stack, Typography } from '@mui/material';

export default function OrderDiscussions() {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState();
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(id)
        getOrderDiscussions(id).then((res) => {
            console.log(res.data.data);
            setModel(res.data.data);
        })

        changeTitle(`order discussions`);

        // eslint-disable-next-line
    }, [pathname]);

    return (
        <section className='componentGlobalWrapper addBlog'>
            {model &&
                <div className=''>

                    {model.map(discussion => (
                        <div className='mb-4'>
                            <Stack spacing={2} direction="column" alignItems="start" sx={{mb: 4}}>
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <Avatar alt="user" src={discussion.user?.media?.original_url} />
                                    <Typography>
                                        {discussion.user?.first_name} {discussion.user?.last_name}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    {
                                        discussion.message && 
                                        <Typography className='d-block'>
                                            {discussion.message}
                                        </Typography>
                                    }
                                    {
                                        discussion.order_cancel_request &&
                                        <Typography className='d-block'>
                                                <span className='d-block bg-danger badge'>{t('cancel order request')}</span>
                                        </Typography>
                                    }
                                    <Typography>
                                        {moment(discussion.created_at).format('Y-M-D h:m a')}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </div>    
                    ))}
                </div>
            }
        </section>
    )
}
