import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setData } from './formHandler';
import { getFee } from '../../../Apis/fees';
import TitleContext from '../../../Contexts/TitleContext';
import { preventEnter } from '../../../Utilities/preventEnter';
import { basename } from './../../../Configurations/config';
import { supportedLanguages } from '../../../Utilities/SupportedLanguages';
import ConfirmModal from '../../../Components/cofirmModal/ConfirmModal';

export default function Form(props) {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        changeTitle(``, '', { url: "Fees" });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) {
            getFee(id).then((res) => {
                let x = res.data.data;
                console.log(x)
                setModel({ ...x });
            })
        } else {
            setModel({
                is_percentage: "",
                value: "",
                locales: {}
            });
        }
    }, [id]);

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setData(model, setModel, e, property, locale, language);
        let err = { ...errors };
        err[property] = '';
        setErrors(err)
    }

    // onsubmit
    const confirmFee = async (e) => {
    //    console.log("Ihave Been Invoked")
        e.preventDefault();
        setLoading(true);

        // console.log("MEDEL", model)
        let data = {is_percentage :model.is_percentage, locales: model.locales, value: Number(model.value)}
        // console.log("data", data)
        let submitProps = await props.onSubmit(data);
        if (submitProps === "done") {
            setLoading(false);
            navigate(`${basename}/dashboard/fees`);
            if (!id) {
                setModel({});
            }
        } else {
            // console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }

    return (
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{id ? `update Fee` : `add new Fee`}</h5>
            </div>

            <div id='adminForm'>
                <div className='d-flex justify-content-start'>
                    <div className="w-100">
                        <div style={{ "width": "50%" }}>
                            {supportedLanguages.map((language) => {
                                return (
                                    <div key={language.slug}>
                                        <div className='d-flex flex-column mb-4'>
                                            <label htmlFor={`name_${language.slug}`} className='formLabel'>name ({language.name})</label>
                                            <input name="name" id={`name_${language.slug}`} type='text' placeholder='name' value={model?.locales ? model?.locales[language.slug]?.name : " "} onChange={(e) => setFormData(e, 'name', true, language.slug)} minLength={3} maxLength={255} required />
                                            <div className='text-danger validateToDelete'>
                                                {errors[`locales.${language.slug}.name`]}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className='d-flex flex-column mb-4 w-100'>
                                <label htmlFor={`value`} className='formLabel'>value</label>
                                <input name="value " id={`value`} type='number' placeholder='value' value={model?.value} onChange={(e) => setFormData(e, 'value')} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} min={0} required />
                                <div className='text-danger validateToDelete'>
                                    {errors[`value`]}
                                </div>
                            </div>
                            <div className='d-flex flex-column mb-4 w-100'>
                                <label htmlFor={`is_percentage`} className='formLabel'>type of value</label>
                                <div className='selectParent'>
                                    <select name="is_percentage " id={`is_percentage`} placeholder='select type of value' value={model?.is_percentage} onChange={(e) => setFormData(e, 'is_percentage')} required >
                                        <option value={''} disabled>select type of valu</option>
                                        <option value={1}>percentage</option>
                                        <option value={0}>AED</option>
                                    </select>
                                </div>
                                <div className='text-danger validateToDelete'>
                                    {errors[`is_percentage`]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!loading ?
                    <button className='confirmBtn mt-5' onClick={() => setOpen(true)} >{id ? `save changes` : `add Fee`}</button>
                    :
                    <button className='confirmBtn mt-5' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </div>
            <ConfirmModal open={open} setOpen={setOpen} action={"Edit"} submitFunction={confirmFee} />
        </section>
    )
}
