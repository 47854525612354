import React, { useContext, useEffect, useRef, useState } from "react";
import { editPage, getPage } from "../../../Apis/pages";
import TitleContext from "../../../Contexts/TitleContext";
import { t } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import deleteIcon from "../../../assets/icons/removeIcon.svg";
import uploadImage from "../../../assets/icons/uploadimage.svg";
import { api_url } from "../../../Configurations/config";
import axios from "axios";
import PermissionsContext from "../../../Contexts/permissionsContext";
/**Editor**/
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

export default function Aboutus() {
  let { changeTitle } = useContext(TitleContext);
  const { Permissions } = useContext(PermissionsContext);
  const [aboutUs, setaboutUs] = useState({ locales: {}, media: [] });
  let [Locales, setLocales] = useState({});
  let [progressMain, setprogressMain] = useState(0);
  let [mainImage, setmainImage] = useState("");
  let [progressSecond, setprogressSecond] = useState(0);
  let [SecondImage, setSecondImage] = useState("");
  let [loader, setLoader] = useState(false);
  let mainImgRef = useRef();
  let secondImgRef = useRef();

  // get about us page headers and content ids
  useEffect(() => {
    changeTitle(`about us`);
    getPage("about-us")
      .then((res) => {
        console.log(res.data.data);
        let data = res.data.data?.locales;
        setLocales(data);
        const contentDataStateAr = ContentState.createFromBlockArray(htmlToDraft(`${data?.ar?.description}`));
        const editorDataStateAr = EditorState.createWithContent(contentDataStateAr);
        const contentDataStateEn = ContentState.createFromBlockArray(htmlToDraft(`${data?.en?.description}`));
        const editorDataStateEn = EditorState.createWithContent(contentDataStateEn);
        setEditorStateAr(editorDataStateAr);
        setEditorStateEn(editorDataStateEn);

        if (res.data.data?.media?.length === 2) {
          setmainImage(res.data.data.media[0]);
          setSecondImage(res.data.data.media[1]);
        }
        let x = res.data.data;
        let medias = [];
        x?.media?.map((item) => {
          medias.push(item.id);
          return true;
        });
        x["old_media"] = medias;
        x.media = [];
        setaboutUs(x);
      })
      .catch((err) => {
        console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);

  // onsubmit page content
  const submitHandler = async (e, data) => {
    let about = { locales: "", old_media: "", media: " " }
    about.locales = Locales;
    about.old_media = aboutUs.old_media;
    about.media = aboutUs.media;


    console.log("This Is ABOUT Us ", about)
    if (!mainImage) {
      window.scrollTo(
        mainImgRef.current.getBoundingClientRect().left + window.scrollX,
        mainImgRef.current.getBoundingClientRect().top + window.scrollY - 150
      );
      mainImgRef.current.nextSibling.classList.remove("d-none");
    }
    if (!SecondImage) {
      window.scrollTo(
        secondImgRef.current.getBoundingClientRect().left + window.scrollX,
        secondImgRef.current.getBoundingClientRect().top + window.scrollY - 150
      );
      secondImgRef.current.nextSibling.classList.remove("d-none");
    }

    if (mainImage && SecondImage) {
      setLoader(true);
      console.log("SentData::::", about)
      return await editPage("about-us", about)
        .then((res) => {
          console.log(res.data);
          setLoader(false);
          return "done";
        })
        .catch((err) => {
          setLoader(false);
          console.log(err.response);
          return err.response.data.errors;
        });
    }
  };



  const ISSERVER = typeof window === "undefined";
  let x;

  if (!ISSERVER) {
    x = localStorage.getItem("need4_token");
  }
  const token = `Bearer ` + x;

  function changeImages(e, type) {
    const formData = new FormData();
    formData.append("media[]", e.target.files[0]);
    const config = {
      onUploadProgress: (data) => {
        if (type === "main") {
          setprogressMain(Math.round((100 * data.loaded) / data.total));
        } else if (type === "second") {
          setprogressSecond(Math.round((100 * data.loaded) / data.total));
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
        AccessControlAllowOrigin: "*",
        lang: "en",
        country: "uae",
      },
    };
    let about = { ...aboutUs };
    axios.post(`${api_url}media`, formData, config).then((res) => {
      let obj = res.data.data;
      if (type === "main") {
        setmainImage(obj[0]);
        about.media.unshift(obj[0]?.id);
        setprogressMain(0);
      } else if (type === "second") {
        setSecondImage(obj[0]);
        about.media.push(obj[0]?.id);
        setprogressSecond(0);
      }
    });
    setaboutUs(about);
  }

  function removeImage(id, type) {
    let about = Object.assign({}, aboutUs);
    if (about.media.includes(id)) {
      let news = about.media.filter((x) => {
        return x !== id;
      });
      about.media = news;
    }
    if (about.old_media.includes(id)) {
      console.log("old");
      let old = about.old_media.filter((x) => {
        return x !== id;
      });
      about.old_media = old;
    }
    setaboutUs(about);

    if (type === "main") {
      setmainImage("");
    } else if (type === "second") {
      setSecondImage("");
    }
  }

  /*********************************** text editor *****************************/
  /* Text Editors */
  let arabicbodyRef = useRef();
  let englishbodyRef = useRef();
  let [editorStateAr, setEditorStateAr] = useState(() => EditorState.createEmpty());
  let [editorStateEn, setEditorStateEn] = useState(() => EditorState.createEmpty());
  const convertArContentToHTML = () => {
    if (!editorStateAr) return ""; // Add a null check for editorStateAr
    const currentContentAsHTML = draftToHtml(convertToRaw(editorStateAr.getCurrentContent()));
    setLocales({
      ...Locales,
      ar: { description: currentContentAsHTML }
    });
  };
  const convertEnContentToHTML = () => {
    if (!editorStateEn) return ""; // Add a null check for editorStateAr
    const currentContentAsHTML = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()));

    setLocales({
      ...Locales,
      en: { description: currentContentAsHTML }
    });
  };

  const handleArEditorChange = (state) => {
    setEditorStateAr(state);
    convertArContentToHTML();
  }
  const handleEnEditorChange = (state) => {
    setEditorStateEn(state);
    convertEnContentToHTML();
  }



  return (
    <>
      <section className="componentGlobalWrapper aboutus">
        <div className="mb-3">
          <h5 className="pageSubtitle">page content</h5>
          <div className="pagesForm" >
            <div className='w-100  mt-3'>
              <label htmlFor="content" className="formLabel">{`${t(
                "content-in"
              )} (${t(`en`)})`}</label>
              <div ref={englishbodyRef} className="w-100 textEditorWrapper ">
                <Editor
                  className='editor'
                  toolbarClassName="d-none"
                  editorState={editorStateEn}
                  onEditorStateChange={handleEnEditorChange}
                  textAlignment="left"
                />
              </div>
            </div>
            <div className='w-100  mt-3'>
              <label htmlFor="content" className="formLabel">{`${t(
                "content-in"
              )} (${t(`ar`)})`}</label>
              <div ref={arabicbodyRef} className="w-100 textEditorWrapper">
                <Editor
                  className='editor border border-0'
                  editorState={editorStateAr}
                  onEditorStateChange={handleArEditorChange}
                  textAlignment="right"
                  toolbarClassName="d-none"
                />
              </div>
            </div>

            <div className="d-flex mt-4">
              {/** Main image **/}
              <div className="d-flex flex-column mb-5">
                <label htmlFor="logoimage" className="formLabel">
                  Main image
                </label>
                {!mainImage ? (
                  <div>
                    <label
                      ref={mainImgRef}
                      htmlFor="mainimage"
                      className="position-relative"
                    >
                      <div className="globalImageWrapper">
                        <input
                          onChange={(e) => changeImages(e, "main")}
                          type="file"
                          id="mainimage"
                          name="logo"
                          className="d-none"
                        />
                        <img
                          className="cloudIcon"
                          src={uploadImage}
                          alt="upload"
                        />
                        <span>Upload Image</span>
                      </div>
                    </label>
                    {progressMain !== 0 && (
                      <div
                        className="progress mt-2 p-0"
                        style={{ width: "11.25rem" }}
                      >
                        <div
                          className="progress-bar progress-bar-info progress-bar-striped"
                          role="progressbar"
                          aria-valuenow={progressMain}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: progressMain + "%" }}
                        >
                          {progressMain}%
                        </div>
                      </div>
                    )}
                    <div className="validateToDelete text-danger d-none">
                      This field is required!
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="globalImageWrapper position-relative">
                      <Tooltip title="Remove Image">
                        <label
                          htmlFor="viewImage"
                          className={`globalRemoveImage`}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete"
                            onClick={() => removeImage(mainImage.id, "main")}
                          />
                        </label>
                      </Tooltip>
                      <div
                        className="globalPreviewedImage"
                        style={{
                          backgroundImage: `url(${mainImage.url})`,
                          backgroundSize: "cover",
                          width: "90%",
                          height: "90%",
                        }}
                      ></div>
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex flex-column mb-5">
                <label htmlFor="logoimage" className="formLabel">
                  Second Image
                </label>
                {!SecondImage ? (
                  <div>
                    <label
                      ref={secondImgRef}
                      htmlFor="SecondImage"
                      className="position-relative"
                    >
                      <div className="globalImageWrapper">
                        <input
                          onChange={(e) => changeImages(e, "second")}
                          type="file"
                          id="SecondImage"
                          name="logo"
                          className="d-none"
                        />
                        <img
                          className="cloudIcon"
                          src={uploadImage}
                          alt="upload"
                        />
                        <span>Upload Image</span>
                      </div>
                    </label>
                    {progressSecond !== 0 && (
                      <div
                        className="progress mt-2 p-0"
                        style={{ width: "11.25rem" }}
                      >
                        <div
                          className="progress-bar progress-bar-info progress-bar-striped"
                          role="progressbar"
                          aria-valuenow={progressSecond}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: progressSecond + "%" }}
                        >
                          {progressSecond}%
                        </div>
                      </div>
                    )}
                    <div className="validateToDelete text-danger d-none">
                      This field is required!
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="globalImageWrapper position-relative">
                      <Tooltip title="Remove Image">
                        <label
                          htmlFor="viewImage"
                          className={`globalRemoveImage`}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete"
                            onClick={() =>
                              removeImage(SecondImage.id, "second")
                            }
                          />
                        </label>
                      </Tooltip>
                      <div
                        className="globalPreviewedImage"
                        style={{
                          backgroundImage: `url(${SecondImage.url})`,
                          backgroundSize: "cover",
                          width: "90%",
                          height: "90%",
                        }}
                      ></div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {Permissions?.appearance?.edit && (
              <>
                {!loader ? (
                  <div>
                    <button className="confirmBtn" onClick={() => submitHandler()}>
                      {t("save-changes")}
                    </button>
                  </div>
                ) : (
                  <button className="confirmBtn" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {t("saving")}...
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
