import { https } from './https';
import { api_url } from './../Configurations/config';

export async function getRoles(){
    return await https.get(`${api_url}dashboard/roles`);
}

export async function getAllPermissions(){
    return await https.get(`${api_url}dashboard/permissions`);
}
export async function getPermissionsList(){
    return await https.get(`${api_url}dashboard/permissions`);
}


export async function getRole(id){
    return await https.get(`${api_url}dashboard/roles/${id}`);
}

export async function deleteRole(id){
    return await https.delete(`${api_url}dashboard/roles/${id}`);
}

export async function updateRole(id, data){
    return await https.put(`${api_url}dashboard/roles/${id}`, data);
}

export async function createRole(data){
    return await https.post(`${api_url}dashboard/roles`, data);
}