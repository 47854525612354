import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getOrders(obj){
    return await https.get(`${api_url}dashboard/orders?${obj}`);
}

export async function getOrder(id){
    return await https.get(`${api_url}dashboard/orders/${id}`);
}

export async function getOrderCancelRequests(obj){
    return await https.get(`${api_url}dashboard/order-cancel-requests?${obj}`);
}

export async function acceptOrderCancelRequest(id){
    return await https.post(`${api_url}dashboard/order-cancel-requests/${id}/accept`);
}

export async function rejectOrderCancelRequest(id){
    return await https.post(`${api_url}dashboard/order-cancel-requests/${id}/reject`);
}

export async function getOrderDiscussions(id){
    return await https.get(`${api_url}dashboard/orders/${id}/discussions`);
}