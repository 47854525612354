import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import pause from "../../assets/icons/pause.svg";
import play from "../../assets/icons/play.svg";
import "../tables.scss";
import "../forms.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import {
	deleteMainFaq,
	getFaqSection,
	getMainFaqs,
	toggleStatusMainFaq,
} from "../../Apis/faq";
import DeleteModal from "../../Utilities/deleteModal";
import moment from "moment";
import { basename } from "./../../Configurations/config";
import TitleContext from "../../Contexts/TitleContext";
import { getSections } from "../../Apis/sections";
import PermissionsContext from "../../Contexts/permissionsContext";

export default function MainFaq() {
	const { id } = useParams();
	const [allMainFaqs, setAllMainFaqs] = useState([]);
	const [sections, setSections] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		search: "",
		section: id ?? "",
		status: "active",
	});
	const [filterObj2, setfilterObj2] = useState({
		per_page: 10,
		search: "",
		section: id ?? "",
	});
	const [deletedMainFaq, setDeletedMainFaq] = useState("");
	const [deletedName, setDeletedName] = useState("faq");
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line
	}, [pathname]);

	function getAllMainFaqs(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&section=${filter.section}&status=${filter.status}`;
		console.log(params);
		getMainFaqs(params, id ? "faqs" : "section-faqs").then((res) => {
			setAllMainFaqs(res.data.data);
			console.log(res.data.data);
			let total = res.data?.pagination?.total;
			if (id) {
				getFaqSection(id).then((res) => {
					console.log(res.data.data);
					changeTitle(
						`Main FAQs at ${res.data.data?.locales?.en?.name} (${total})`,
						{
							url: `${basename}/dashboard/appearance/pages/faq-sections/${id}/faqs/create`,
							text: "add Faq",
							canAdd: Permissions?.faq?.create,
						}
					);
				});
			} else {
				changeTitle(`${"FAQs At Sections"} (${total})`, {
					url: `${basename}/dashboard/appearance/pages/faqs/create`,
					text: "add Faq",
					canAdd: Permissions?.faq?.create,
				});
			}
			setPageCount(Math.ceil(total / filter.per_page));
		});
	}

	useEffect(() => {
		getAllMainFaqs(filterObj);
		if (!id) {
			getSections("status=active").then((res) => {
				setSections(res.data.data);
			});
		}
		// eslint-disable-next-line
	}, []);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		thisObj.section = filterObj2.section;
		setfilterObj(thisObj);
		getAllMainFaqs(thisObj);
	}

	async function onSubmitDelete() {
		console.log("deletedMainFaq", deletedMainFaq);
		let url = "faqs" 
		console.log("URL", url);
		return await deleteMainFaq(
			deletedMainFaq,
			url
		).then((res) => {
				getAllMainFaqs(filterObj);
				return "done";
			})
			.catch((err) => {
				console.log(err.response);
				return err.response.data.message;
			});
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getAllMainFaqs(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		thisObj2.section = filter.section;
		setfilterObj2(thisObj2);
	};

	function changeStatus(status) {
		let thisObj = { ...filterObj };
		thisObj.status = status;
		thisObj.page = 1;
		setfilterObj(thisObj);
		getAllMainFaqs(thisObj);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = thisObj.per_page;
		thisObj2.search = thisObj.search;
		thisObj2.section = thisObj.section;
		setfilterObj2(thisObj2);
	}

	function toggleStatue(status) {
		toggleStatusMainFaq(status, "faqs")
			.then((res) => {
				console.log(res.data);
				getAllMainFaqs(filterObj);
			})
			.catch((err) => {
				console.log(err.response);
			});
	}

	function clickDelete(item) {
		setDeletedMainFaq(item.id);
		setDeletedName(item?.locales?.en?.question.substring(0, 25));
	}

	return (
		<>
			<section className="componentGlobalWrapper d-flex flex-column">
				<div>
					<div
						className="tabs-container nav nav-tabs d-flex align-items-center"
						id="myTab"
						role="tablist"
					>
						<div
							onClick={() => changeStatus("active")}
							className="tablinks active"
							id="Gallery-tab"
							data-bs-toggle="tab"
							data-bs-target="#allnews"
						>
							active
						</div>
						<span className="separator"></span>
						<div
							onClick={() => changeStatus("inactive")}
							className="tablinks"
							id="published-tab"
							data-bs-toggle="tab"
							data-bs-target="#published-Img"
						>
							inactive
						</div>
					</div>
					<div className="searchActionsBar w-100">
						<div className="searchActionsBar mt-0 mb-0">
							{/*** search input ***/}
							<div className="searchInputWrapper">
								<div>
									<input
										type="text"
										className="barSearchInput"
										name="search"
										onChange={changeFilterObj}
									/>
								</div>
								<img src={search} alt="search-icon" className="barSearchIcon" />
							</div>
						</div>
						{!id && (
							<div className="cityFilter">
								<select
									defaultValue=""
									name="section"
									onChange={changeFilterObj}
									className="form-select citySelect"
								>
									<option value="">all sections</option>
									{sections &&
										sections.map((item, i) => {
											return (
												<option key={i} value={item.id}>
													{item?.locales?.en?.name}
												</option>
											);
										})}
								</select>
							</div>
						)}
						{/* show select */}
						<div className="perPageContainer">
							<label htmlFor="selectShow" className="perPageLabel">
								show
							</label>
							<select
								name="per_page"
								onChange={changeFilterObj}
								id={`selectShow`}
								className="form-select selectShow"
								value={filterObj2?.per_page}
							>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
							</select>
						</div>
						{/*** apply search btn  ***/}
						<button className="applySearchBtn" onClick={getFiltered}>
							search
						</button>
					</div>
					{
						<div className="tableWrapper">
							<table className="w-100 table table-striped ">
								<thead>
									<tr>
										<th>question</th>
										<th>answer</th>
										<th>section</th>
										<th>created at</th>
										<th>actions</th>
									</tr>
								</thead>
								<tbody>
									{allMainFaqs &&
										allMainFaqs.map((item, index) => {
											return (
												<tr key={index}>
													<td className="desc">
														<div className="descriptionTd">
															{item?.locales?.en?.question
																? item?.locales?.en?.question
																: "no data added"}
														</div>
													</td>
													<td className="desc">
														<div className="descriptionTd">
															{item?.locales?.en?.answer
																? item?.locales?.en?.answer
																: "no data added"}
														</div>
													</td>
													<td className="desc">
														<div className="descriptionTd">
															{item?.section ?? "no data added"}
														</div>
													</td>
													<td>
														{item?.created_at
															? moment(item?.created_at).format("DD-MM-YYYY")
															: "no data added"}
													</td>
													<td>
														<div>
															{Permissions?.faq?.edit && (
																<Tooltip title="edit">
																	<Link
																		to={
																			id
																				? `${basename}/dashboard/appearance/pages/faq-sections/${id}/faqs/${item.id}/update`
																				: `${basename}/dashboard/appearance/pages/faqs/${item.id}/update`
																		}
																	>
																		<img
																			className="iconActions"
																			src={edit_icon}
																			alt="edit"
																		/>
																	</Link>
																</Tooltip>
															)}
															{Permissions?.faq?.activate ? (
																item?.status === "active" ? (
																	<Tooltip title="deactivate">
																		<img
																			onClick={() => toggleStatue(item.id)}
																			className="iconActions"
																			src={pause}
																			alt="edit"
																		/>
																	</Tooltip>
																) : (
																	<Tooltip title="activate">
																		<img
																			onClick={() => toggleStatue(item.id)}
																			className="iconActions"
																			src={play}
																			alt="edit"
																		/>
																	</Tooltip>
																)
															) : (
																""
															)}
															{Permissions?.faq?.delete && (
																<Tooltip title="delete this item">
																	<img
																		data-bs-toggle="modal"
																		onClick={() => clickDelete(item)}
																		data-bs-target="#deleteModal"
																		className="iconActions"
																		src={delete_icon}
																		alt="edit"
																	/>
																</Tooltip>
															)}
														</div>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					}
				</div>
				<div className="d-flex justify-content-end mt-auto">
					<Pagination
						pagesNumber={pageCount}
						page={filterObj.page - 1}
						pageChangeHandler={handlepageChange}
					/>
				</div>
				<DeleteModal
					onSubmitDelete={onSubmitDelete}
					name={deletedName}
				></DeleteModal>
			</section>
		</>
	);
}
