import React from 'react'
import { createAdmin } from '../../../Apis/admins';
import Form from './Form'

export default function AddAdmin() {
    const onSubmit = async (data) => {
        //to send request
        return await createAdmin(data).then(res => {
            return 'done';          
        }).catch(err =>{
            return err.response.data.errors;
        })
    };

    return (
        <Form onSubmit={onSubmit}></Form>
    )
}
