import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { getAllNotifications, readMyNotifications } from "../Apis/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faAddressCard, faFlag, faBriefcase, faStore } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Echo from "laravel-echo";
import { getAdminProfileInfo } from "../Apis/profile";
// 1- create Notification Context
let NotificationContext = createContext("");

export function NotificationContextProvider(props) {
  const [numOfNotification, setNumOfNotification] = useState(0);
  const [allNotifications, setAllNotifications] = useState([]);
  const [adminData, setAdminData] = useState();

  /***********Getting All Notifications from API************/
  function getMyAllNotifications() {
    getAllNotifications()
      .then((res) => {
        setNumOfNotification(res.data.overview);
        // let n = [...allNotifications, ...res?.data?.data];
        console.log(res?.data?.data)
        setAllNotifications(res?.data?.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  function getAdminInfo() {
    getAdminProfileInfo().then((res) => {
      setAdminData(res.data.data);
    }).catch((err) => {
      console.log(err.response);
    });
  }


  useEffect(
    () => {
      getAdminInfo()
      getMyAllNotifications();
    }, //eslint-disable-next-line
    []
  );

  function returnIcon(item) {
    if (item?.type === "reports") return <FontAwesomeIcon icon={faFlag} />
    else if (item?.type === "services") return <FontAwesomeIcon icon={faStore} />
    else if (item?.type === "projects") return <FontAwesomeIcon icon={faBriefcase} />
    else if (item?.type === "contactus") return <FontAwesomeIcon icon={faEnvelopeOpen} />
    if (item?.action === "identity") return <FontAwesomeIcon icon={faAddressCard} />
  }

  function returnMsg(item) {
    if (item.type === "services") {
      if (item.action === "create") return <><span className="text-decoration m-0 p-0"> <b>Pending Service: </b> </span><span> {item?.item?.name} </span></>
      else if (item.action === "update") return <><span className="text-decoration m-0 p-0"><b> Need-Action Service:</b>  </span><span> {item?.item?.name} </span></>
      else if (item.action === "archive") return <><span className="text-decoration m-0 p-0"><b>Archived Service:</b> </span><span> {item?.item?.name} </span></>
      else if (item.action === "activate") return <><span className="text-decoration m-0 p-0"><b>Activate Service:</b> </span><span> {item?.item?.name} </span></>
    } else if (item.type === "projects") {
      if (item.action === "create") return <><span className="text-decoration m-0 p-0 "><b>Pending Project:</b> </span><span> {item?.item?.name} </span></>
      else if (item.action === "update") return <><span className="text-decoration m-0 p-0"> <b>Need-Action Project:</b> </span><span> {item?.item?.name} </span></>
      else if (item.action === "close") return <><span className="text-decoration m-0 p-0"><b>Closed Project:</b> </span><span> {item?.item?.name} </span></>
    } else if (item?.type === "reports") {
      if (item.action === "create") return <><span className="text-decoration m-0 p-0"><b>Report by:</b></span><span> {item?.username} </span></>
    } else if (item?.type === "user") {
      if (item.action === "identity") return <><span className="text-decoration m-0 p-0"><b>User Verification:</b></span><span> {item?.username} </span></>
    } else if (item?.type === "contactus") return <><span className="text-decoration m-0 p-0"><b>New Mail from:</b></span><span> {item?.message?.email} </span></>
  }

  function returnLiHtmlElement(item, link) {
    return (
      <Link className="text-decoration-none" to={`/dashboard/${link}`}>
        <li className={"align-items-center d-flex h-80 " + (item.state === 0 && "unreadNotification")}>
          <span className="dropdownItem d-flex justify-content-between pe-2">
            <span className="icon">{returnIcon(item)}</span>
          </span>
          <span className="dropdownItem w-100">
            <div className="d-flex flex-column">
              <span className="user">
                <p className="msgContent m-0">{returnMsg(item)}</p>
              </span>
              <span className="issue">{item?.message?.message?.message}</span>
            </div>
            <span className="hour">{moment(item.created_at).format("DD MMM")}</span>
          </span>
        </li>
      </Link>
    );
  }

  function returnLink(item) {
    let link = "";
    if (item?.type === "projects") {
      if (item.action === "create") link = `projects?status=2`;
      else if (item.action === "update") link = `projects?status=4`;
      else if (item.action === "close") link = `projects?status=closed`;
    }
    else if (item?.type === "services") {
      if (item.action === "create") link = `services?status=2`;
      else if (item.action === "update") link = `services?status=4`;
      else if (item.action === "archive") link = `services?status=5`;
      else if (item.action === "activate") link = `services?status=1`;
    }
    else if (item?.type === "reports") link = `spams/spam-reports`;
    else if (item?.type === "contactus") link = `contactus-msgs`;
    else if (item?.type === "user" && item.action === "identity") link = `users/${item.user}`
    return returnLiHtmlElement(item, link);
  }

  function handleReadNotification(id) {
    readMyNotifications(id).then(() => {
      console.log("read", id);
    });
  }
  //realTime Notifications
  console.log("adminData username", adminData?.username);
  const adminUsername = adminData?.username;

  if (typeof window !== "undefined") {
    window.io = require('socket.io-client');
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: "http://api.atmemly.com:6001",
    });
    window.Echo.channel(`atmemly.admin-notification.${adminUsername}`).listen(".notification", (e) => {
      console.log("HIOIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII")
      // console.log(e)
      setAllNotifications([e, ...allNotifications]);
      setNumOfNotification(numOfNotification + 1)
    })
  }

  return (
    <NotificationContext.Provider value={{ numOfNotification, allNotifications, setAllNotifications, setNumOfNotification, handleReadNotification, returnLink, }}>
      {props.children}
    </NotificationContext.Provider>
  );
} export default NotificationContext;
