import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import formModel from "./formModel";
import { setData } from "./formHandler";
import { supportedLanguages } from "./../../../Utilities/SupportedLanguages";
import { getSection } from "../../../Apis/sections";
import TitleContext from "../../../Contexts/TitleContext";
import { api_url, basename } from "../../../Configurations/config";
import { preventEnter } from "../../../Utilities/preventEnter";
import Tooltip from "@mui/material/Tooltip";
import removeIcon from "../../../assets/icons/removeIcon.svg";
import uploadImage from "../../../assets/icons/uploadimage.svg";
import axios from "axios";

export default function Form(props) {
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [model, setModel] = useState(formModel);
	const [stateImg, setStateImg] = useState("");
	const [stateIcon, setStateIcon] = useState("");
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});
	const [show, setShow] = useState({ media: false, icon: false, iconColor: false, save: false })
	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, [pathname]);

	useEffect(() => {
		changeTitle(``, "");
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (id) {
			getSection(id).then((res) => {
				let x = res.data.data;
				console.log("HERE IS THE RES DATA", x);
				setStateImg(x?.image?.url);
				setStateIcon(x?.icon?.url);
				setModel(x);
				setShow({ media: true, icon: true, iconColor: true, save: true })
				console.log(x)
			});
		} else {
			setModel({ locales: {}, media: "" });
		}
	}, [id]);
	useEffect(() => {
		console.log("HERE IS THE MODAL", model);
		// eslint-disable-next-line
	}, [model]);
	// onchange
	const setFormData = (e, property, locale = false, language = null) => {
		setData(model, setModel, e, property, locale, language);
		let err = { ...errors };
		if (locale) {
			err[`locales.${language}.${property}`] = "";
			err[`locales`] = "";
		} else {
			err[property] = "";
		}
		setErrors(err);
	};

	/* upload image */
	/* **************************** IMAGES ************************** */
	const [progress, setProgress] = useState(0);
	const [iconProgress, setIconProgress] = useState(0);
	const token = `Bearer ` + localStorage.getItem("need4_Admin_Token");

	function upload(e, type) {
		const formData = new FormData();
		formData.append("media[]", e.target.files[0]);
		const config = {
			onUploadProgress: (data) => {
				if (type === "icon") {
					setIconProgress(Math.round((100 * data.loaded) / data.total));
				} else {
					setProgress(Math.round((100 * data.loaded) / data.total));
				}
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: token,
				AccessControlAllowOrigin: "*",
				lang: "en",
				country: "uae",
			},
		};

		let err = { ...errors };
		err[`${type}`] = "";
		setErrors(err);

		axios
			.post(`${api_url}media`, formData, config)
			.then((res) => {
				console.log("upload res", res.data.data);
				if (type === "icon") {
					let obj = res.data.data;
					setStateIcon(obj[0]?.url);
					let m = Object.assign({}, model);
					//should be media insted image to make it work
					m["icon"] = obj[0]?.id;
					setModel(m);
					setIconProgress(0);
					setShow({ ...show, iconColor: true })
				} else {
					let obj = res.data.data;
					setStateImg(obj[0]?.url);
					let m = Object.assign({}, model);
					//should be media insted icon to make it work
					m["media"] = obj[0]?.id;
					setModel(m);
					setProgress(0);
					setShow({ ...show, icon: true })
				}
			})
			.catch((err) => {
				console.log(err.response.data);
				let x = err.response.data.errors.media;
				let e = { ...errors };
				e["media"] = x;
				setErrors(e);
				setProgress(0);
			});
	}

	function removeImgHandler(x) {
		let m = Object.assign({}, model);
		if (x === "img") {
			m["media"] = "";
			setStateImg("");
		} else {
			m["icon"] = "";
			setStateIcon("");
		}
		setModel(m);
	}

	// onsubmit
	const confirmSection = async (e, model) => {
		e.preventDefault();
		setLoading(true);
		console.log(model);
		let submitProps = await props.onSubmit(model);
		if (submitProps === "done") {
			setLoading(false);
			navigate(`${basename}/dashboard/sections`);
			if (!id) {
				setModel({});
			}
		} else {
			setLoading(false);
			setErrors(submitProps);
			console.log(submitProps)
		}
	};

	return (
		<section className="componentGlobalWrapper addBlog">
			<div className="mb-3">
				<h5 className="pageSubtitle">
					{id ? `update Section` : `add new Section`}
				</h5>
			</div>

			<form
				id="adminForm"
				onSubmit={(e) => confirmSection(e, model)}
				onKeyDown={preventEnter}
			>
				<div className="d-flex justify-content-start">
					<div className="w-100">
						{supportedLanguages.map((language) => {
							return (
								<div
									key={language.slug}
									className="mb-4"
									style={{ width: "50%" }}
								>
									<div className="d-flex flex-column">
										<label
											htmlFor={`name_${language.slug}`}
											className="formLabel"
										>
											name ({language.name})
										</label>
										<input
											name="name"
											id={`name_${language.slug}`}
											type="text"
											placeholder="name"
											value={
												model?.locales
													? model?.locales[language.slug]?.name
													: " "
											}
											onBlur={() => (model?.locales?.en?.name && model?.locales?.ar?.name) && setShow({ ...show, media: true })}
											onChange={(e) =>
												setFormData(e, "name", true, language.slug)
											}
											minLength={3}
											maxLength={255}
											required
										/>
									</div>
									<div className="text-danger validateToDelete">
										{errors[`locales.${language.slug}`]}
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{/**  image upload **/}
				<div className={`row m-0 mb-2  align-items-center mt-2`}>
					<label className="formLabel p-0 mb-0">image </label>
					{stateImg ? (
						<div className="globalImageWrapper position-relative p-1 mt-2">
							<Tooltip title="Remove Image">
								<label htmlFor="viewImage" className={`globalRemoveImage`}>
									<img
										src={removeIcon}
										alt="delete"
										onClick={() => removeImgHandler("img")}
									/>
								</label>
							</Tooltip>
							<div
								className="globalPreviewedImage"
								style={{ backgroundImage: `url(${stateImg})` }}
							></div>
						</div>
					) : (
						<div className="px-0">
							<div
								className="imageWrapper p-0 mt-2"
								style={{ width: "180px", height: "140px" }}
							>
								<label
									htmlFor="propertyImg"
									className="h-100 w-100 d-flex flex-column justify-content-center align-items-center"
								>
									<img
										width="70px"
										height="50px"
										src={uploadImage}
										alt="upload"
									/>
									<input
										accept=".jpg, .jpeg, .png ,.webp"
										onChange={(e) => upload(e, "media")}
										name="image"
										type="file"
										value={""}
										disabled={progress}
										className={`d-none`}
										id="propertyImg"
									/>
									<span> upload image </span>
								</label>
							</div>
						</div>
					)}
				</div>
				{progress !== 0 && (
					<div className="progress mt-2" style={{ width: "11.25rem" }}>
						<div
							className="progress-bar progress-bar-info progress-bar-striped"
							role="progressbar"
							aria-valuenow={progress}
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: progress + "%" }}
						>
							{progress}%
						</div>

					</div>
				)}
				{errors?.media && (
					<div className="text-danger validateToDelete">{errors?.media}</div>
				)}
				{/* icon upload */}
				<div className={`row m-0 mb-2  align-items-center mt-2`}>
					<label className="formLabel p-0 mb-0">icon </label>
					{stateIcon ? (
						<div className="globalImageWrapper position-relative p-1 mt-2">
							<Tooltip title="Remove Icon">
								<label htmlFor="viewIcon" className={`globalRemoveImage`}>
									<img
										src={removeIcon}
										alt="delete"
										onClick={() => removeImgHandler("icon")}
									/>
								</label>
							</Tooltip>
							<div
								className="globalPreviewedImage"
								style={{ backgroundImage: `url(${stateIcon})` }}
							></div>
						</div>
					) : (
						<div className="px-0">
							<div
								className="imageWrapper p-0 mt-2"
								style={{ width: "180px", height: "140px" }}
							>
								<label
									htmlFor="propertyIcon"
									className="h-100 w-100 d-flex flex-column justify-content-center align-items-center"
								>
									<img
										width="70px"
										height="50px"
										src={uploadImage}
										alt="upload"
									/>
									<input
										accept=".jpg, .jpeg, .png ,.webp"
										onChange={(e) => upload(e, "icon")}
										name="icon"
										type="file"
										value={""}
										disabled={progress}
										className={`d-none`}
										id="propertyIcon"
									/>
									<span> upload icon </span>
								</label>
							</div>

						</div>
					)}
				</div>

				{iconProgress !== 0 && (
					<div className="progress mt-2" style={{ width: "11.25rem" }}>
						<div
							className="progress-bar progress-bar-info progress-bar-striped"
							role="progressbar"
							aria-valuenow={iconProgress}
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: iconProgress + "%" }}
						>
							{iconProgress}%
						</div>

					</div>
				)}{errors?.icon
					&& (
						<div className="text-danger validateToDelete">{errors?.icon}</div>
					)}

				<div className={`mb-4 `} style={{ width: "50%" }}>
					<div className="d-flex flex-column">
						<label htmlFor={`section_color`} className="formLabel">
							section color
						</label>
						<input
							name="name"
							id={`section_color`}
							type="color"
							value={model.icon_color}
							onChange={(e) => setFormData(e, "icon_color")}
							required
						/>
					</div>
					<div className="text-danger validateToDelete">
						{errors[`icon_color`]}
					</div>
				</div>

				{!loading ? (
					<button className="confirmBtn mt-5" type="submit">
						{id ? `save changes` : `add section`}
					</button>
				) : (
					<button className="confirmBtn mt-5" disabled>
						<span
							className="spinner-border spinner-border-sm me-2"
							role="status"
							aria-hidden="true"
						></span>
						saving...
					</button>
				)}
			</form>
		</section>
	);
}
