import React from 'react'
import GIF from './empty.gif'
export default function NotDataFound({t}) {
    return (
        <div className='pagesWrapper h-50'>
            <div className='d-flex justify-content-center align-items-center flex-column mb-4 h-100'>
                <img src={GIF} alt='' className='w-25 p-0 m-0'/>
                <h5 className='noDataFound'>No Data Found</h5>
            </div>
        </div>
    )
}
