import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getLevels(obj){
    return await https.get(`${api_url}dashboard/levels?${obj}`);
}

export async function getLevel(id){
    return await https.get(`${api_url}dashboard/levels/${id}`);
}

export async function deleteLevel(id){
    return await https.delete(`${api_url}dashboard/levels/${id}`);
}

export async function updateLevel(id, data){
    return await https.put(`${api_url}dashboard/levels/${id}`, data);
}

export async function createLevel(data){
    return await https.post(`${api_url}dashboard/levels`, data);
}