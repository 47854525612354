import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { basename } from "../../Configurations/config";
import overview from "../../assets/icons/overview.svg";
import spams from "../../assets/icons/spams.svg";
import admins from "../../assets/icons/admins.svg";
import budgets from "../../assets/icons/budgets2.svg";
import skills from "../../assets/icons/skills.svg";
import transactions from "../../assets/icons/transactions.svg";
import withdraws from "../../assets/icons/withdraws.svg";
import levels from "../../assets/icons/levels.svg";
import fees from "../../assets/icons/fees.svg";
import coupons from "../../assets/icons/coupons.svg";
import leads from "../../assets/icons/contact2.svg";
import logo from "../../assets/images/logo.png";
import guide from "../../assets/icons/guide.svg";
import sections from "../../assets/icons/sections.svg";
import services from "../../assets/icons/services2.svg";
import projects from "../../assets/icons/project.svg";
import orders from "../../assets/icons/order.svg";
import appearance from "../../assets/icons/appearance.svg";
import users from "../../assets/icons/users.svg";
import deleteusers from "../../assets/icons/deleteReq.svg";
import settings from "../../assets/icons/settings.svg";
import "./Aside.scss";
import PermissionsContext from "../../Contexts/permissionsContext";
import { useContext } from "react";
// import PermissionsContext from '../../Contexts/permissionsContext';

export default function Aside() {
	const { Permissions } = useContext(PermissionsContext);
	let closeRef = useRef();
	function closeOffCanvas() {
		if (closeRef.current.style.display === "none") {
			closeRef.current.click();
		}
	}

	return (
		<div className="dashboardAside">
			<div className="asideWrapper">
				<div className="d-flex justify-content-around  justify-content-lg-center  align-items-center mb-4 px-2">
					<img className="asideLogo ps-2 ps-md-0" src={logo} alt="" />
					<button
						ref={closeRef}
						type="button"
						className="btn-close text-reset sideBarClose px-4 d-lg-none"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>

				<ul className="asideList">
					{/* {Permissions?.overview?.view && */}
					<li>
						<NavLink
							className={`navLink`}
							to={`${basename}/dashboard/overview`}
							onClick={closeOffCanvas}
						>
							<img src={overview} alt="icon" />
							<span className="capitalWord">{"overview"}</span>
						</NavLink>
					</li>
					{/* } */}
					{Permissions["contact-us"]?.view && (
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/contactus-msgs`}
								onClick={closeOffCanvas}
							>
								<img src={leads} alt="icon" />
								<span className="capitalWord">{"contact us msgs"}</span>
							</NavLink>
						</li>
					)}
				</ul>
				{/* {(Permissions?.overview?.view || Permissions?.overview?.view) && */}
				<div className="asideSplitter"></div>
				{/* } */}
				{/* services  */}
				{Permissions["service"]?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/services?status=2`}
								onClick={closeOffCanvas}
							>
								<img src={services} alt="icon" />
								<span className="capitalWord">{"services"}</span>
							</NavLink>
						</li>
					</ul>
				)}
				{/* projects  */}
				{Permissions["project"]?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/projects?status=2`}
								onClick={closeOffCanvas}
							>
								<img src={projects} alt="icon" />
								<span className="capitalWord">{"projects"}</span>
							</NavLink>
						</li>
					</ul>
				)}
				{/* orders  */}
				{Permissions["order"]?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/orders?stage=2`}
								onClick={closeOffCanvas}
							>
								<img src={orders} alt="icon" />
								<span className="capitalWord">{"orders"}</span>
							</NavLink>
						</li>
					</ul>
				)}
                {Permissions["order-cancel-request"]?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
                                to={`${basename}/dashboard/order-cancel-requests`}
								onClick={closeOffCanvas}
							>
								<img src={orders} alt="icon" />
                                <span className="capitalWord">{"cancel requests"}</span>
							</NavLink>
						</li>
					</ul>
				)}
				{/* competitions  */}
				{/*  <ul className='asideList'>
                    <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/competitions`} onClick={closeOffCanvas}>
                            {<img src={competitions} alt="icon" />}
                            <span className='capitalWord'>{'competitions'}</span>
                        </NavLink>
                    </li>
                </ul> */}
				{(Permissions["service"]?.view || Permissions["project"]?.view) && (
					<div className="asideSplitter"></div>
				)}

				<ul className="asideList">
					{(Permissions.admin?.view || Permissions.role?.view) && (
						<li>
							<NavLink
								className={`navLink`}
								data-bs-toggle="collapse"
								data-bs-target="#usersCollapse"
								to={`${basename}/dashboard/admins`}
							>
								<img src={admins} alt="icon" />
								<span className="capitalWord">{"admins"}</span>
							</NavLink>
							<ul className="collapse asideDropdown" id="usersCollapse">
								{Permissions.admin?.view && (
									<li>
										<NavLink
											to={`${basename}/dashboard/admins/admins-list`}
											className="nestedNavLink"
											onClick={closeOffCanvas}
										>
											{"all admins"}
										</NavLink>
									</li>
								)}
								{Permissions.role?.view && (
									<li>
										<NavLink
											to={`${basename}/dashboard/admins/roles/add`}
											className="nestedNavLink"
											onClick={closeOffCanvas}
										>
											{"roles"}
										</NavLink>
									</li>
								)}
							</ul>
						</li>
					)}
					{Permissions.user?.view && (
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/users`}
								onClick={closeOffCanvas}
							>
								<img src={users} alt="icon" />
								<span className="capitalWord">{"users"}</span>
							</NavLink>
						</li>
					)}
					{Permissions.user?.view && (
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/delete-requests`}
								onClick={closeOffCanvas}
							>
								<img src={deleteusers} alt="icon" />
								<span className="capitalWord">{"delete requests"}</span>
							</NavLink>
						</li>
					)}
				</ul>
				{(Permissions?.admin?.view || Permissions?.user?.view) && (
					<div className="asideSplitter"></div>
				)}
				{Permissions?.transaction?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/transactions`}
								onClick={closeOffCanvas}
							>
								<img src={transactions} alt="icon" />
								<span className="capitalWord">{"transactions"}</span>
							</NavLink>
						</li>
					</ul>
				)}
        {/* withdraws */}
				{Permissions?.withdraw?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/withdraws?status=0`}
								onClick={closeOffCanvas}
							>
								<img src={withdraws} alt="icon" />
								<span className="capitalWord">{"withdraws"}</span>
							</NavLink>
						</li>
					</ul>
				)}
				{/* skills */}
				{(Permissions?.skill?.view || Permissions?.skill?.create) && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/skills`}
								onClick={closeOffCanvas}
							>
								<img src={skills} alt="icon" />
								<span className="capitalWord">{"skills"}</span>
							</NavLink>
						</li>
					</ul>
				)}
				{Permissions?.level?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/levels`}
								onClick={closeOffCanvas}
							>
								<img src={levels} alt="icon" />
								<span className="capitalWord">{"levels"}</span>
							</NavLink>
						</li>
					</ul>
				)}

				{/* sections and subsections */}
				{Permissions?.section?.view && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/sections`}
								data-bs-toggle="collapse"
								data-bs-target="#sectionCollapse"
								onClick={closeOffCanvas}
							>
								<img src={sections} alt="icon" />
								<span className="capitalWord">{"sections"}</span>
							</NavLink>
							<ul className="collapse asideDropdown" id="sectionCollapse">
								<li>
									<NavLink
										to={`${basename}/dashboard/sections`}
										className="nestedNavLink"
									>
										{"All Sections"}
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`${basename}/dashboard/appearance/pages/faqs`}
										className="nestedNavLink"
									>
										{"Sections FAQ"}
									</NavLink>
								</li>
							</ul>
						</li>
					</ul>
				)}

				{/* budget */}
				{(Permissions?.budget?.view || Permissions?.budget?.create) && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/budgets`}
								onClick={closeOffCanvas}
							>
								<img src={budgets} alt="icon" />
								<span className="capitalWord">{"budgets"}</span>
							</NavLink>
						</li>
					</ul>
				)}
				{/* coupon */}
				{(Permissions?.coupon?.view || Permissions?.coupon?.create) && (
					<ul className="asideList">
						<li>
							<NavLink
								className={`navLink`}
								to={`${basename}/dashboard/coupons`}
								onClick={closeOffCanvas}
							>
								<img src={coupons} alt="icon" />
								<span className="capitalWord">{"coupons"}</span>
							</NavLink>
						</li>
					</ul>
				)}
				{/* guide sections */}
				<ul className="asideList">
					<li>
						<NavLink className={`navLink`} to={`${basename}/dashboard/guide`} data-bs-toggle="collapse" data-bs-target="#guideCollapse">
							<img src={guide} alt="guide" />
							<span className="capitalWord">{"Guide"}</span>
						</NavLink>
						<ul className="collapse asideDropdown" id="guideCollapse">
							<li onClick={closeOffCanvas}>
								<NavLink to={`${basename}/dashboard/guide/guide-page`} className="nestedNavLink">
									{"guide page"}
								</NavLink>
							</li>
							<li onClick={closeOffCanvas}>
								<NavLink to={`${basename}/dashboard/guide/guide-sections`} className="nestedNavLink">
									{"guide sections"}
								</NavLink>
							</li>
						</ul>
					</li>
				</ul>
				{/* guide sections */}
				{/* {(Permissions["guide-section"]?.view ||
					Permissions["guide-section"]?.create) && (
						
					)} */}

				{/* spams */}
				{(Permissions["report"]?.view || Permissions["report"]?.create) && (
					<>
						<ul className="asideList">
							<li>
								<NavLink
									className={`navLink`}
									to={`${basename}/dashboard/spams`}
									data-bs-toggle="collapse"
									data-bs-target="#spamsCollapse"
								>
									<img src={spams} alt="spams" />
									<span className="capitalWord">{"spams"}</span>
								</NavLink>
								<ul className="collapse asideDropdown" id="spamsCollapse">
									<li onClick={closeOffCanvas}>
										<NavLink
											to={`${basename}/dashboard/spams/spam-options`}
											className="nestedNavLink"
										>
											{"spam options"}
										</NavLink>
									</li>
									<li onClick={closeOffCanvas}>
										<NavLink
											to={`${basename}/dashboard/spams/spam-reports`}
											className="nestedNavLink"
										>
											{"spam reports"}
										</NavLink>
									</li>
								</ul>
							</li>
						</ul>
					</>
				)}
				{Permissions.appearance?.view && (
					<>
						<div className="asideSplitter"></div>
						<ul className="asideList">
							<li>
								<NavLink
									className={`navLink`}
									to={`${basename}/dashboard/appearance`}
									data-bs-toggle="collapse"
									data-bs-target="#appearanceCollapse"
								>
									<img src={appearance} alt="icon" />
									<span className="capitalWord">{"appearance"}</span>
								</NavLink>
								<ul className="collapse asideDropdown" id="appearanceCollapse">
									<li>
										<NavLink
											to={`${basename}/dashboard/appearance/pages`}
											className="nestedNavLink"
										>
											{"pages"}
										</NavLink>
									</li>
								</ul>
							</li>
						</ul>
					</>
				)}

				<div className="asideSplitter"></div>
				<ul className="asideList">
					{/* pages */}
					{/*  {(Permissions?.settings?.show) && */}
					{/* <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/appearance`} data-bs-toggle="collapse" data-bs-target="#appearanceCollapse">
                            <img src={appearance} alt="icon" />
                            <span className='capitalWord' >{'appearance'}</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='appearanceCollapse'>
                            <li>
                                <NavLink to={`${basename}/dashboard/appearance/pages`} className="nestedNavLink">{'pages'}</NavLink>
                            </li>
                        </ul>
                    </li> */}
					{/* } */}

					{/* settings */}
					{Permissions?.setting?.view && (
						<>
							<li>
								<NavLink
									className={`navLink`}
									to={`${basename}/dashboard/fees`}
									onClick={closeOffCanvas}
								>
									{<img src={fees} alt="icon" />}
									<span className="capitalWord">{"fees"}</span>
								</NavLink>
							</li>

							<li>
								<NavLink
									className={`navLink`}
									data-bs-toggle="collapse"
									data-bs-target="#settingsCollapse"
									to={`${basename}/dashboard/settings`}
								>
									<img src={settings} alt="icon" />
									<span className="capitalWord">{"settings"}</span>
								</NavLink>
								<ul className="collapse asideDropdown" id="settingsCollapse">
									<li>
										<NavLink
											to={`${basename}/dashboard/settings/general`}
											className="nestedNavLink"
											onClick={closeOffCanvas}
										>
											{"general"}
										</NavLink>
									</li>
									<li>
										<NavLink
											to={`${basename}/dashboard/settings/footer`}
											className="nestedNavLink"
											onClick={closeOffCanvas}
										>
											{"footer"}
										</NavLink>
									</li>
									<li>
										<NavLink
											to={`${basename}/dashboard/settings/security`}
											className="nestedNavLink"
											onClick={closeOffCanvas}
										>
											{"security"}
										</NavLink>
									</li>
									<li>
										<NavLink
											to={`${basename}/dashboard/settings/account`}
											className="nestedNavLink"
											onClick={closeOffCanvas}
										>
											{"account"}
										</NavLink>
									</li>
									{/* <li>
                                    <NavLink to={`${basename}/dashboard/settings/scripts`} className="nestedNavLink lastOne" onClick={closeOffCanvas}>{'script-page'}</NavLink>
                                </li> */}
								</ul>
							</li>
						</>
					)}
				</ul>
			</div>
		</div>
	);
}
