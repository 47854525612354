import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setData } from './formHandler';
import { supportedLanguages } from './../../../Utilities/SupportedLanguages';
import { getLevel } from '../../../Apis/levels';
import TitleContext from '../../../Contexts/TitleContext';
import { preventEnter } from '../../../Utilities/preventEnter';
import { basename } from './../../../Configurations/config';

export default function Form(props) {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        changeTitle(``, '', { url: "levels" });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(model);
        // eslint-disable-next-line
    }, [model]);

    useEffect(() => {
        if (id) {
            getLevel(id).then((res) => {
                let x = res.data.data;
                console.log(x)
                setModel({
                    locales: x.locales,
                    min_orders_count:x.min_orders_count
                });
            })
        } else {
            setModel({ locales: {} });
        }
    }, [id]);

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setData(model, setModel, e, property, locale, language);
        let err = { ...errors };
        if (locale) {
            err[`locales.${language}.${property}`] = '';
            err[`locales`]=''
        } else {
            err[property] = '';
        }
        setErrors(err)
    }

    // onsubmit
    const confirmLevel = async (e, model) => {
        e.preventDefault();
        setLoading(true);
        console.log(model)
        let submitProps = await props.onSubmit(model);
        if (submitProps === "done") {
            setLoading(false);
            navigate(`${basename}/dashboard/Levels`);
            if (!id) {
                setModel({});
            }
        } else {
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }




    return (
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{id ? `update Level` : `add new Level`}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirmLevel(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div className="w-100">
                        {supportedLanguages.map((language) => {
                            return (
                                <div key={language.slug} style={{ "width": "50%" }}>
                                    <div className='d-flex flex-column mb-4'>
                                        <label htmlFor={`name_${language.slug}`} className='formLabel'>name ({language.name})</label>
                                        <input name="name" id={`name_${language.slug}`} type='text' placeholder='name' value={model?.locales ? model?.locales[language.slug]?.name : " "} onChange={(e) => setFormData(e, 'name', true, language.slug)} minLength={3} maxLength={255} required />
                                        <div className='text-danger validateToDelete'>
                                            {errors[`locales.${language.slug}.name`]}
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column mb-4'>
                                        <label htmlFor={`description_${language.slug}`} className='formLabel'>description ({language.name})</label>
                                        <textarea name="description" id={`description_${language.slug}`} type='text' placeholder='description' value={model?.locales ? model?.locales[language.slug]?.description : " "} onChange={(e) => setFormData(e, 'description', true, language.slug)} minLength={3} maxLength={500} required />
                                        <div className='text-danger validateToDelete'>
                                            {errors[`locales.${language.slug}.description`]}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    <div className='d-flex flex-column mb-4' style={{ "width": "50%" }}>
                        <label htmlFor={`min_orders_count`} className='formLabel'>minimum orders count</label>
                        <input name="min_orders_count" id={`min_orders_count`} type='number' placeholder='orders count' value={model?.min_orders_count ? model?.min_orders_count : " "} onChange={(e) => setFormData(e, 'min_orders_count')} minLength={3} maxLength={255} required />
                        <div className='text-danger validateToDelete'>
                            {errors[`min_orders_count`]}
                        </div>
                    </div>
                    </div>

                </div>



                {!loading ?
                    <button className='confirmBtn mt-5' type='submit'>{id ? `save changes` : `add Level`}</button>
                    :
                    <button className='confirmBtn mt-5' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }

            </form>
        </section>
    )
}
