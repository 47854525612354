import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import edit_icon from "../../../assets/icons/edit.svg";
import "../../tables.scss";
import "../../forms.scss";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { deleteSpamOption, getAllSpamOptions } from "../../../Apis/spams";
import moment from "moment";
import TitleContext from "../../../Contexts/TitleContext";
import { basename } from "../../../Configurations/config";
import DeleteModal from "./../../../Utilities/deleteModal";
import delete_icon from "../../../assets/icons/delete.svg";
import PermissionsContext from "../../../Contexts/permissionsContext";

export default function SpamOptions() {
	const [allSpamOptions, setAllSpamOptions] = useState([]);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [deleted, setDeleted] = useState("");
	const [deletedName, setDeletedName] = useState("spam option");
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line

	}, [pathname]);

	function getSpamOptions() {
		getAllSpamOptions().then((res) => {
			setAllSpamOptions(res.data.data);
			console.log(res.data);
		});
	}

	useEffect(() => {
		changeTitle(`Spam Options `, {
			url: `${basename}/dashboard/spams/spam-options/create`,
			text: "add spam option",
			canAdd: Permissions["report-option"]?.create,
		});
		getSpamOptions();

		// eslint-disable-next-line
	}, []);

	function clickDelete(item) {
		setDeleted(item.id);
		setDeletedName(item?.locales?.en?.name);
	}

	async function onSubmitDelete() {
		return await deleteSpamOption(deleted)
			.then((res) => {
				getSpamOptions();
				return "done";
			})
			.catch((err) => {
				console.log(err.response.data);
				return err.response.data.errors.error;
			});
	}

	return (
		<div>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						{Permissions["report-option"]?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>name</th>
											<th>created at</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allSpamOptions &&
											allSpamOptions.map((item, index) => {
												return (
													<tr key={index}>
														<td>
															{item?.locales?.en?.name
																? item?.locales?.en?.name
																: "no data added"}
														</td>
														<td>
															{item?.created_at
																? moment(item?.created_at).format("DD-MM-YYYY")
																: "no data added"}
														</td>
														<td>
															<div>
																{Permissions["report-option"]?.edit && (
																	<Tooltip title="edit">
																		<Link
																			to={`${basename}/dashboard/spams/spam-options/${item.id}/update`}
																		>
																			<img
																				className="iconActions"
																				src={edit_icon}
																				alt="edit"
																			/>
																		</Link>
																	</Tooltip>
																)}
																{Permissions["report-option"]?.delete && (
																	<Tooltip title="delete this skill">
																		<img
																			data-bs-toggle="modal"
																			onClick={() => clickDelete(item)}
																			data-bs-target="#deleteModal"
																			className="iconActions"
																			src={delete_icon}
																			alt="edit"
																		/>
																	</Tooltip>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>
					<DeleteModal
						onSubmitDelete={onSubmitDelete}
						name={deletedName}
					></DeleteModal>
				</section>
			}
		</div>
	);
}
