import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import TitleContext from '../../../Contexts/TitleContext';
import "./WebsitePages.scss";
import rightarrow from "../../../assets/icons/rightarrow.svg"
import { basename } from './../../../Configurations/config';
import PermissionsContext from '../../../Contexts/permissionsContext';

export default function WebsitePages() {
    let { changeTitle } = useContext(TitleContext);
    const { Permissions } = useContext(PermissionsContext);

    useEffect(() => {
        changeTitle(`pages`);
        // eslint-disable-next-line
    }, []);

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <>
            <section className='componentGlobalWrapper websitePages'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>{'pages settings'}</h5>
                </div>

                <div>
                    {Permissions?.appearance?.view && <>
                        <div className='overviewPage'>
                            <p>{'home'}</p>
                            <NavLink to={`${basename}/dashboard/appearance/pages/home`}>
                                <img src={rightarrow} className='pageArrow' alt="arrow" />
                            </NavLink>
                        </div>
                        <div className='overviewPage'>
                            <p>{'privacy and policy'}</p>
                            <NavLink to={`${basename}/dashboard/appearance/pages/privacy-policy`}>
                                <img src={rightarrow} className='pageArrow' alt="arrow" />
                            </NavLink>
                        </div>
                        <div className='overviewPage'>
                            <p>{'terms'} &#38; {'conditions'}</p>
                            <NavLink to={`${basename}/dashboard/appearance/pages/terms-and-conditions`}>
                                <img src={rightarrow} className='pageArrow' alt="arrow" />
                            </NavLink>
                        </div>
                        <div className='overviewPage'>
                            <p>about us</p>
                            <NavLink to={`${basename}/dashboard/appearance/pages/about-us`}>
                                <img src={rightarrow} className='pageArrow' alt="arrow" />
                            </NavLink>
                        </div>
                    </>}
                    <div className='overviewPage'>
                        <p>{'main faqs page'}</p>
                        <NavLink to={`${basename}/dashboard/appearance/pages/faq-sections`}>
                            <img src={rightarrow} className='pageArrow' alt="arrow" />
                        </NavLink>
                    </div>
                    {/* <div className='overviewPage'>
                        <p>{'faqs at sections'}</p>
                        <NavLink to={`${basename}/dashboard/appearance/pages/faqs`}>
                            <img src={rightarrow} className='pageArrow' alt="arrow" />
                        </NavLink>
                    </div> */}
                </div>

            </section>
        </>
    )
}
