import React from 'react';
import "../AddRole.scss";
import { useParams } from 'react-router';
import formModel from './formModel';
import { useState } from 'react';
import { setRoleData } from './formHandler';
import { useEffect } from 'react';
import { deleteRole, getRole, getPermissionsList } from '../../../Apis/roles';
import DeleteModal from '../../../Utilities/deleteModal';
import { preventEnter } from '../../../Utilities/preventEnter';
import PermissionsContext from '../../../Contexts/permissionsContext';
import { useContext } from 'react';
import { supportedLanguages } from '../../../Utilities/SupportedLanguages';
/* import { api_basename } from './../../../Configurations/Config'; */

export default function Form(props) {
    let { id } = useParams();
    const [model, setModel] = useState(formModel);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [allpermissions, setallpermissions] = useState();
    const { Permissions } = useContext(PermissionsContext);

    function getPermissions() {
        getPermissionsList().then((res) => {
            //let roles = { ...allpermissions }
            let roles = res.data.data;
            console.log(roles);
            setallpermissions(roles)
        })
    }

    useEffect(() => {
        getPermissions()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        console.log(id)
        if (id) {
            getRole(id).then((res) => {
                let thisRole = res.data.data;
                let thisModel = { locales: thisRole?.locales, permissions: thisRole?.permissions }
                console.log(thisRole);
                setModel(thisModel);
            })
        } else {
            setModel({
                locales: {},
                permissions: [],
                checkAll:0
            })
        }
        // eslint-disable-next-line
    }, [id])

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setRoleData(model, setModel, e, property, locale, language);
        setErrors({});
    }

    // onsubmit
    async function confirmRole(e) {
        e.preventDefault();
        let thisModel = { ...model };
        setLoading(true);
        // send request
        delete thisModel.checkAll;
        let submitProps = await props.onSubmit(e, thisModel);
        if (submitProps === 'done') {
            let m = Object.assign({},model);
            m = { locales: {ar:{name:""},en:{name:""}},  permissions: [],  checkAll: 0 }
            setModel(m)
            setErrors({});
            setLoading(false);
            console.log(model)
        } else if (submitProps === 'done_update') {
            setErrors({});
            setLoading(false);
        }
        else {
            let err = { ...errors }
            setLoading(false);
            // Object.entries(submitProps).map(([key, value]) => {
            //     err[key] = value;
            //     setErrors(err);
            //     return true;
            // });
            Object.entries(submitProps).map(([key, value]) => {
                if (key.split('.')[0] === 'permissions' && key.split('.').length > 1) {
                    let m = key.split('.')[1]
                    console.log(model.permissions[m]);
                    err[`permissions.${model.permissions[m]}`] = value
                } else {
                    err[key] = value;
                }
                console.log(err)
                return true;
            });
            setErrors(err);
            console.log(err)
        }
    }

    function selectAllHandler(e) {
        let thisModel = { ...model, permissions: [] };
        if (e.target.checked) {
            allpermissions && Object.keys(allpermissions).map((section) => {
                allpermissions[section]?.permissions.map((permission) => {
                    thisModel.permissions.push(permission.id)
                    return true
                })
                return true
            })
            thisModel['checkAll'] = 1;
        } else {
            thisModel.permissions = [];
            thisModel['checkAll'] = 0;
        }
        setModel(thisModel);
    }

    const onSubmitDelete = async () => {
        return await deleteRole(id).then((res) => {
            props.getAll();
            return 'deleteRole';
        }).catch(err => {
            return err.response.data.message;
        })
    }
    return (
        <>
            <form onSubmit={confirmRole} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-between align-items-center mb-5'>
                    <h5 className='capitalWord'>{id ? `update role` : `add role`}</h5>
                    <div className='d-flex flex-wrap' style={{ marginInlineStart: '35%' }}>
                        {(id && Permissions?.role?.delete) ?
                            <div style={{ "width": "fitContent", "cursor": "pointer" }} data-bs-toggle="modal" data-bs-target='#deleteModal' className='confirmBtn deleteBtn'>delete</div>
                            : ''}
                        {(Permissions?.role?.edit || Permissions?.role?.create) &&
                            <>
                                {!loading ?
                                    <button className='confirmBtn' type='submit'>{id ? `save changes` : `add role`}</button>
                                    :
                                    <button className='confirmBtn' disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        saving...
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>
                {/*** role title and description ***/}
                <div className='pagesForm'>
                    <div className='d-flex flex-column mb-4'>
                        {/* <label htmlFor='name' className='formLabel'>name</label>
                        <input name="name" id='name' type='text' placeholder='name' value={model?.name} onChange={(e) => setFormData(e, 'name')} minLength={3} maxLength={255} required />
                        {errors?.name && <div className='validateToDelete text-danger'>
                            {errors?.name}
                        </div>} */}
                        {supportedLanguages.map((language) => {
                            return (
                                <div key={language.slug} className='mb-4' >
                                    <div className='d-flex flex-column'>
                                        <label htmlFor={`name_${language.slug}`} className='formLabel'>name ({language.name})</label>
                                        <input name="name" id={`name_${language.slug}`} type='text' placeholder='name' value={model?.locales ? model?.locales[language.slug]?.name : " "} onChange={(e) => setFormData(e, 'name', true, language.slug)} minLength={3} maxLength={255} required />
                                    </div>
                                    <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.name`]}
                                    </div>
                                    {errors[`locales.${language.slug}.name`] && <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.name`]}
                                    </div>}
                                </div>)
                        })}
                    </div>
                </div>

                {/**** permissions ****/}
                <div className='permissionsWrapper'>
                    <div className='mb-5'>
                        <h5 className='capitalWord p-0'>permissions</h5>
                        {errors?.permissions && <div className='validateToDelete text-danger'>
                            {errors?.permissions}
                        </div>}
                    </div>
                    <div className='checkLabelWrapper'>
                        <input className='permissionsCheckbox' onChange={selectAllHandler} type="checkbox" id="all" name="all" defaultValue="0"
                            checked={model?.checkAll} />
                        <label htmlFor="all">select all</label>
                    </div>
                    <div>
                        {
                            allpermissions && Object.keys(allpermissions).map((section, i) => {
                                return (
                                    <div className='mt-5' key={section}>
                                        <p className='mb-2'>{section}</p>
                                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 m-0 p-0' key={section}>
                                            {allpermissions && allpermissions[section]?.permissions.map((permission) => {
                                                return (
                                                    <div className='col mt-2' key={permission.name}>
                                                        <div key={permission.id} className='checkLabelWrapper'>
                                                            <input className='permissionsCheckbox' type="checkbox" name="permissions" value={permission?.id} id={permission.id} onChange={(e) => setFormData(e, 'permissions')}
                                                                checked={model?.permissions?.includes(Number(permission.id))} /* {...(model?.permissions?.includes(Number(permission.id)) ? {'checked': true} :{}) } */ />
                                                            <label htmlFor={permission.id}>
                                                                {permission?.name}
                                                            </label>
                                                        </div>
                                                        <div className='validateToDelete text-danger'>
                                                            {errors[`permissions.${[permission.id]}`] ?? ''}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>)
                            })
                        }
                    </div>
                </div>
            </form>
            <DeleteModal onSubmitDelete={onSubmitDelete} name={model.name}></DeleteModal>
        </>
    )
}
