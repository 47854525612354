import React, { useState, useEffect, useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import pause from "../../assets/icons/pause.svg";
import play from "../../assets/icons/play.svg";
import "../tables.scss";
import "../forms.scss";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../../Components/Pagination/Pagination";
import DeleteModal from "../../Utilities/deleteModal";
import moment from "moment";
import { basename } from "../../Configurations/config";
import TitleContext from "../../Contexts/TitleContext";
import {
	getFaqsSections,
	deleteFaqSection,
	toggleStatusFaqSection,
} from "../../Apis/faq";
import PermissionsContext from "../../Contexts/permissionsContext";

export default function Faqsections() {
	const [allSections, setAllSections] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		search: "",
		status: "active",
	});
	const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
	const [deletedSection, setDeletedSection] = useState("");
	const [deletedName, setDeletedName] = useState("section");
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line
	}, [pathname]);

	function getAllFaqSections(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}`;
		console.log(params);
		getFaqsSections(params).then((res) => {
			setAllSections(res.data.data);
			console.log(res.data);
			let total = res.data?.pagination?.total;
			changeTitle(`Main FAQs Sections (${total})`, {
				url: `${basename}/dashboard/appearance/pages/faq-sections/create`,
				text: "add Section",
				canAdd: Permissions && Permissions["faq-section"]?.create,
			});
			setPageCount(Math.ceil(total / filter.per_page));
		});
	}

	useEffect(() => {
		changeTitle("", "");
		getAllFaqSections(filterObj);
		// eslint-disable-next-line
	}, []);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		setfilterObj(thisObj);
		getAllFaqSections(thisObj);
	}

	async function onSubmitDelete() {
		return await deleteFaqSection(deletedSection)
			.then((res) => {
				getAllFaqSections(filterObj);
				return "done";
			})
			.catch((err) => {
				console.log(err.response);
				return err.response.data.message;
			});
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getAllFaqSections(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		setfilterObj2(thisObj2);
	};

	function toggleStatue(id) {
		toggleStatusFaqSection(id).then(() => {
			getAllFaqSections(filterObj);
		});
	}

	function changeStatus(status) {
		let thisObj = { ...filterObj };
		thisObj.status = status;
		thisObj.page = 1;
		setfilterObj(thisObj);
		getAllFaqSections(thisObj);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = thisObj.per_page;
		thisObj2.search = thisObj.search;
		setfilterObj2(thisObj2);
	}

	function clickDelete(item) {
		setDeletedSection(item.id);
		setDeletedName(item?.locales?.en?.name);
	}

	return (
		<>
			<section className="componentGlobalWrapper d-flex flex-column">
				<div>
					<div
						className="tabs-container nav nav-tabs d-flex align-items-center"
						id="myTab"
						role="tablist"
					>
						<div
							onClick={() => changeStatus("active")}
							className="tablinks active"
							id="Gallery-tab"
							data-bs-toggle="tab"
							data-bs-target="#allnews"
						>
							active
						</div>
						<span className="separator"></span>
						<div
							onClick={() => changeStatus("inactive")}
							className="tablinks"
							id="published-tab"
							data-bs-toggle="tab"
							data-bs-target="#published-Img"
						>
							inactive
						</div>
					</div>
					<div className="searchActionsBar w-100">
						<div className="searchActionsBar mt-0 mb-0">
							{/*** search input ***/}
							<div className="searchInputWrapper">
								<div>
									<input
										type="text"
										className="barSearchInput"
										name="search"
										onChange={changeFilterObj}
									/>
								</div>
								<img src={search} alt="search-icon" className="barSearchIcon" />
							</div>
						</div>

						{/* show select */}
						<div className="perPageContainer">
							<label htmlFor="selectShow" className="perPageLabel">
								show
							</label>
							<select
								name="per_page"
								onChange={changeFilterObj}
								id={`selectShow`}
								className="form-select selectShow"
								value={filterObj2?.per_page}
							>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
							</select>
						</div>
						{/*** apply search btn  ***/}
						<button className="applySearchBtn" onClick={getFiltered}>
							search
						</button>
					</div>
					{
						<div className="tableWrapper">
							<table className="w-100 table table-striped ">
								<thead>
									<tr>
										<th>name</th>
										<th>no. of faqs</th>
										<th>created at</th>
										<th>actions</th>
									</tr>
								</thead>
								<tbody>
									{allSections &&
										allSections.map((section, index) => {
											return (
												<tr key={index}>
													<td>
														{section?.locales?.en?.name
															? section?.locales?.en?.name
															: "no data added"}
													</td>
													<td>
														No. of faqs : {section?.faqs_count}
														<br />
														<br />
														{/* {Permissions?.model?.view && */}{" "}
														<Link
															className="mangeModelsLink"
															to={`${basename}/dashboard/appearance/pages/faq-sections/${section?.id}/faqs`}
														>
															Manage Faqs
														</Link>
														{/* } */}
													</td>
													<td>
														{section?.created_at
															? moment(section?.created_at).format("DD-MM-YYYY")
															: "no data added"}
													</td>
													<td>
														<div>
															{Permissions &&
																Permissions["faq-section"]?.edit && (
																	<Tooltip title="edit">
																		<Link
																			to={`${basename}/dashboard/appearance/pages/faq-sections/${section.id}/update`}
																		>
																			<img
																				className="iconActions"
																				src={edit_icon}
																				alt="edit"
																			/>
																		</Link>
																	</Tooltip>
																)}
															{Permissions &&
															Permissions["faq-section"]?.activate ? (
																section?.status === "active" ? (
																	<Tooltip title="delete this section">
																		<img
																			onClick={() => toggleStatue(section.id)}
																			className="iconActions"
																			src={pause}
																			alt="edit"
																		/>
																	</Tooltip>
																) : (
																	<Tooltip title="delete this section">
																		<img
																			onClick={() => toggleStatue(section.id)}
																			className="iconActions"
																			src={play}
																			alt="edit"
																		/>
																	</Tooltip>
																)
															) : (
																""
															)}
															{Permissions &&
																Permissions["faq-section"]?.delete && (
																	<Tooltip title="delete this section">
																		<img
																			data-bs-toggle="modal"
																			onClick={() => clickDelete(section)}
																			data-bs-target="#deleteModal"
																			className="iconActions"
																			src={delete_icon}
																			alt="edit"
																		/>
																	</Tooltip>
																)}
														</div>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					}
				</div>
				{
					<div className="d-flex justify-content-end mt-auto">
						<Pagination
							pagesNumber={pageCount}
							page={filterObj.page - 1}
							pageChangeHandler={handlepageChange}
						/>
					</div>
				}
				<DeleteModal
					onSubmitDelete={onSubmitDelete}
					name={deletedName}
				></DeleteModal>
			</section>
		</>
	);
}
