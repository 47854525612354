import moment from 'moment/moment';
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { getTransactions } from '../../Apis/transactions';
import Pagination from '../../Components/Pagination/Pagination';
// import { basename } from '../../Configurations/config';
import PermissionsContext from '../../Contexts/permissionsContext';
import TitleContext from '../../Contexts/TitleContext';

const Transactions = () => {
    const [allTransactions, setAllTransactions] = useState([]);
    
    const [pageCount, setPageCount] = useState(0);
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({
        per_page: 10,
        page: 1,
    });
    const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });

    const { Permissions } = useContext(PermissionsContext);


    const getAllTransactions = async (filterObj) => {
        let filter = { ...filterObj };
        let params = `per_page=${filter.per_page}&page=${filter.page}`;
        const res = await getTransactions(params)
        console.log('transactions data', res.data);
        const data = await res.data
        setAllTransactions(data.data)
        let total = data?.pagination?.total;
        changeTitle(`Transactions (${total})`);
        setPageCount(Math.ceil(total / filter.per_page));
    }
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
        
        changeTitle(`Transactions`);
        // eslint-disable-next-line
    }, [pathname]);


    useEffect(() => {
        getAllTransactions(filterObj);

        // eslint-disable-next-line
    }, []);

    function changeFilterObj(e) {
        let thisObj = { ...filterObj2 };
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj2(thisObj);
    }

    function getFiltered() {
        let thisObj = { ...filterObj };
        thisObj.page = 1;
        thisObj.per_page = filterObj2.per_page;
        thisObj.search = filterObj2.search;
        setfilterObj(thisObj);
        getAllTransactions(thisObj);
    }

    const handlepageChange = async (data) => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        getAllTransactions(filter);

        let thisObj2 = { ...filterObj2 };
        thisObj2.per_page = filter.per_page;
        thisObj2.search = filter.search;
        setfilterObj2(thisObj2);
    };

    return (
        <>{
            <section className="componentGlobalWrapper d-flex flex-column">
                <div>
                    <div className="searchActionsBar w-100">
                        {/* show select */}
                        <div className="perPageContainer">
                            <label htmlFor="selectShow" className="perPageLabel">
                                show
                            </label>
                            <select
                                name="per_page"
                                onChange={changeFilterObj}
                                id={`selectShow`}
                                className="form-select selectShow"
                                value={filterObj2?.per_page}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/*** apply search btn  ***/}
                        <button className="applySearchBtn" onClick={getFiltered}>
                            show
                        </button>
                    </div>
                    {
                        <div className="tableWrapper">
                            {Permissions?.transaction?.view && (
                                <table className="w-100 table table-striped ">
                                    <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>username</th>
                                            <th>created at</th>
                                            <th>type</th>
                                            <th>amount</th>
                                            <th>Pending at</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allTransactions &&
                                            allTransactions.map((transactions, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {transactions?.id ? transactions?.id : "no data added"}
                                                        </td>
                                                        <td>
                                                            {transactions?.user?.username ? transactions?.user?.username : "no data added"}
                                                        </td>
                                                        <td>
                                                            {transactions?.created_at
                                                                ? moment(transactions?.created_at).format(
                                                                    "DD-MM-YYYY"
                                                                )
                                                                : "no data added"}
                                                        </td>
                                                        <td>
                                                            {transactions?.credit ? 'Withdraw' : "Recharge"}
                                                        </td>
                                                        <td>
                                                            {transactions?.credit || transactions?.debit}
                                                        </td>
                                                        <td>
                                                            {transactions?.pending_ends_at ? transactions?.pending_ends_at : "no data added"}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    }
                </div>
                {
                    <div className="d-flex justify-content-end mt-auto">
                        <Pagination
                            pagesNumber={pageCount}
                            page={filterObj.page - 1}
                            pageChangeHandler={handlepageChange}
                        />
                    </div>
                }
            </section>
        }
        </>
    )
}

export default Transactions