import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { style } from "./suspend.js";
import { suspendUser, getSuspendCases } from "../../Apis/user";
import "react-datetime/css/react-datetime.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { ConstructionOutlined } from "@mui/icons-material";
import { useEffect } from "react";
export default function SuspendModal({
  open,
  setOpen,
  userId,
  username,
  suspensionHistory,
}) {
  const [susObj, setsusObj] = useState({
    reason: "",
    from: "",
    duration: 0,
    user: "",
    cases: [],
  });
  const [errors, setErrors] = useState();

  const handleClose = () => {
    setOpen(false);
    setsusObj({ reason: "", from: "", duration: 0, user: "" });
    setErrors("");
  };

  const today = new Date().toISOString().slice(0, 10);

  function changeSuspendHandler(e) {
    const { name, value } = e.target;
    // console.log(userId);
    setsusObj((prevState) => ({ ...prevState, [name]: value, user: userId }));

    let err = { ...errors };
    err[e.target.name] = "";
    setErrors(err);
  }

  useEffect(() => {
    getSuspendCases()
      .then((res) => {
        setSuspendCases(res?.data?.data);
      })
      .catch((err) => {
        console.log("DOnt Forget To handle SUS CASES FROM END POINT");
        console.log(err);
      });
  }, []);

  function onSuspend(e) {
    if (
      susObj.reason == "" ||
      susObj.from == "" ||
      susObj.duration == 0 ||
      susObj.user == "" ||
      susObj.cases == 0
    ) {
      setErrors({ message: "Please fill all fields" });
    } else {
      suspendUser(susObj)
        .then((res) => {
          console.log(res);
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          let err = { ...errors };
          Object.entries(error.response.data.errors).map(([key, value]) => {
            err[key] = value;
            setErrors(err);
            return true;
          });
        });
    }
  }

  /* React Select Handling */
  const [suspendCases, setSuspendCases] = useState();
  console.log(suspendCases);
  let Cases=[];
  
  let options = () =>{
      suspendCases?.map((mycase) => {
          let theCase = {value:"",label:""}
          theCase = {value:mycase.id,label:mycase.locales.en.name}
          return Cases.push(theCase)
        })
    }
    options()
console.log(Cases);

  const animatedComponents = makeAnimated();

  function handleSelect(e) {
    let mycases = [];
    e?.map((option) => {
      mycases.push(option.value);
    });
    setsusObj({ ...susObj, cases: mycases });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box className="w-50 border-0 rounded" sx={style}>
          <Typography
            className="d-flex justify-content-between text-center"
            id="transition-modal-title"
            variant="h6"
            component="h2"
          >
            <b> Suspend {username}</b>
            <div
              className="closeModalIcon"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </Typography>
          {errors?.message && (
            <div className="text-danger p-2  errorText">
              <div>{errors?.message}</div>
              <div>{errors?.error}</div>
            </div>
          )}
          <b>Duration</b>
          <div className="d-flex  mx-2">
            <div className="d-flex flex-column ">
              <label htmlFor="from">From</label>
              <input
                type="date"
                id="from"
                name="from"
                min={today}
                value={susObj.from}
                dateformat="YYYY-MM-DD"
                onChange={changeSuspendHandler}
              />
            </div>
            <div className="d-flex flex-column mx-2">
              <label htmlFor="days">Days</label>
              <input
                id="days"
                type="number"
                name="duration"
                min={1}
                value={susObj.duration}
                onChange={changeSuspendHandler}
                required
              />
            </div>
          </div>
          <b className="mt-2">Case</b>
          <Select
            isMulti
            options={Cases}
            onChange={handleSelect}
            closeMenuOnSelect={false}
            className="m-2 CaseSelect"
            components={animatedComponents}
            placeholder={`Please Enter The Case..!`}
          />
          <b>Reason</b>
          <div>
            <textarea
              className="m-2 formsText w-100"
              name="reason"
              required
              placeholder="Please Give a Reason..."
              onChange={changeSuspendHandler}
            />
            <div className="d-flex justify-content-center">
              <button
                onClick={() => onSuspend()}
                className="btn btn-danger w-25"
              >
                Suspend
              </button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
