import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateGuideArticle } from '../../../Apis/guideArticles';
import { useEffect, useContext } from 'react';
import { getGuideSection } from '../../../Apis/guideSections';
import TitleContext from '../../../Contexts/TitleContext';

export default function UpdateGuideArticle() {
  const { article_id ,id } = useParams();
  const { changeTitle } = useContext(TitleContext);

  useEffect(()=>{
    id && getGuideSection(id).then((res)=>{
      console.log(res.data.data)
      changeTitle(`create article at ${res.data.data?.locales?.en?.name}`);
    })
    
  },[id])

    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        (delete data.media)
        console.log("data that sent ::::::::::::",data)
        return await updateGuideArticle(article_id,data).then(res => {
            return 'done';          
        }).catch(err => {
            return err.response.data.errors;
        })
    };

  return (
    <div>
        <Form onSubmit={onSubmit} />
    </div>
  )
}
