import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router';
import { getService } from '../../Apis/services';
import TitleContext from '../../Contexts/TitleContext';
import pdfImg from '../../assets/images/pdfImg.jpg';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import vector from '../../assets/icons/Vector.svg'

export default function ServiceDetails() {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState();
    const { id } = useParams();
    const [loader, setLoader] = useState()
    useEffect(() => {
        window.scrollTo(0, 0);
        changeTitle(`service details`);
    }, [pathname])

    useEffect(() => {
        console.log(id)
        id && getService(id).then((res) => {
            console.log(res.data.data);
            setModel(res.data.data);
        }).catch((err) => {
            console.log(err.response);
        })
        // eslint-disable-next-line
    }, [id]);

    return (
        <section className='componentGlobalWrapper addBlog'>
            {model && <form>
                <div className='formWidth'>
                    <div className='mb-4'>
                        <label className='formLabel p-0 mb-0'> service image </label>
                        <Gallery>
                            <Item
                                original={model?.image?.url}
                                thumbnail={vector}
                                width="1500"
                                height="800"
                            >
                                {({ ref, open }) => (
                                    <div className='serviceImageWrapper p-1 mt-2' ref={ref} onClick={open} >
                                        <img alt='servive' src={model?.image?.url} />
                                    </div>
                                )}
                            </Item>
                        </Gallery>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>title</label>
                        <input type='text' value={model?.title} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>description</label>
                        <textarea type='text' value={model?.description} readOnly />
                    </div>
                    <div className='d-flex'>
                        <div className='w-100 d-flex flex-column mb-4 marginEnd'>
                            <label className='formLabel'>section</label>
                            <input type='text' value={`${model?.subsection?.section?.name}`} readOnly />
                        </div>
                        <div className='w-100 d-flex flex-column mb-4 marginStart'>
                            <label className='formLabel'>sub section</label>
                            <input type='text' value={model?.subsection?.name} readOnly />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='w-100 d-flex flex-column mb-4 '>
                            <label className='formLabel'>service's owner username</label>
                            <input type='text' value={`${model?.user?.username}`} readOnly />
                        </div>
                    </div>
                    {/* skills */}
                    <div className='d-flex flex-column mb-4' >
                        <label htmlFor='metaKeyword' className='formLabel'>skills</label>
                        <div className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                            <div className="">
                                {
                                    model?.skills?.map((item, index) => (
                                        <div key={index} className={`tagContainer px-3 py-2`}>
                                            {item?.locales?.en?.name}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {/* media */}
                    <div className="row m-0 mb-2 d-flex align-items-center mt-2 mb-4">
                        <label className='formLabel p-0 mb-0'> media </label>
                        {/* <div className='globalImageWrapper position-relative p-1 mt-2'>
                            <div className='globalPreviewedImage' style={{ backgroundImage: `url(${model?.image?.url})` }}></div>
                        </div> */}
                        {
                            model?.media?.map((item, index) => {
                                if (item.extension !== 'pdf') {
                                    return (


                                        <Gallery>
                                            <Item
                                                original={item?.url}
                                                thumbnail={vector}
                                                width="1000"
                                                height="700"
                                            >
                                                {({ ref, open }) => (
                                                    <div key={index} ref={ref} onClick={open} className='globalImageWrapper position-relative p-1 mt-2'>
                                                        <div className='globalPreviewedImage' style={{ backgroundImage: `url(${item?.url})` }}></div>
                                                    </div>
                                                )}
                                            </Item>
                                        </Gallery>
                                    )
                                } else {
                                    return (

                                        <a className='globalImageWrapper position-relative p-1 mt-2' href={item?.url} key={index} target='_blank' rel='noreferrer' download={item?.name}>
                                            <div className='globalPreviewedImage' style={{ backgroundImage: `url(${pdfImg})` }}></div>
                                            <p className='mediaName'>
                                                {item.name}.{item.extension}
                                            </p>
                                        </a>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <label className='formLabel'> plans</label>
                <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 mt-3 gx-3 '>
                    {
                        model?.plans?.map((plan, i) => (
                            <div key={i} className='col border p-4 mx-2'>
                                <div className='UpdateProject plansFormContainer h-100'>
                                    <div className='plansInsideWrapper'>
                                        {/* plan title */}
                                        <div className='d-flex flex-column mb-4'>
                                            <label className='formLabel'> plan title</label>
                                            <input className='formsInput' name='title' type='text' value={plan?.title} readOnly />
                                        </div>

                                        {/* plan description */}
                                        <div className='d-flex flex-column mb-4'>
                                            <label className='formLabel' > plan description </label>
                                            <textarea className='formsText' type='text' value={plan?.description} readOnly />
                                        </div>

                                        <div className='row row-cols-2 mb-4'>
                                            {/* price */}
                                            <div className='d-flex flex-column col'>
                                                <label className='formLabel'> {'plan price'} </label>
                                                <input className='formsInput' name='price' type='number' value={plan?.price} readOnly />
                                            </div>
                                            {/* duration */}
                                            <div className='d-flex flex-column col'>
                                                <label className='formLabel'> duration (days) </label>
                                                <input className='formsInput' name='duration' type='number' value={plan?.duration} readOnly />
                                            </div>
                                        </div>

                                        {/* plans benefits  */}
                                        <div className='d-flex flex-column col'>
                                            <label htmlFor='metaKeyword' className='formLabel'>plans benefits</label>
                                            <div className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                                <div className="">
                                                    {
                                                        plan?.features?.map((item, index) => (
                                                            <div key={index} className={`tagContainer px-3 py-2`}>
                                                                {item}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </form>}
        </section>
    )
}
