import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { acceptIdentityDocs, getUser, getUserPortfolios, getUserRating, rejectIdentityDocs } from '../../Apis/user';
import TitleContext from '../../Contexts/TitleContext';
import noImg from '../../assets/images/noImg.jpg';
import { Rating } from '@mui/material';
import acceptIcon from '../../assets/icons/accept-with-bg.svg'
import rejectIcon from '../../assets/icons/reject-with-bg.svg'
import arrowLeftIcon from '../../assets/icons/arrowLeft.svg'
import { basename } from '../../Configurations/config';
import './Details.scss'
import { Link } from 'react-router-dom';
import RejectModal from '../../Utilities/rejectModal';
import RateSection from '../../Components/rateSection/rateSection';
import SuspendModal from '../../Components/SuspendModal/SuspendModal';
import CancelSuspend from '../../Components/SuspendModal/CancelSuspend';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import vector from '../../assets/icons/Vector.svg'

export const UserDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { pathname } = useLocation();
    const [model, setModel] = useState();
    const { changeTitle } = useContext(TitleContext);
    const [userRatings, setUserRatings] = useState()
    const [isWaitingForVerified, setisWaitingForVerified] = useState(false);
    const [userPortofolios, setUserPortofolios] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
        getUser(id).then((res) => {
            console.log('user data', res.data.data);
            setModel(res.data.data);
            if (res.data.data?.identity_verified === 2) {
                setisWaitingForVerified(true)
            }
        })
        changeTitle(`user details`);
        getUserRating(id).then((res) => {
            setUserRatings(res.data.data)
            console.log('userRatings', userRatings);
        })
        getUserPortfolios(id).then((res) => {
            const data = res.data.data
            console.log("data here is data:::", data);
            setUserPortofolios(data)
        })
        // eslint-disable-next-line
    }, [pathname, isWaitingForVerified]);

    const creationDate = new Date(model?.created_at).toLocaleDateString('en-US');

    // Modal Suspension 
    //1-Open/close Susoend model
    const [openSuspend, setOpenSuspend] = useState(false);
    const toggleSuspendModalHandler = () => {
        setOpenSuspend(prev => !prev)
    }
    //2-Open/close cancelSuspend model
    const [openCancelSuspend, setOpenCancelSuspend] = useState(false);
    const toggleCancelSuspendModalHandler = () => {
        setOpenCancelSuspend(prev => !prev)
    }
    // displaying date in right format to the admin in suspension history
    let formattedDate = ""
    const getDisplayedDate = (mydate) => {
        const date = new Date(mydate);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hour = ("0" + date.getHours()).slice(-2);
        const minute = ("0" + date.getMinutes()).slice(-2);
        return (formattedDate = ` ${hour}:${minute} - ${year}\\${month}\\${day}`)
    }

    // modal states
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState({});
    const toggleRejectModalHandler = () => {
        setOpen(prev => !prev)
    }
    const acceptIdentityHandler = () => {
        acceptIdentityDocs(id).then((res) => {
            console.log(res);
            if (res.status === 200) {
                const newModel = Object.assign(model, {})
                newModel.identity_verified = 1
                setisWaitingForVerified(false)
                setModel(newModel)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const rejectUserIdentityHandler = async () => {
        rejectIdentityDocs(id, reason)
        setisWaitingForVerified(false)
        toggleRejectModalHandler()
    }

    const isOnline = model?.is_online ? { backgroundColor: 'darkgreen' } : { backgroundColor: '#EF1010' }
   
    const goToRatings = () => userRatings.length > 0
        ? navigate(`${basename}/dashboard/users/${id}/ratings`)
        : console.log("No Ratongs")

    const [suspensionHistory, setSuspensionHistory] = useState(false)
    const SuspensionHistory = () => {
        setSuspensionHistory(!suspensionHistory)
    }
    return (
        <>
            {/* user status */}
            < section className='componentGlobalWrapper addBlog userSection' >
                {model &&
                    <>
                        <div className='card p-4 '>
                            <div className='row '>
                                {/* image */}
                                <Gallery>
                                    <Item
                                        original={model?.media?.url}
                                        thumbnail={vector}
                                        width="1500"
                                        height="800"
                                    >
                                        {({ ref, open }) => (
                                            <div ref={ref} onClick={open} className='col-2 imageContainer imgZoom'>
                                                <img objectFit="cover" src={model?.media?.url ? model.media.url : noImg} alt="" />
                                                <span className='onlineStatus ' style={isOnline}></span>
                                            </div>)}
                                    </Item>
                                </Gallery>
                                {/* name and rate */}
                                <div className='col-10 '>
                                    <span className='d-block  mb-1 fw-bold '> {model?.username}</span>
                                    <Rating name="rating" value={model?.ratings_average
                                    } readOnly />
                                    <div className='row row-cols-lg-6 row-cols-md-3 row-cols-sm-2 mt-2 gy-3 status' >
                                        <div className='col userInfo'>gender : <span className='infoValue'>{model?.gender === 1 ? 'male' : 'female'}</span> </div>
                                        <div className='col userInfo'>country : <span className='infoValue'>{model?.country?.locales?.en?.name ? model?.country?.locales?.en?.name : 'no data'}</span> </div>
                                        <div className='col userInfo '>level : <span className='infoValue'>{model?.level?.name ? model?.level?.name : 'no data'}</span> </div>
                                        <div className='col userInfo'>last seen : <span className='infoValue'>{model?.last_seen ? model?.last_seen : 'no data'
                                        }</span> </div>
                                        <div className='col userInfo'>time commitment : <span className='infoValue'>{model?.time_commitment ? model?.time_commitment : 'no data'}</span> </div>
                                        <div className='col userInfo'>reply average : <span className='infoValue'>{model?.replay_average ? model?.replay_average : 'no data'
                                        }</span> </div>
                                        {model.d_o_b && <div className='col userInfo'>date of birth : <span className='infoValue'>{model?.d_o_b ? model?.d_o_b : 'no data'}</span> </div>}
                                        <div className='col userInfo'>member since : <span className='infoValue'>{model.created_at ? creationDate : 'no data'
                                        }</span> </div>
                                        <div className='col userInfo'>completed services : <span className='infoValue'>{model?.completed_services_orders_count ? model?.completed_services_orders_count : 'no data'
                                        }</span> </div>
                                        <div className='col userInfo'>completed projects : <span className='infoValue'>{model?.completed_projects_orders_count ? model?.completed_projects_orders_count : 'no data'}</span> </div>
                                        <div className='col userInfo'>services reordered : <span className='infoValue'>{model?.services_reorders_count ? model?.services_reorders_count : 'no data'}</span> </div>
                                        <div className='w-75 userInfo'>Bio : <span className='infoValue'>{model?.bio ? model?.bio : "no data"}</span> </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-5' >
                            <div className='statusCard'>
                                {/* <Link> */
                                    console.log("LOOOK HERE ::::::", model)
                                }
                                <span className='details'>Projects</span>
                                <span className='number'>{model?.completed_projects_orders_count ? model?.completed_projects_orders_count : 0}</span>
                                {/* </Link> */}

                            </div>
                            <div className='statusCard '>
                                {/* <Link> */}
                                <span className='details'>Services</span>
                                <span className='number'>{model?.completed_services_orders_count ? model?.completed_services_orders_count : 0
                                }</span>
                                {/* </Link> */}

                            </div>
                            <Link to={`${basename}/dashboard/users/works/${id}`} className='statusCard'>
                                <span className='details'>Works</span>
                                <span className='number'>{userPortofolios ? userPortofolios.length : 0
                                }</span>
                            </Link>
                        </div>
                    </>
                }
            </section >
            {/* skills and certifications */}
            < section className='componentGlobalWrapper addBlog userSection' >
                {model &&
                    <>
                        <div className='d-flex justify-content-between   ' >
                            <div className="card  p-2  itretablesCard  " >
                                <span className='title'> Skills</span>
                                <ul className='list'>
                                    {
                                        model?.skills.map((skill, index) => <li key={index} >{skill.name} </li>)
                                    }
                                </ul>
                            </div>

                            <div className="card  p-2 itretablesCard">
                                <span className='title'> Languages</span>
                                {model?.languages.length > 0 ? <ul className='list'>
                                    {
                                        model?.languages.map((language, index) => <li key={index} >{language.locales} </li>)
                                    }
                                </ul> :
                                    <ul className='list'>
                                        <li>no data</li>
                                    </ul>
                                }

                            </div>
                        </div>
                        <div className="card d-flex mt-4 p-4 certificatesCard">
                            <span className='title'> Certifications</span>
                            {console.log("Certificateeeeeeeeeeeeeeeeees:::::::::::", model?.certificates)}
                            <ul className='list'>
                                {model?.certificates.length > 0
                                    ? model?.certificates.map((certificate) => {
                                        return (

                                            <li key={certificate.id}>
                                                <Gallery>
                                                    <Item
                                                        original={certificate?.media?.url}
                                                        thumbnail={vector}
                                                        width="1500"
                                                        height="800"
                                                    >
                                                        {({ ref, open }) => (
                                                            <div ref={ref} onClick={open} className='imgZoom'>
                                                                <img style={{ objectFit: "cover" }}
                                                                    src={certificate?.media?.url} alt={certificate.name} width={'175px'} height='130' />
                                                            </div>)}
                                                    </Item>
                                                </Gallery>
                                                <span className='d-block text-center mt-3'>{certificate.name}</span>
                                            </li>
                                        )
                                    })
                                    :
                                    <h6 className='text-center '>no data</h6>
                                }

                            </ul>
                        </div>
                    </>
                }
            </section >
            {/* verification */}
            < section className='componentGlobalWrapper addBlog userSection' >

                <div className={`card d-flex  p-3 verificationCard  ${model?.identity_verified === 3 ? 'rejected' : ''}`}>

                    {model && <span className='title'> Verification</span>}
                    {
                        (model?.identityDocumentations?.face && model?.identityDocumentations?.back && model?.identityDocumentations?.both) ?
                            <>
                                {
                                    isWaitingForVerified &&
                                    <>
                                        <div className='acceptIcon' onClick={acceptIdentityHandler}>
                                            <img src={acceptIcon} alt="accept icon" />
                                        </div>
                                        <div className='rejectIcon' onClick={toggleRejectModalHandler}>
                                            <img src={rejectIcon} alt="reject icon" />
                                        </div>
                                    </>
                                }
                                <ul className='list mt-2' >

                                    < li >
                                        <Gallery>
                                            <Item
                                                original={model?.identityDocumentations?.face?.media?.url}
                                                thumbnail={vector}
                                                width="1500"
                                                height="800"
                                            >
                                                {({ ref, open }) => (
                                                    <div ref={ref} onClick={open} className='imgZoom'>
                                                        <img style={{ objectFit: "cover" }} src={model?.identityDocumentations?.face?.media?.url} alt="identity_image_face" width={'175px'} height='175px' />
                                                    </div>)}
                                            </Item>
                                        </Gallery>

                                        <span className='d-block text-center mt-3'>face</span>
                                    </li>
                                    <li >
                                        <Gallery>
                                            <Item
                                                original={model?.identityDocumentations?.back?.media?.url}
                                                thumbnail={vector}
                                                width="1500"
                                                height="800"
                                            >
                                                {({ ref, open }) => (
                                                    <div ref={ref} onClick={open} className='imgZoom'>
                                                        <img style={{ objectFit: "cover" }} src={model?.identityDocumentations?.back?.media?.url} alt="identity_image_face" width={'175px'} height='175px' />
                                                    </div>)}
                                            </Item>
                                        </Gallery>

                                        <span className='d-block text-center mt-3'>back</span>
                                    </li>
                                    <li >
                                        <Gallery>
                                            <Item
                                                original={model?.identityDocumentations?.both?.media?.url}
                                                thumbnail={vector}
                                                width="1500"
                                                height="800"
                                            >
                                                {({ ref, open }) => (
                                                    <div ref={ref} onClick={open} className='imgZoom'>
                                                        <img style={{ objectFit: "cover" }} src={model?.identityDocumentations?.both?.media?.url} alt="identity_image_face" width={'175px'} height='175px' />
                                                    </div>)}
                                            </Item>
                                        </Gallery>

                                        <span className='d-block text-center mt-3'>both</span>
                                    </li>
                                </ul>
                            </> :
                            model && <h6 className='text-center '>no data</h6>

                    }

                </div>
                {model?.identity_verified === 3 && <p className='text-danger'>this user was rejected</p>}

            </section >
            {/* Suspension */}
            <section className='componentGlobalWrapper addBlog userSection' >
                <div className={`card d-flex justify-contnet-center p-3 verificationCard`}>
                    <div className=' d-flex justify-content-between align-items-center flex-wrap'>
                        {model && <span className='title'>Suspension</span>}
                        {model && model?.suspends && <div className='icon ' >
                            <img src={arrowLeftIcon} onClick={() => SuspensionHistory()} alt="" />
                        </div>}
                    </div>
                    <div className='d-flex justify-content-end m-2'>
                        <button className="cancelSuspendBtn" onClick={() => toggleSuspendModalHandler()}>
                            {model?.suspends[0]?.status === 1 ? "Update Suspension" : "Suspend"}
                        </button>
                        {model && model?.suspends[0]?.status === 1 && <button className="suspendBtn" onClick={() => toggleCancelSuspendModalHandler()}>
                            Cancel Suspension
                        </button>}
                    </div>
                    <div>
                        {suspensionHistory && <div className='w-auto d-flex flex-wrap justify-content-between align-items-center' >
                            {model?.suspends?.map((suspend, i) => {
                                getDisplayedDate(suspend.created_at)
                                return (
                                    <div key={suspend.id} className={`d-inline-block w-100 flex-grow-1 shadow m-2 p-2 ${suspend.status === 1 && 'suspendedCard'}`} >
                                        <span className='label'>{i + 1}</span>
                                        <span className='mt-2 '>  <b className='title'> Suspend at: </b> {formattedDate}</span>
                                        <div className=' mx-4'> <b className='title'>Reason: </b>{suspend.reason.message}</div>
                                        <div className=' mx-4'> <b className='title'>Cases: </b>{
                                        suspend?.cases?.map((everycase, index)=>{
                                            return <span className=' p-1'>{ everycase.locales.en.name} {suspend?.cases?.length > 1 && index !== suspend?.cases?.length - 1  ? "," : ""} </span>
                                        }
                                        )}</div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </div>
            </section>
            {/* freelancer evaluation */
                console.log("model:::::::::", model)}
            < section className='componentGlobalWrapper addBlog userSection' >
                {model &&
                    <>
                        <div className="card d-flex p-3 justify-content-between evaluationCard flex-wrap">
                            <p>Freelancer evaluation</p>
                            <div className='icon' >
                                <img src={arrowLeftIcon} alt="" onClick={() => goToRatings()} />
                            </div>
                            <RateSection totalRating={model?.ratings_average} userRatings={userRatings} />
                        </div>
                    </>
                } {console.log(model)}
            </section >

            <RejectModal open={open} setOpen={setOpen} title={model?.username} onSubmitReject={rejectUserIdentityHandler} reason={reason} setReason={setReason} errors={errors} setErrors={setErrors} />
            <SuspendModal open={openSuspend} setOpen={setOpenSuspend} userId={id} username={model?.username} suspensionHistory={model?.suspends} />
            <CancelSuspend open={openCancelSuspend} setOpen={setOpenCancelSuspend} userId={id} username={model?.username} />
        </>

    )


}
