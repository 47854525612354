import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getContactMsgs(obj){
    return await https.get(`${api_url}dashboard/contact-us?${obj}`);
}

export async function getContactMsg(id){
    return await https.get(`${api_url}dashboard/contact-us/${id}`);
}

export async function toggleRead(id){
    return await https.patch(`${api_url}dashboard/contact-us/${id}/toggle-read`);
}

export async function deleteContactMsg(id){
    return await https.delete(`${api_url}dashboard/contact-us/${id}`);
}