import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateSection } from '../../../Apis/sections';

export default function UpdateSections() {
  const { id } = useParams();
    // onsubmit
    const onSubmit = async (data) => {
        //to send request
          console.log(data,id)
          data?.icon?.url && delete data?.icon
          data?.image?.url && delete data?.image
        return await updateSection(id,data).then(res => {
            return 'done';          
        }).catch(err => {
          console.log(err.response)
            return err.response.data.errors;
        })
    };

  return (
    <div>
        <Form onSubmit={onSubmit} />
    </div>
  )
}
