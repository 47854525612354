import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setData } from './formHandler';
import { supportedLanguages } from '../../../Utilities/SupportedLanguages';
import { getMainFaq } from '../../../Apis/faq';
import TitleContext from '../../../Contexts/TitleContext';
import { preventEnter } from '../../../Utilities/preventEnter';
import { getSections } from '../../../Apis/sections';

export default function Form(props) {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id, faq_id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [sections, setSections] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        changeTitle(``, '');
        if(!id){
            getSections().then((res)=>{
                console.log(res.data.data)
                setSections(res.data.data);
            })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (faq_id) {
            getMainFaq(faq_id , id ? 'faqs':'section-faqs').then((res) => {
                let x = res.data.data;
                console.log(x)
                setModel({
                    locales: x.locales,
                    section: id ? id : x.section
                });
            })
        } else {
            setModel({ locales: {}, section: id ?? ''});
        }
        // eslint-disable-next-line
    }, [faq_id]);

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setData(model, setModel, e, property, locale, language);
        let err = { ...errors };
        if (locale) {
            err[`locales.${language}.${property}`] = '';
            err[`locales`]=''
        } else {
            err[property] = '';
        }
        setErrors(err)
    }

    /* upload image */

    // onsubmit
    const confirmMainFaq = async (e) => {
        e.preventDefault();
        setLoading(true);

        let submitProps = await props.onSubmit(e, model);
        console.log(submitProps);
        if (submitProps === "done") {
            setLoading(false);
            navigate(-1);
            
        } else {
            setLoading(false);
            setErrors(submitProps);
        }
    }


    return (
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{faq_id ? `update Faq` : `add new Faq`}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirmMainFaq(e)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div className="w-50">
                        {supportedLanguages.map((language) => {
                            return (
                                <div key={language.slug} className='mb-4'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor={`name_${language.slug}`} className='formLabel'>question in ({language.name})</label>
                                        <textarea name="question" id={`name_${language.slug}`} type='text' placeholder='question' value={model?.locales ? model?.locales[language.slug]?.question : " "} onChange={(e) => setFormData(e, 'question', true, language.slug)} minLength={3} maxLength={255} required />
                                    </div>
                                    <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.question`]}
                                    </div>
                                </div>)
                        })}
                        {supportedLanguages.map((language) => {
                            return (
                                <div key={language.slug} className='mb-4'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor={`name_${language.slug}`} className='formLabel'>answer in ({language.name})</label>
                                        <textarea name="answer" id={`name_${language.slug}`} type='text' placeholder='answer' value={model?.locales ? model?.locales[language.slug]?.answer : " "} onChange={(e) => setFormData(e, 'answer', true, language.slug)} minLength={3} maxLength={500} required />
                                    </div>
                                    <div className='text-danger validateToDelete'>
                                        {errors[`locales.${language.slug}.answer`]}
                                    </div>
                                </div>)
                        })}
                        {!id && <div className='mb-4'>
                            <label htmlFor='role' className='formLabel'>section</label>
                            <div className='selectParent'>
                                <select name='role' value={model?.section} onChange={(e) => setFormData(e, 'section')} required>
                                    <option value='' disabled>select section</option>
                                    {sections && sections?.map((item , index)=> (
                                        <option value={item?.id} key={index}>{item?.locales?.en?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>}
                    </div>
                </div>
                {!loading ?
                    <button className='confirmBtn mt-5' type='submit'>{faq_id ? `save changes` : `add faq`}</button>
                    :
                    <button className='confirmBtn mt-5' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
        </section>
    )
}
