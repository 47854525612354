import { useState , useEffect , React} from "react";
import { Outlet } from "react-router-dom";
import { getAdminProfileInfo } from "./Apis/profile.js";
import AuthLayout from './Layouts/authLayout/authLayout.jsx';


export default function ProtectedRoutes() {
    let [authorized , setauthorized] = useState('');

    useEffect(() =>{
        handleInvalidToken()
    } , []);

    const handleInvalidToken = e => {
        if (localStorage.getItem('need4_Admin_Token') == null) {
            setauthorized('not-authorized')
        }else{
          getAdminProfileInfo().then((res)=>{
            setauthorized('authorized')
          }).catch(()=>{
            setauthorized('not-authorized')
          })
        }
      }
    
      useEffect(() => {
        if (typeof window !== undefined) {
            window.addEventListener('storage', handleInvalidToken)
            return function cleanup() {
                window.removeEventListener('storage', handleInvalidToken)
            }
        }
      })  

    if(authorized === 'authorized'){
        return <Outlet/> 
    }else if(authorized === 'not-authorized'){
        return <AuthLayout/>;
    }else{
        return <>
        </>
    }
    
}