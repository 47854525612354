import React from 'react';
import { editAdminProfileInfo } from '../../Apis/profile';
import Form from './Form'

export default function EditSecuritySettings() {

    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await editAdminProfileInfo({...data }).then(res => {
            return 'done';          
        }).catch(err => {
            return err.response.data.errors;
        })
    };

    return (
        <Form onSubmit={onSubmit} ></Form>
    )
}
