import React, { useContext, useEffect, useState } from 'react';
/* import deleteIcon from "../../../../assets/icons/deleteIcon.svg"; */
import { useLocation, useNavigate, useParams } from 'react-router';
import { getSlider } from '../../../../Apis/sliders';
import { basename,  api_url } from '../../../../Configurations/config';
import Tooltip from '@mui/material/Tooltip';
import removeIcon from "../../../../assets/icons/removeIcon.svg";
import uploadImage from "../../../../assets/icons/uploadimage.svg";
import axios from 'axios';
import TitleContext from '../../../../Contexts/TitleContext';

export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState({ image: ""});
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    let [sliderImage , setSliderImage] = useState('');
    let navigate = useNavigate();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle("home slider");
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        if (id) {
            getSlider(id).then((res) => {
                let x = res.data.data;
                console.log(x)
                setModel({image:x.media.id});
                setSliderImage(`${x.media.url}`);
            })
        }else{
            setModel({
                image: "",
            })
        }
    }, [id]);

    /* const setFormData = (e, property , locale=false , language=null) => {
        let newData = Object.assign({}, model);
        if(locale){
            newData.locales[language] = {}
            newData.locales[language][property] = e.target.value;
        }else{
            newData[property] = e.target.value;
        }
        console.log(newData)
        setModel(newData);

        let err = {...errors};
        err[property] = '';
        setErrors(err)
    } */


    // remove image
    function removeImgHandler(){
        setSliderImage('');
        let m = Object.assign({}, model);
        m['media'] = '';
        setModel(m);
    }

    // onsubmit
    const confirmSlider = async (e , model)=>{
        e.preventDefault();
        setLoading(true);
        // send request
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
            navigate(`${basename}/dashboard/appearance/pages/home`);
            
        }else{
            setLoading(false);
            setErrors(submitProps);
        }
    
    }

    const token = `Bearer ` + localStorage.getItem('need4_Admin_Token');
    const [progress, setProgress] = useState(0);

    function upload(e){
        const formData = new FormData();
        formData.append("media[]", e.target.files[0]); 
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
        },headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
            AccessControlAllowOrigin: '*',
            lang: 'en',
            country:'uae',
        }}

        let err = {...errors};
        err['media'] = '';
        setErrors(err)

        axios.post(`${api_url}media` , formData , config).then((res)=>{
            let obj = res.data.data;
            setSliderImage(obj[0]?.url);
            setProgress(0);
            let m = Object.assign({}, model);
            m['media'] = obj[0]?.id;
            setModel(m);
        }).catch((err)=>{
            console.log(err.response)
            let e = {...errors};
            console.log(err.response)
            if(err.response.status=== 422){
                let x = err.response.data.errors.image;
                e['media'] = x;
            }else{
                e['media'] = [`something went wrong at uploading ,error code ${err.response.status}`];
            }
            setErrors(e) 
            setProgress(0);
        })
    }

    return (
        <>
            <section className='componentGlobalWrapper addBlog'>
                {
                    id ?
                    <h5 className='pageSubtitle mb-5'>Update Slider</h5>
                    :
                    <h5 className='pageSubtitle mb-5'>Add New Slider</h5>
                }
                <form style={{"width" : "40%"}} onSubmit={(e) => confirmSlider(e, model)}>
                   {/*  <div  className="w-100">
                    {supportedLanguages.map((language)=>{
                        return(
                            <div key={language.slug} className='mb-4 w-100' style={{"width" : "50%"}}>
                                <div className='d-flex flex-column'>
                                    <label htmlFor={`name_${language.slug}`} className='formLabel'>slider title ({language.name})</label>
                                    <input name="name" id={`name_${language.slug}`} type='text' placeholder='title' value={model?.locales ? model?.locales[language.slug]?.name : " " } onChange={(e) => setFormData(e, 'name' , true , language.slug)} minLength={3} maxLength={20} required />
                                </div>
                                <div className='text-danger validateToDelete'>   
                                    {errors[`locales.${language.slug}.name`]}
                                </div>
                            </div>)})}
                    </div> */}
                    <div className="m-0 mb-5 d-flex flex-column align-items-start ">
                        <label className="formLabel p-0 mb-0">image</label>
                            { sliderImage ?
                                <div  className='globalImageWrapper position-relative p-1 mt-2'>
                                    <Tooltip title='Remove Image'>
                                        <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                            <img src={removeIcon} alt="delete" onClick={()=>removeImgHandler()}/>
                                        </label>
                                    </Tooltip>
                                    <div className='globalPreviewedImage' style={{backgroundImage: `url(${sliderImage})` }}></div>
                                </div>
                                : 
                                <div className='px-0'>
                                    <div className='imageWrapper p-0 mt-2' style={{"width" : "180px" , "height" : "140px"}}>
                                        <label htmlFor='propertyImg' className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                                            <img width='70px' height='50px' src={uploadImage} alt='upload' />
                                            <input onChange={upload} name="image" type="file" accept=".jpg, .jpeg, .png ,.webp,.svg" className={`d-none`} id="propertyImg"/>
                                            <span> upload image </span>
                                        </label>
                                    </div>
                                </div>
                            }
                        {progress !== 0 && (
                            <div className="progress mt-2" style={{"width": "11.25rem"}} >
                            <div className="progress-bar progress-bar-info progress-bar-striped"  role="progressbar"
                                aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                {progress}%
                            </div>
                            </div>
                        )}
                        {errors?.media && <div className='text-danger validateToDelete mt-2'>   
                            {errors?.media[0]}
                        </div>}
                    </div>

                    {!loading ? 
                        <button className='confirmBtn' type='submit'>{id ? `save changes` : `add slider`}</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </form>
            </section>
        </>
    )
}
