import React, {  useEffect, useState } from 'react'
import formModel from './formModel';
import { getBudget } from '../../../Apis/budgets';
import { preventEnter } from '../../../Utilities/preventEnter';

export default function Form(props) {
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const id = props?.id ;
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setErrors({})
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(props?.id);
        setErrors({})
        if(id !== 'create') {
            getBudget(id).then((res) => {
                let x = res.data.data;
                setModel({
                    from: Number(x.from),
                    to: Number(x.to)
                });
            })
        }else{
            setModel({from: '', to: ''});
        }
        
        // eslint-disable-next-line
    }, [props]);

    // onchange
    const setFormData = (e, property) => {
        let newData = Object.assign({}, model);
        newData[property] = Number(e.target.value);
        setModel(newData);
        console.log(newData);
        let err = {...errors};
        err[property] = '';
        err['error'] = '';
        setErrors(err);
    }

    // onsubmit
    const confirmCity = async (e , model)=>{
        e.preventDefault();
        setLoading(true);
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
            setModel({from: '', to: ''});
                            
        }else{
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }


  return (
    <form id='adminForm' onSubmit={(e) => confirmCity(e, model)} onKeyDown={preventEnter}>
        <div className='d-flex w-100'>
            <div className='d-flex flex-column  w-100'>
                <label htmlFor={`from`} className='formLabel'>from</label>
                <input name="from " id={`from`} type='number'  placeholder='from' value={model?.from} onChange={(e) => setFormData(e, 'from')} min={0} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}  required />
                <div className='text-danger validateToDelete'>   
                    {errors[`from`]}
                </div>
            </div>
            <div className='d-flex flex-column mb-4 w-100 marginStart'>
                <label htmlFor={`to`} className='formLabel'>to</label>
                <input name="to " id={`to`} type='number'  placeholder='to' value={model?.to} onChange={(e) => setFormData(e, 'to')} min={0} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}  required />
                <div className='text-danger validateToDelete'>   
                    {errors[`to`]}
                </div>
            </div>
        </div>
        <div className='text-danger validateToDelete'>   
            {errors[`error`]}
        </div>

        {!loading ? 
            <button className='confirmBtn mt-5' type='submit'>{id !== 'create' ? `save changes` : `add budget`}</button>
            :
            <button className='confirmBtn mt-5' disabled>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                saving...
            </button>
        }
    </form>
  )
}
