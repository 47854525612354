import React, { useState } from "react";
/* import Tooltip from '@mui/material/Tooltip';
import edit_icon from "../../../assets/icons/edit.svg";
import delete_icon from "../../../assets/icons/delete.svg";
import pause_icon from "../../../assets/icons/pause.svg";
import play_icon from "../../../assets/icons/play.svg"; */
import "../../tables.scss";
import "../../forms.scss";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import Pagination from "../../../Components/Pagination/Pagination";
import DeleteModal from "../../../Utilities/deleteModal";
import moment from "moment";
import TitleContext from "../../../Contexts/TitleContext";
import PermissionsContext from "../../../Contexts/permissionsContext";
import { getAllSpamReports } from "../../../Apis/spams";
import Tooltip from "@mui/material/Tooltip";
import { BsEnvelopeOpen } from "react-icons/bs";
import MessageModal from "../../../Utilities/messageModal";

export default function SpamReports() {
	const [allGuideSections, setAllGuideSections] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		type: "",
	});
	const [filterObj2, setfilterObj2] = useState({ per_page: 10, type: "" });
	/* const [deletedSection, setDeletedSection] = useState(""); */
	const [deletedName] = useState("section");
	const [description, setDescription] = useState("");
	const [context, setContext] = useState(null);
	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);
		changeTitle(`Spams Reports`);
		// eslint-disable-next-line
	}, [pathname]);

	function getSpamsReport(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&page=${filter.page}&type=${filter.type}`;
		getAllSpamReports(params).then((res) => {
			setAllGuideSections(res.data.data);
			let total = res.data?.pagination?.total;
			setPageCount(Math.ceil(total / filter.per_page));
		});
	}

	useEffect(() => {
		getSpamsReport(filterObj);

		// eslint-disable-next-line
	}, []);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.type = filterObj2.type;
		setfilterObj(thisObj);
		getSpamsReport(thisObj);
	}

	/* async function onSubmitDelete() {
		return await deleteGuideSection(deletedSection).then(res => {
			getSpamsReport(filterObj);
			return "done";
		}).catch(err => {
			console.log(err.response)
			return err.response.data.message;
		})
	} */

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getSpamsReport(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.type = filter.type;
		setfilterObj2(thisObj2);
	};

	/* function toggleActivate(id, action) {
		if (action === 'activate') {
			activateSection(id).then((res) => {
				console.log(res.data);
				getSpamsReport(filterObj);
			})
		} else {
			deactivateSection(id).then((res) => {
				console.log(res.data);
				getSpamsReport(filterObj);
			})
		}
	} */

	/* function clickDelete(item) {
		setDeletedSection(item.id)
		setDeletedName(item?.name.substring(0, 25))
	} */

	return (
		<>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						<div className="searchActionsBar w-100">
							{/* show select */}
							<div className="cityFilter">
								<select
									name="type"
									onChange={changeFilterObj}
									id={`selectShow`}
									className="form-select citySelect"
									value={filterObj2?.type}
								>
									<option value="">all types</option>
									<option value="1">services</option>
									<option value="2">projects</option>
									<option value="3">offers</option>
									<option value="4">messages</option>
								</select>
							</div>
							{/* show select */}
							<div className="perPageContainer">
								<label htmlFor="selectShow" className="perPageLabel">
									show
								</label>
								<select
									name="per_page"
									onChange={changeFilterObj}
									id={`selectShow`}
									className="form-select selectShow"
									value={filterObj2?.per_page}
								>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
								</select>
							</div>
							{/*** apply search btn  ***/}
							<button className="applySearchBtn" onClick={getFiltered}>
								search
							</button>
						</div>
						{Permissions["guide-section"]?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>type</th>
											{/* <th>item</th> */}
											<th>spam option</th>
											{/* <th>note</th> */}
											<th>created by</th>
											<th>Reported user</th>
											<th>created at</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allGuideSections &&
											allGuideSections.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item?.type?.name ?? "no data added"}</td>
														{/* <td>{item?.model?.title}</td> */}
														<td>{item?.report_option?.name ?? ""}</td>
														{/* <td className="desc">
															<div className="descriptionTd">
																{item?.message ?? ""}
															</div>
														</td> */}
														<td>{item?.user?.username ?? "no data added"}</td>
														<td>{item?.reportedUser?.username ?? "no data added"}</td>
														<td>
															{item?.created_at
																? moment(item?.created_at).format("DD-MM-YYYY")
																: "no data added"}
														</td>
														<td>
															<Tooltip title="read the msg content">
																<div
																	className="contactActions"
																	data-bs-toggle="modal"
																	data-bs-target={`#readMsgModel`}
																	onClick={() => {
																		setDescription(item?.message);
																		setContext(item?.context ? item?.context : null);
																	}}
																>
																	<span className="envelopWrapper">
																		<BsEnvelopeOpen />
																	</span>
																</div>
															</Tooltip>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>
					{
						<div className="d-flex justify-content-end mt-auto">
							<Pagination
								pagesNumber={pageCount}
								page={filterObj.page - 1}
								pageChangeHandler={handlepageChange}
							/>
						</div>
					}
					<DeleteModal
						/* onSubmitDelete={onSubmitDelete} */ name={deletedName}
					></DeleteModal>
					<MessageModal description={description} context={context} />
				</section>
			}
		</>
	);
}
