import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router';
import { getProject } from '../../Apis/projects';
import TitleContext from '../../Contexts/TitleContext';
import pdfImg from '../../assets/images/pdfImg.jpg';
import { Gallery, Item } from 'react-photoswipe-gallery';
import vector from '../../assets/icons/Vector.svg';
import 'photoswipe/dist/photoswipe.css';

export default function ProjectDetails() {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState();
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(id)
        getProject(id).then((res) => {
            console.log(res.data.data);
            setModel(res.data.data);
        })

        changeTitle(`project details`);

        // eslint-disable-next-line
    }, [pathname]);

    return (
        <section className='componentGlobalWrapper addBlog'>
            {model && <form>
                <div className='formWidth'>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>title</label>
                        <input type='text' value={model?.title} readOnly />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label className='formLabel'>description</label>
                        <textarea type='text' value={model?.description} readOnly />
                    </div>
                    <div className='d-flex'>
                        <div className='w-100 d-flex flex-column mb-4 marginEnd'>
                            <label className='formLabel'>Duration (days)</label>
                            <input type='text' value={model?.duration} readOnly />
                        </div>
                        <div className='w-100 d-flex flex-column mb-4 marginStart'>
                            <label className='formLabel'>budget</label>
                            <input type='text' value={`${model?.budget?.from} : ${model?.budget?.to}`} readOnly />
                        </div>

                    </div>
                    <div className='d-flex'>
                        <div className='w-100 d-flex flex-column mb-4 marginEnd'>
                            <label className='formLabel'>section</label>
                            <input type='text' value={model?.subsection?.section} readOnly />
                        </div>
                        <div className='w-100 d-flex flex-column mb-4 marginStart'>
                            <label className='formLabel'>sub section</label>
                            <input type='text' value={model?.subsection?.locales?.en?.name} readOnly />
                        </div>
                        {/* <div className='w-100 d-flex flex-column mb-4 marginStart'>
                            <label className='formLabel'>budget</label>
                            <input type='text' value={`${model?.budget?.from} : ${model?.budget?.to}`} readOnly />
                        </div> */}
                    </div>
                    <div className='d-flex'>
                        <div className='w-100 d-flex flex-column mb-4 '>
                            <label className='formLabel'>project's owner username</label>
                            <input type='text' value={`${model?.user?.username}`} readOnly />
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-4' >
                        <label htmlFor='metaKeyword' className='formLabel'>skills</label>
                        <div className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                            <div className="">
                                {
                                    model?.skills?.map((item, index) => (
                                        <div key={index} className={`tagContainer px-3 py-2`}>
                                            {item?.locales?.en?.name}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 mb-2 d-flex align-items-center mt-2">
                        <label className='formLabel p-0 mb-0'> media </label>
                        {
                            model?.media?.map((item, index) => {
                                if (item.extension !== 'pdf') {
                                    return (
                                        <div key={index} className='globalImageWrapper border-0  position-relative p-1 mt-3'>
                                            <Gallery>
                                                <Item
                                                    original={item?.url}
                                                    thumbnail={vector}
                                                    width="1500"
                                                    height="800"
                                                >
                                                    {({ ref, open }) => (
                                                        <div ref={ref} onClick={open} className='imgZoom  '>
                                                            <img style={{ objectFit: "cover" }} className='globalPreviewedImage' src={item?.url} alt='' />
                                                        </div>)}
                                                </Item>
                                            </Gallery>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <a className='globalImageWrapper position-relative p-1 mt-2' href={item?.url} key={index} target='_blank' rel='noreferrer' download={item?.name}>

                                            <div className='globalPreviewedImage' style={{ backgroundImage: `url(${pdfImg})` }}></div>
                                            <p className='mediaName'>
                                                {item.name}.{item.extension}
                                            </p>
                                        </a>

                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </form>}
        </section>
    )
}
