import React from 'react'
const Loader = () => {
    return (
        <>
            <div className='loader-container'>
                <div className='pulse-loader'></div>
            </div>
        </>
    )
}

export default Loader