import { t } from 'i18next';
import React, { useState, useEffect, useContext } from 'react'
import { supportedLanguages } from './../../../Utilities/SupportedLanguages';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TitleContext from '../../../Contexts/TitleContext';
import { getTermsAndConditions, updateTermsAndConditions } from '../../../Apis/pages';
import PermissionsContext from '../../../Contexts/permissionsContext';

export default function TermsAndConditions() {
    let [loader, setLoader] = useState(false);
    const { Permissions } = useContext(PermissionsContext);
    let [model, setModel] = useState({});
    let [editors, setEditors] = useState({})
    let [errors, setErrors] = useState({})
    const { changeTitle } = useContext(TitleContext);

    useEffect(() => {
        changeTitle(t('Terms and conditions'), '');
        getTermsAndConditions().then((res) => {
            let thisEditors = { ...editors };
            let result = res.data.data
            setModel(result)

            supportedLanguages.map((language) => {
                let lang = result.locales[language.slug]
                //set values at editor state
                const contentDataState = ContentState.createFromBlockArray(htmlToDraft(`${lang.description}`));
                const editorDataState = EditorState.createWithContent(contentDataState);
                thisEditors[language.slug] = editorDataState;
                return true
            })
            setEditors(thisEditors);
        })
        // eslint-disable-next-line
    }, [])

    const convertContentToHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()));
    }

    const handleEditorChange = (state, lang) => {
        let thisEditors = { ...editors }
        if (!thisEditors[lang]) {
            thisEditors[lang] = EditorState.createEmpty()
        }
        thisEditors[lang] = state;
        setEditors(thisEditors);

        let newData = Object.assign({}, model);
        newData.locales[lang] = {}
        newData.locales[lang]['description'] = convertContentToHTML(state);
        setModel(newData)
    }
    function handleChange(e, lang) {
        let newData = Object.assign({}, model);
        newData.locales[lang] = {}
        newData.locales[lang]['description'] = e.target.value;
        setModel(newData)
    }
    // const [uploadedImages, setUploadedImages] = useState([])
    // async function _uploadImageCallBack(file) {
    //     const form = new FormData();
    //     form.append('image', file);
    //     let uploadedImages2 = [...uploadedImages];
    //     let imageObject = {}
    //     return await uploadImages(form).then((response) => {
    //         imageObject = {
    //             file: file,
    //             localSrc: response.data.data.web_image,
    //         }
    //         uploadedImages2.push(imageObject);
    //         setUploadedImages(uploadedImages2);
    //         return new Promise(
    //             (resolve, reject) => {
    //                 resolve({ data: { link: imageObject.localSrc } });
    //             }
    //         )
    //     });
    // }

    function submitHandler(e) {
        e.preventDefault();
        setLoader(true);
        let data = { ...model }
        delete data.media
        updateTermsAndConditions(data).then((res) => {
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            let err = { ...errors }
            Object.entries(error.response.data.data).map(([key, value]) => {
                err[key] = value;
                setErrors(err);
                return true;
            });
        })
    }

    return (
        <div className='componentGlobalWrapper'>
            <div className='mb-3'>
                {/* <h5 className='pageSubtitle'>{t('terms-and-conditions')}</h5> */}
                <form className='pagesForm' onSubmit={submitHandler} >
                    {supportedLanguages?.map((language, index) => {
                        return <div key={index} className='d-flex flex-column mb-4'>
                            <label htmlFor='content' className='formLabel'>{`${t('content-in')} (${t(`${language.name}`)})`}</label>
                            <div {...(language.slug === 'ar' ? { 'dir': 'rtl' } : {})}>
                                <Editor
                                    editorState={model && editors[language.slug]}
                                    onEditorStateChange={(state) => handleEditorChange(state, language.slug)}
                                    toolbarClassName="d-none"

                                    // toolbar=
                                    // {{
                                    //     image: { uploadCallback: _uploadImageCallBack },
                                    //     alt: { present: true, mandatory: true }
                                    // }}
                                />
                                {/* <textarea className='textArea' value={model?.locales ? model?.locales[language.slug]?.description : " "} onChange={(e) => handleChange(e, language.slug)} /> */}
                            </div>
                        </div>
                    })}

                    {Permissions?.appearance?.edit &&<>
                        {!loader ?
                            <div>
                                <button className='confirmBtn' type='submit' >
                                    {t('save-changes')}
                                </button>
                            </div>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {t('saving')}...
                            </button>
                        }
                    </>}
                </form>
            </div>
        </div>
    )
}

