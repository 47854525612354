import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateSpamOption } from '../../../../Apis/spams';

export default function UpdateSpamOptions() {
  const { id } = useParams();
    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateSpamOption(id,data).then(res => {
            return 'done';          
        }).catch((err) =>{
            console.log(err.response);
            if(err.response.status === 422){
              return err.response.data.errors; 
            }else if(err.response.status === 403){
              let obj = {error: 'action is not allowed , error 403'}
              return obj;
            } 
      })}

  return (
    <div>
        <Form onSubmit={onSubmit} />
    </div>
  )
}
