import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateFaqSection } from '../../../Apis/faq';

export default function UpdateFaqSection() {
  const { id } = useParams();
    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateFaqSection(id,data).then(res => {
            return 'done';          
        }).catch(err => {
            return err.response.data.errors;
        })
    };

  return (
    <div>
        <Form onSubmit={onSubmit} />
    </div>
  )
}
