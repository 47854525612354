import React from 'react'
import { createSection } from '../../../Apis/sections';
import Form from './Form';

export default function CreateSections() {
  const onSubmit = async (data) => {
    console.log(data);
    //to send request
    return await createSection(data).then(res => {
      if (res.status === 200) {
        console.log(res);
        return 'done';          
      }
    }).catch(err =>{ 
      console.log("Error From Here:::::::::::", err.response.data);
      return err.response.data.errors; 
    })
}

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
