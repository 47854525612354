import React from 'react';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { getFooterSettings, getGeneralSettings,  updateGeneralSettings } from '../../Apis/generalSettings';
import { useLocation, useNavigate } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import { supportedLanguages } from './../../Utilities/SupportedLanguages';
import { preventEnter } from '../../Utilities/preventEnter';

export default function Social() {
    let { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState({});
    const { pathname } = useLocation();
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  useEffect(() => {
    changeTitle(`footer settings`);
    getGeneralSettings().then((res) => {
      console.log(res);
      let generalSettings = res.data.data;
      console.log(res.data.data);
      let mod = { ...model };
      mod.facebook = generalSettings.facebook ?? " ";
      mod.youtube = generalSettings.youtube ?? " ";
      mod.twitter = generalSettings.twitter ?? " ";
      mod.linkedin = generalSettings.linkedin ?? " ";
      mod.instagram = generalSettings.instagram ?? " ";
      mod.email = generalSettings.email ?? " ";
      mod.post_office = generalSettings.post_office ?? " ";
      mod.phone = generalSettings.phone ?? " ";
      mod.address = generalSettings.address ?? " ";
      mod.site_description = generalSettings.site_description ?? " ";
      setModel(mod);
    });
    getFooterSettings().then((res) => {
      console.log(res);
    });
    // eslint-disable-next-line
  }, []);
    useEffect(() => {
        changeTitle(`footer settings`);
        getGeneralSettings().then((res) => {
            console.log(res)
            let generalSettings = res.data.data;
            console.log(res.data.data);
            let mod = { ...model }
            mod.facebook = generalSettings.facebook ?? "";
            mod.youtube = generalSettings.youtube?? "";
            mod.twitter = generalSettings.twitter?? "";
            mod.linkedin = generalSettings.linkedin?? "";
            mod.instagram = generalSettings.instagram?? "";
            mod.email = generalSettings.email?? "";
            mod.post_office = generalSettings.post_office?? "";
            mod.phone = generalSettings.phone?? "";
            mod.address = generalSettings.address?? "";
            mod.site_description = generalSettings.site_description?? ""
            console.log(mod);
            setModel(mod);

        }) },[])

  function changeHandler(e, lang = null) {
    let thisModel = { ...model };
    if (lang) {
      if (thisModel[e.target.name][lang]) {
        thisModel[e.target.name][lang]["description"] = e.target.value;
      } else {
        thisModel[e.target.name][lang] = {};
        thisModel[e.target.name][lang]["description"] = e.target.value;
      }
    } else {
      thisModel[e.target.name] = e.target.value;
    }
    // console.log(thisModel)
    setModel(thisModel);

    let err = { ...errors };
    err[e.target.name] = "";
    setErrors(err);
  }

    function onConfirmEdit(e) {
        e.preventDefault();
        setLoader(true);
        let thisModel = { ...model };
        console.log(thisModel)
        updateGeneralSettings(thisModel).then((res) => {
            console.log(res);
            navigate('/dashboard/overview')
            setLoader(false);
        }).catch((err) => {
            setErrors(err.response.data.errors)
            setLoader(false);
        })

    }

     return (
    <div className="componentGlobalWrapper generalSettings addBlog">
      <div className="socialLinksContainer w-50">
        {/** site title  ***/}
        <form onSubmit={onConfirmEdit} onKeyDown={preventEnter}>
          <div>
            <div className="d-flex flex-column">
              {supportedLanguages.map((language, index) => {
                return (
                  <div key={index} className="d-flex flex-column mb-4">
                    <label
                      htmlFor={`site_description${language}`}
                      className="formLabel"
                    >{`site description in ${language.name}`}</label>
                    <textarea
                      maxLength={500}
                      name="site_description"
                      id={`site_description${language.slug}`}
                      type="text"
                      value={
                        model?.site_description
                          ? model?.site_description[language.slug]?.description
                          : ""
                      }
                      onChange={(e) => changeHandler(e, language.slug)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-column mb-4">
              <label htmlFor={`email`} className="formLabel">{`email`}</label>
              <input
                      maxLength={500}
                      name="email"
                id={`email`}
                type="email"
                value={model?.email}
                onChange={(e) => changeHandler(e, "")}
              />
            </div>

            <div className="d-flex flex-column mb-4">
              <label htmlFor={`post_office`} className="formLabel">{`post_office`}</label>
              <input
                      maxLength={500}
                      name="post_office"
                id={`post_office`}
                type="text"
                value={model?.post_office}
                onChange={(e) => changeHandler(e, "")}
              />
            </div>

            <div className="d-flex flex-column mb-4">
              <label htmlFor={`phone`} className="formLabel">{`phone`}</label>
              <input
                      maxLength={500}
                      name="phone"
                id={`phone`}
                type="text"
                value={model?.phone}
                onChange={(e) => changeHandler(e)}
              />
            </div>
            <div className="d-flex flex-column mb-4">
              <label
                htmlFor={`facebook`}
                className="formLabel"
              >{`facebook`}</label>
              <input
                id={`facebook`}
                name="facebook"
                onChange={(e) => changeHandler(e)}
                type="text"
                value={model?.facebook ?? ""}
              />
              <div className="text-danger validateToDelete">
                {errors && errors[`facebook`]}
              </div>
            </div>
            <div className="d-flex flex-column mb-4">
              <label
                htmlFor={`twitter`}
                className="formLabel"
              >{`twitter`}</label>
              <input
                id={`twitter`}
                name={`twitter`}
                onChange={(e) => changeHandler(e)}
                type="text"
                value={model?.twitter ?? ""}
              />
              <div className="text-danger validateToDelete">
                {errors && errors[`twitter`]}
              </div>
            </div>
            <div className="d-flex flex-column mb-4">
              <label
                htmlFor={`instagram`}
                className="formLabel"
              >{`instagram`}</label>
              <input
                id={`instagram`}
                name={`instagram`}
                onChange={(e) => changeHandler(e)}
                type="text"
                value={model?.instagram ?? ""}
              />
              <div className="text-danger validateToDelete">
                {errors && errors[`instagram`]}
              </div>
            </div>

            <div className="d-flex flex-column mb-4">
              <label
                htmlFor={`appStore`}
                className="formLabel"
              >{`youtube`}</label>
              <input
                id={`appStore`}
                name={`youtube`}
                onChange={(e) => changeHandler(e)}
                type="text"
                value={model?.youtube ?? ""}
              />
              <div className="text-danger validateToDelete">
                {errors && errors[`youtube`]}
              </div>
            </div>
            <div className="d-flex flex-column mb-4">
              <label
                htmlFor={`appGallery`}
                className="formLabel"
              >{`linkedin`}</label>
              <input
                id={`appGallery`}
                name={`linkedin`}
                onChange={(e) => changeHandler(e)}
                type="text"
                value={model?.linkedin ?? ""}
              />
              <div className="text-danger validateToDelete">
                {errors && errors[`linkedin`]}
              </div>
            </div>

            {/*** address ***/}
            <div className="d-flex flex-column">
              {supportedLanguages.map((language, index) => {
                return (
                  <div key={index} className="d-flex flex-column mb-4">
                    <label
                      htmlFor={`address${language.slug}`}
                      className="formLabel"
                    >{`address in ${language.name}`}</label>
                    <textarea
                      maxLength={500}
                      name="address"
                      id={`address${language.slug}`}
                      type="text"
                      value={
                        model && model?.address
                          ? model?.address[language.slug]?.description
                          : ""
                      }
                      onChange={(e) => changeHandler(e, language.slug)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {!loader ? (
            <div>
              <button className="confirmBtn" type="submit">
                save changes
              </button>
            </div>
          ) : (
            <button className="confirmBtn" disabled>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              saving...
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
