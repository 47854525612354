import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import { deleteAdmin, getAdmins } from '../../Apis/admins';
import { basename } from '../../Configurations/config';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../Utilities/deleteModal';
import '../tables.scss'
import PermissionsContext from '../../Contexts/permissionsContext';

export default function AdminsList() {
    let { Permissions } = useContext(PermissionsContext);
    const { pathname } = useLocation();
    let { changeTitle } = useContext(TitleContext);
    const [allAdmins, setAllAdmins] = useState([]);
    const [adminId, setAdminId] = useState('');
    const [deletedName, setDeletedName] = useState('section');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    // get all admins
    function getAllAdmins() {
        let myallAdmins = [...allAdmins];
        getAdmins().then(res => {
            myallAdmins = res.data.data;
            console.log(myallAdmins);
            setAllAdmins(myallAdmins);
        }).catch(err =>
            console.log(err)
        )
    }

    useEffect(() => {
        changeTitle('admins', { url: 'admins/create', text: 'add admin' ,canAdd:Permissions?.admin?.create});
        getAllAdmins();
        // eslint-disable-next-line
    }, []);

    // delete admin
    const onSubmitDelete = async () => {
        return await deleteAdmin(adminId).then(res => {
            getAllAdmins();
            return 'done';
        }).catch(err => {
            return err.response.data.message;;
        })
    }

    function clickDelete(item) {
        setAdminId(item.id)
        setDeletedName(item?.username)
    }
    return (
        <>
            {Permissions?.admin?.view &&
                <section>
                    <div style={{ "borderRadius": "8px", "border": "1px solid #DFE0EB" }} className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>{'username'}</th>
                                    <th>{'name'}</th>
                                    <th>{'email'}</th>
                                    <th>{'role'}</th>
                                    <th>{'actions'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allAdmins && allAdmins.map((admin, index) => (
                                    <tr key={index}>
                                        <td>{admin.username}</td>
                                        <td style={{ "width": "22%" }}>{admin.name}</td>
                                        <td style={{ "width": "18%" }}>{admin.email}</td>
                                        <td style={{ "width": "15%" }}>
                                            <div className={`adminRoleLabel p-2`}>
                                                {admin?.role ? admin.role : 'no role'}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {Permissions?.admin?.edit &&
                                                    <Tooltip title={'edit'}>
                                                        <Link to={`${basename}/dashboard/admins/${admin.id}/update`}>
                                                            <img src={edit_icon} alt="edit" />
                                                        </Link>
                                                    </Tooltip>
                                                }
                                                {Permissions?.admin?.delete &&
                                                    <Tooltip title={'delete'}>
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => clickDelete(admin)} />
                                                    </Tooltip>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name={deletedName}></DeleteModal>
                    </div>
                </section>
            }
        </>
    )
}
