import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function MessageModal({ description, context }) {
    let user1 = context?.[0]?.from_user?.username ?? context?.[0]?.user.username
    console.log(context);
    return (
        <div className="modal fade" id="readMsgModel" tabIndex={-1} aria-labelledby="toggleblockIpModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header ">
                        <div className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                    <div className="modal-body my-2 mt-0">
                        {!context ?
                            <form className='w-100 m-0'>
                                <label>the message content </label>
                                <textarea style={{ height: '11.25rem' }} className='w-100' value={description} type='text' readOnly />
                            </form>
                            :
                            <div className='d-flex flex-column border rounded p-3' style={{ maxHeight: '70vh', overflow: 'auto' }}>
                                <p className='text-danger w-100' style={{ fontSize: '14px'}}>{description}</p>
                                <hr/>
                                {context?.map((msg) => {
                                    return (
                                        <div key={msg?.id}
                                            style={{
                                                color: '#fff', background: (msg?.from_user?.username ?? msg?.user?.username) === user1 ? '#000' : '#18df',
                                                margin: '5px', padding: '10px', borderRadius: '10px',
                                                alignSelf: (msg?.from_user?.username ?? msg?.user?.username) === user1 ? 'flex-start' : 'flex-end',
                                                width: 'fit-content',
                                                maxWidth: '80%'
                                            }}>
                                            <p style={{ fontSize: '11px', background: (msg?.from_user?.username ?? msg?.user?.username) === user1 ? '#000' : '#18df' }}>
                                                {(msg?.from_user?.username ?? msg?.user?.username)}
                                            </p>
                                            {msg?.message}
                                        </div>
                                    )
                                })}
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}