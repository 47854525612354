import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateSubSection } from '../../../Apis/subSections';
import { useContext } from 'react';
import TitleContext from '../../../Contexts/TitleContext';
import { useEffect } from 'react';
import { getSection } from '../../../Apis/sections';

export default function UpdateSubSections() {
  const { sub_id ,id } = useParams();
  const { changeTitle } = useContext(TitleContext);

  useEffect(()=>{
    id && getSection(id).then((res)=>{
      console.log(res.data.data)
      changeTitle(`update subsection at ${res.data.data?.locales?.en?.name}`);
    })
  },[id])

    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateSubSection(sub_id,data).then(res => {
            return 'done';          
        }).catch(err => {
            return err.response.data.errors;
        })
    };

  return (
    <div>
        <Form onSubmit={onSubmit} />
    </div>
  )
}
