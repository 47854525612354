import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getGuideArticles(obj){
    return await https.get(`${api_url}dashboard/articles?${obj}`);
}

export async function getGuideArticle(id){
    return await https.get(`${api_url}dashboard/articles/${id}`);
}

export async function deleteGuideArticle(id){
    return await https.delete(`${api_url}dashboard/articles/${id}`);
}

export async function updateGuideArticle(id, data){
    return await https.put(`${api_url}dashboard/articles/${id}`, data);
}

export async function createGuideArticle(data){
    return await https.post(`${api_url}dashboard/articles`, data);
}

export async function toggleStatusGuideArticle(id){
    return await https.patch(`${api_url}dashboard/articles/toggle-active/${id}`);
}