export const setRoleData = (model, setModel, ele, property, locale = false, language = null) => {
    let newData = Object.assign({}, model);
    if (locale) {
        newData.locales[language] = {}
        newData.locales[language][property] = ele.target.value;
    } else {
        if (ele.target.checked) {
            newData.permissions.push(Number(ele.target.value));
        } else {
            let x = newData.permissions.indexOf(Number(ele.target.value));
            newData.permissions.splice(x, 1)
        }
    }
    setModel(newData);
}