import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import search from "../../assets/icons/search.svg";
import delete_icon from "../../assets/icons/delete.svg";
import details from "../../assets/icons/details.svg";
// import noImg from '../../assets/images/noImg.jpg';
import "../tables.scss";
import "../forms.scss";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import {
  acceptProject,
  deleteProject,
  getProjects,
  rejectProject,
} from "../../Apis/projects";
import DeleteModal from "../../Utilities/deleteModal";
import moment from "moment";
import { basename } from "../../Configurations/config";
import TitleContext from "../../Contexts/TitleContext";
import acceptIcon from "../../assets/icons/accept.svg";
import rejectIcon from "../../assets/icons/reject.svg";
/* import PermissionsContext from '../../Contexts/permissionsContext'; */
import RejectModal from "./../../Utilities/rejectModal";
import PermissionsContext from "../../Contexts/permissionsContext";
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import Loader from '../../Components/Loader/Loader'

export default function Projects() {
  const [allProjects, setallProjects] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const { pathname } = useLocation();
  const location = useLocation();
  const { changeTitle } = useContext(TitleContext);
  let [searchParams] = useSearchParams();
  // const { status } = useParams();
  const [status, setStatus] = useState(Number(searchParams.get("status")) ?? 1);

  const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: "", status: status, stage: "1,2,3,4"});
  const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
  const [deleted, setdeleted] = useState("");
  const [deletedName, setDeletedName] = useState("project");
  const { Permissions } = useContext(PermissionsContext);
  const [projectsCount, setProjectsCount] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
    changeTitle(`Projects`);
  }, [pathname]);

  useEffect(() => {
      setStatus(Number(searchParams.get("status")));
    if (searchParams.get("status") === "closed") {
      setStatus("closed");
      setfilterObj({ ...filterObj, stage: 5, status: "" });
    } else {
      setStatus(Number(searchParams.get("status")));
      setfilterObj({...filterObj, status: Number(searchParams.get("status")), stage: "1,2,3,4"});
    }
    
  }, [location]);
  
  function getallProjects(obj) {
    setLoading(true)
    let filter = { ...obj };
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}&stage=${filter.stage}`;
    getProjects(params).then((res) => {
      setallProjects(res.data.data);
      let total = res?.data?.overview?.total;
      changeTitle(`Projects (${total})`);
      setPageCount(Math.ceil(total / filter.per_page));
      setProjectsCount(res?.data?.overview);
      setLoading(false)
    });
  }

  useEffect(() => {
    getallProjects(filterObj);
    // eslint-disable-next-line
  }, [status]);

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 };
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj };
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;
    setfilterObj(thisObj);
    getallProjects(thisObj);
  }

  function changeStatus(status) {
    let thisObj = { ...filterObj };
    if (status === "closed") {
      thisObj.status = "";
      thisObj.stage = 5;
    } else {
      thisObj.status = status;
      thisObj.stage = "1,2,3,4";
    }
    thisObj.page = 1;
    setfilterObj(thisObj);
    getallProjects(thisObj);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = thisObj.per_page;
    thisObj2.search = thisObj.search;
    setfilterObj2(thisObj2);

    navigate(`${basename}/dashboard/projects?status=${status}`);
    setStatus(status);
  }

  async function onSubmitDelete() {
    return await deleteProject(deleted)
      .then((res) => {
        getallProjects(filterObj);
        return "done";
      })
      .catch((err) => {
        //console.log(err.response)
        return err.response.data.message;
      });
  }

  const handlepageChange = async (data) => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    getallProjects(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    setfilterObj2(thisObj2);
  };

  function acceptProjectHandler(id) {
    acceptProject(id)
      .then((res) => {
        getallProjects(filterObj);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  function clickDelete(item, handler) {
    if (handler === "delete") {
      setdeleted(item.id);
      setDeletedName(item?.title.substring(0, 25));
    } else {
      setOpen(true);
      setdeleted(item.id);
      setDeletedName(item?.title.substring(0, 25));
    }
  }

  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});

  async function rejectProjectHandler() {
    return await rejectProject(deleted, { reason: reason })
      .then((res) => {
        getallProjects(filterObj);
        return "done";
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
  return (
    <>
      {
        <section className="componentGlobalWrapper d-flex flex-column">
          <div>
            <div
              className="tabs-container nav nav-tabs d-flex align-items-center"
              id="myTab"
              role="tablist"
            >
              <div
                onClick={() => changeStatus(1)}
                className={`tablinks ${status === 1 && `active`}`}
                id="Gallery-tab"
                data-bs-toggle="tab"
                data-bs-target="#allnews"
              >
                active{" "}
                <span className="tabcounter">({projectsCount["active"]})</span>
              </div>
              <span className="separator"></span>

              <div
                onClick={() => changeStatus(2)}
                className={`tablinks ${status === 2 && `active`}`}
                id="published-tab"
                data-bs-toggle="tab"
                data-bs-target="#published-Img"
              >
                Pending{" "}
                <span className="tabcounter">
                  {" "}
                  ({projectsCount["pending"]}){" "}
                </span>
              </div>
              <span className="separator"></span>

              <div
                onClick={() => changeStatus(4)}
                className={`tablinks ${status === 4 && `active`}`}
                id="need-tab"
                data-bs-toggle="tab"
                data-bs-target="#need-Img"
              >
                need actions{" "}
                <span className="tabcounter">
                  {" "}
                  ({projectsCount["need_action"]}){" "}
                </span>
              </div>
              <span className="separator"></span>

              <div
                onClick={() => changeStatus("closed")}
                className={`tablinks ${status === "closed" && `active`}`}
                id="published-tab"
                data-bs-toggle="tab"
                data-bs-target="#published-Img"
              >
                closed{" "}
                <span className="tabcounter">
                  {" "}
                  ({projectsCount["closed"]}){" "}
                </span>
              </div>

              <div
                onClick={() => changeStatus(3)}
                className={`tablinks ${status === 3 && `active`}`}
                id="published-tab"
                data-bs-toggle="tab"
                data-bs-target="#published-Img"
              >
                rejected{" "}
                <span className="tabcounter">
                  {" "}
                  ({projectsCount["rejected"]}){" "}
                </span>
              </div>
            </div>
            <div className="searchActionsBar w-100">
              <div className="searchActionsBar mt-0 mb-0">
                {/*** search input ***/}
                <div className="searchInputWrapper">
                  <div>
                    <input
                      type="text"
                      className="barSearchInput"
                      name="search"
                      onChange={changeFilterObj}
                    />
                  </div>
                  <img
                    src={search}
                    alt="search-icon"
                    className="barSearchIcon"
                  />
                </div>
              </div>

              {/* show select */}
              <div className="perPageContainer">
                <label htmlFor="selectShow" className="perPageLabel">
                  show
                </label>
                <select
                  name="per_page"
                  onChange={changeFilterObj}
                  id={`selectShow`}
                  className="form-select selectShow"
                  value={filterObj2?.per_page}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
              {/*** apply search btn  ***/}
              <button className="applySearchBtn" onClick={getFiltered}>
                search
              </button>
            </div>
            {Permissions?.project?.view && (
              <div className="tableWrapper">
                <table className="w-100 table table-striped serviceTable">
                  <thead>
                    <tr>
                      {/* <th>image</th> */}
                      <th>title</th>
                      <th>user</th>
                      {filterObj?.stage === 5 || filterObj?.status === 3 ? (
                        <th>reason</th>
                      ) : (
                        ""
                      )}
                      <th>created at</th>
                      <th>actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    loading? <td className='w-100' colSpan={6}><Loader/></td>
                    :allProjects && allProjects?.length !== 0 ?
                      allProjects.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td className='sliderImg'>
                                                    <img src={`${item?.media !== null ? item?.media[0]?.url : noImg}`} alt="slider" />
                                                </td> */}
                            <td>
                              {item?.title ? item?.title : "no data added"}
                            </td>
                            <td> {item?.user?.username ?? "no data added"}</td>
                            {(item?.stage?.id === 5 ||
                              item?.status?.id === 3) && (
                              <td>
                                {item?.stage?.id === 5
                                  ? item?.close_reasons?.message
                                  : item?.status?.id === 3
                                  ? item?.reject_reasons?.message
                                  : "no data added"}
                              </td>
                            )}
                            <td>
                              {item?.created_at
                                ? moment(item?.created_at).format("DD-MM-YYYY")
                                : "no data added"}
                            </td>
                            <td>
                              <div className="d-flex">
                                <Tooltip title="details">
                                  <Link
                                    to={`${basename}/dashboard/projects/${item.id}`}
                                  >
                                    <img
                                      className="iconActions"
                                      src={details}
                                      alt="details"
                                    />
                                  </Link>
                                </Tooltip>

                                {(item?.status?.id === 2 ||
                                  item?.status?.id === 4) && (
                                  <>
                                    {Permissions?.project?.accept && (
                                      <Tooltip title="accept">
                                        <div
                                          className="d-flex"
                                          onClick={() =>
                                            acceptProjectHandler(item.id)
                                          }
                                        >
                                          <img
                                            className="iconActions"
                                            src={acceptIcon}
                                            alt="accept"
                                          />
                                        </div>
                                      </Tooltip>
                                    )}
                                    {Permissions?.project?.reject && (
                                      <Tooltip title="reject">
                                        <div
                                          className="d-flex"
                                          onClick={() =>
                                            clickDelete(item, "reject")
                                          }
                                        >
                                          <img
                                            className="iconActions"
                                            src={rejectIcon}
                                            alt="accept"
                                          />
                                        </div>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                                {item?.status?.id === 3 && (
                                  <Tooltip title="delete">
                                    <img
                                      data-bs-toggle="modal"
                                      onClick={() =>
                                        clickDelete(item, "delete")
                                      }
                                      data-bs-target="#deleteModal"
                                      className="iconActions"
                                      src={delete_icon}
                                      alt="edit"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      }):<td className='w-100' colSpan={6}><NotDataFound /></td>}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end mt-auto">
            <Pagination
              pagesNumber={pageCount}
              page={filterObj.page - 1}
              pageChangeHandler={handlepageChange}
            />
          </div>
          <DeleteModal
            onSubmitDelete={onSubmitDelete}
            name={deletedName}
          ></DeleteModal>
          <RejectModal
            open={open}
            setOpen={setOpen}
            title={deletedName}
            onSubmitReject={rejectProjectHandler}
            reason={reason}
            setReason={setReason}
            errors={errors}
            setErrors={setErrors}
          />
        </section>
      }
    </>
  );
}
