import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setData } from './formHandler';
import { supportedLanguages } from './../../../Utilities/SupportedLanguages';
import { getGuideArticle } from '../../../Apis/guideArticles';
// import { preventEnter } from '../../../Utilities/preventEnter';
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, ContentState, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { api_url } from '../../../Configurations/config';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import removeIcon from "../../../assets/icons/removeIcon.svg";
import uploadImage from "../../../assets/icons/uploadimage.svg";

export default function Form(props) {
    const { pathname } = useLocation();
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id, article_id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    // const [editors, setEditors] = useState({});
    const [stateImg, setStateImg] = useState();
    const [changedImg, setchangedImg] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);



    useEffect(() => {
        if (article_id) {
            console.log(article_id)
            getGuideArticle(article_id).then((res) => {
                let x = res.data.data.article;
                console.log(x)
                setStateImg(x?.media?.url)
                setchangedImg(x?.media?.id)
                let obj = { ...model }
                obj = x
                obj.guide_section = x?.guide_section?.id;
                obj.media = x?.media?.id
                setModel(obj);

                // let thisEditors = { ...editors };
                // supportedLanguages.map((language) => {
                //     let lang = x.locales[language.slug]
                //     //set values at editor state
                //     const contentDataState = ContentState.createFromBlockArray(htmlToDraft(`${lang.description}`));
                //     const editorDataState = EditorState.createWithContent(contentDataState);
                //     thisEditors[language.slug] = editorDataState;
                //     return true
                // })
                // setEditors(thisEditors);
            })
        } else {
            setModel({
                locales: {},
                media: "",
                guide_section: id,
                reading_minutes: '',
                publisher_name: ""
            });
        }
        //eslint-disable-next-line
    }, [article_id]);

    // const convertContentToHTML = (state) => {
    //     return draftToHtml(convertToRaw(state.getCurrentContent()));
    // }
    // const handleEditorChange = (state, lang) => {
    //     let thisEditors = { ...editors }
    //     if (!thisEditors[lang]) {
    //         thisEditors[lang] = EditorState.createEmpty()
    //     }
    //     thisEditors[lang] = state;
    //     setEditors(thisEditors);

    //     let newData = Object.assign({}, model);
    //     if (newData.locales[lang]) {
    //         newData.locales[lang]['description'] = convertContentToHTML(state);
    //     } else {
    //         newData.locales[lang] = {};
    //         newData.locales[lang]['description'] = convertContentToHTML(state);
    //     }
    //     setModel(newData)

    //     let err = { ...errors };
    //     err[`locales.${lang}.description`] = '';
    //     err[`locales`] = ''
    //     setErrors(err)
    // }

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setData(model, setModel, e, property, locale, language);
        console.log(model)
        let err = { ...errors };
        if (locale) {
            err[`locales.${language}.${property}`] = '';
            err[`locales`] = ''
        } else {
            err[property] = '';
        }
        setErrors(err)
    }

    /* upload image */
    /* **************************** IMAGES ************************** */
    const [progress, setProgress] = useState(0);
    const token = `Bearer ` + localStorage.getItem("need4_Admin_Token");

    function upload(e) {
        const formData = new FormData();
        formData.append("media[]", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }
        let err = { ...errors };
        err['media'] = '';
        setErrors(err)
        axios.post(`${api_url}media`, formData, config).then((res) => {
            let obj = res.data.data;
            setStateImg(obj[0]?.url);
            let m = Object.assign({}, model);
            m['media'] = obj[0]?.id;
            setModel(m);
            setProgress(0);
        }).catch((err) => {
            console.log(err.response.data)
            let x = err.response.data.errors.media;
            let e = { ...errors };
            e['media'] = x;
            setErrors(e)
            setProgress(0);
        })
    }

    function removeImgHandler() {
        let m = Object.assign({}, model);
        m['media'] = '';
        setModel(m)
        setStateImg('');
    }


    // onsubmit
    const confirmHandler = async (e, model) => {
        e.preventDefault();
        // setLoading(true);
        console.log(model)
        if(id && changedImg ==  model.media){
            return (delete model.media)
        }
        console.log(model)
        let submitProps = await props.onSubmit(e, model);
        
        if (submitProps === "done") {
            setLoading(false);
            navigate(-1);
            if (!article_id) {
                setModel(formModel);
            }
        } else {
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }

    return (
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{article_id ? `update Guide article` : `add new Guide article`}</h5>
            </div>
            <form className='formWidth' id='adminForm' onSubmit={(e) => confirmHandler(e, model)}>
                {errors[`locales`] && <div className='alert alert-danger w-100'>
                    {errors[`locales`]}
                </div>}
                {errors[`error`] && <div className='alert alert-danger w-100'>
                    {errors[`error`]}
                </div>}
                <div className='d-flex justify-content-start'>
                    <div className="w-100">
                        {supportedLanguages.map((language) => {
                            return (
                                <div key={language.slug} >
                                    <div className='d-flex flex-column' >
                                        <label htmlFor={`name_${language.slug}`} className='formLabel'>title ({language.name})</label>
                                        <input name="title" id={`name_${language.slug}`} type='text' placeholder='title' value={model?.locales ? model?.locales[language.slug]?.name : " "} onChange={(e) => setFormData(e, 'name', true, language.slug)} minLength={3} maxLength={255} />
                                        <div className='text-danger validateToDelete'>
                                            {errors[`locales.${language.slug}.name`]}
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column my-4'>
                                        <label htmlFor='content' className='formLabel'>{`description (${language.name})`}</label>
                                        <div {...(language.slug === 'ar' ? { 'dir': 'rtl' } : {})}>
                                            {/* <Editor editorState={model && editors[language.slug]} onEditorStateChange={(state) => handleEditorChange(state, language.slug)} /> */}
                                            <textarea className='textArea' name='description' value={model?.locales ? model?.locales[language.slug]?.description : " "} onChange={(e) => setFormData(e, 'description', true, language.slug)} />
                                        </div>
                                        <div className='text-danger validateToDelete'>
                                            {errors[`locales.${language.slug}.description`]}
                                        </div>
                                    </div>
                                </div>)
                        })}
                    </div>
                </div>

                <div className='d-flex mb-4'>
                    <div className='d-flex flex-column w-100 marginEnd' >
                        <label htmlFor={`reading_minutes`} className='formLabel'>reading time (minutes) </label>
                        <input name="reading_minutes" id={`reading_minutes`} type='number' placeholder='reading time' value={model?.reading_minutes} onChange={(e) => setFormData(e, 'reading_minutes')} />
                        <div className='text-danger validateToDelete'>
                            {errors[`reading_minutes`]}
                        </div>
                    </div>
                    <div className='d-flex flex-column w-100 marginStart' >
                        <label htmlFor={`publisher_name`} className='formLabel'>publisher name</label>
                        <input name="publisher_name" id={`publisher_name`} type='text' placeholder='publisher name' value={model?.publisher_name} onChange={(e) => setFormData(e, 'publisher_name')} />
                        <div className='text-danger validateToDelete'>
                            {errors[`publisher_name`]}
                        </div>
                    </div>
                </div>

                {/** admin image **/}
                <div className="row m-0 mb-2 d-flex align-items-center mt-2">
                    <label className='formLabel p-0 mb-0'>image </label>
                    {stateImg ?
                        <div className='globalImageWrapper position-relative p-1 mt-2'>
                            <Tooltip title='Remove Image'>
                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                    <img src={removeIcon} alt="delete" onClick={() => removeImgHandler()} />
                                </label>
                            </Tooltip>
                            <div className='globalPreviewedImage' style={{ backgroundImage: `url(${stateImg})` }}></div>
                        </div>
                        :
                        <div className='px-0'>
                            <div className='imageWrapper p-0 mt-2' style={{ "width": "180px", "height": "140px" }}>
                                <label htmlFor='propertyImg' className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                                    <img width='70px' height='50px' src={uploadImage} alt='upload' />
                                    <input onChange={upload} name="image" accept=".jpg, .jpeg, .png ,.webp" type="file" className={`d-none`} id="propertyImg" />
                                    <span> upload image </span>
                                </label>
                            </div>
                        </div>
                    }
                </div>

                {progress !== 0 && (
                    <div className="progress mt-2" style={{ "width": "11.25rem" }} >
                        <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                            aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                            {progress}%
                        </div>
                    </div>
                )}

                {errors?.media &&
                    <div className='text-danger validateToDelete'>
                        {errors?.media}
                    </div>
                }

                {!loading ?
                    <button className='confirmBtn mt-5' type='submit'>{article_id ? `save changes` : `add article`}</button>
                    :
                    <button className='confirmBtn mt-5' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
        </section>
    )
}
