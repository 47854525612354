import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { style } from '../SuspendModal/suspend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export default function ConfirmModal({ open, setOpen, action, submitFunction }) {
 

    const [errors, setErrors] = useState()
    const handleClose = () => setOpen(false);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box className='w-50 border-0 rounded' sx={style}>
                    <Typography className='d-flex justify-content-between text-center' id="transition-modal-title" variant="h6" component="h2">
                        <b>{`Confirm ${action}`}  </b>
                        <div className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </Typography>
                    <div className='m-2'>
                        <div>
                            <div className='text-danger errorText'>{errors?.message}</div>
                            <div className='text-danger errorText'>{errors?.error}</div>
                        </div>
                        <div className='d-flex justify-content-center w-100'>
                            <button onClick={submitFunction} className='btn btn-success w-25 m-2'>Confirm</button>
                            <button onClick={handleClose} className='btn btn-dark text-light w-25 m-2'>Discard</button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}