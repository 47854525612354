import React from 'react'
import { createSpamOption } from '../../../../Apis/spams';
import Form from './Form';

export default function CreateSpamOptions() {
  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createSpamOption(data).then(res => {
      return 'done';          
    }).catch((err) =>{
      console.log(err.response);
      if(err.response.status === 422){
        return err.response.data.errors; 
      }else if(err.response.status === 403){
        let obj = {error: 'action is not allowed , error 403'}
        return obj;
      } }
    )}

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
