import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setData } from './formHandler';
import { getCoupon } from '../../../Apis/coupons';
import TitleContext from '../../../Contexts/TitleContext';
import { preventEnter } from '../../../Utilities/preventEnter';
import { basename } from './../../../Configurations/config';

export default function Form(props) {
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        changeTitle(``, '', { url: "coupons" });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(model);
        // eslint-disable-next-line
    }, [model]);

    useEffect(() => {
        if (id) {
            getCoupon(id).then((res) => {
                let x = res.data.data;
                setModel({
                    is_percentage: x?.is_percentage,
                    code: x?.code,
                    value: x?.value
                });
            })
        } else {
            setModel({
                is_percentage: "",
                code: "",
                value: ""
            });
        }
    }, [id]);

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setData(model, setModel, e, property, locale, language);
        let err = { ...errors };
        err[property] = '';
        setErrors(err)
    }

    // onsubmit
    const confirmCoupon = async (e, model) => {
        e.preventDefault();
        setLoading(true);
        console.log(model)
        let submitProps = await props.onSubmit(model);
        if (submitProps === "done") {
            setLoading(false);
            navigate(`${basename}/dashboard/coupons`);
            if (!id) {
                setModel({});
            }
        } else {
            console.log(submitProps);
            setLoading(false);
            setErrors(submitProps);
        }
    }

    return (
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{id ? `update Coupon` : `add new Coupon`}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirmCoupon(e, model)} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-start'>
                    <div className="w-100">
                        <div style={{ "width": "50%" }}>
                            <div className='d-flex flex-column mb-4 w-100'>
                                <label htmlFor={`code`} className='formLabel'>code</label>
                                <input name="code " id={`code`} type='text' placeholder='code' value={model?.code} onChange={(e) => setFormData(e, 'code')} required />
                                <div className='text-danger validateToDelete'>
                                    {errors[`code`]}
                                </div>
                            </div>
                            <div className='d-flex flex-column mb-4 w-100'>
                                <label htmlFor={`value`} className='formLabel'>value</label>
                                <input name="value " id={`value`} type='number' placeholder='value' value={model?.value} onChange={(e) => setFormData(e, 'value')} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} min={0} required />
                                <div className='text-danger validateToDelete'>
                                    {errors[`value`]}
                                </div>
                            </div>
                            <div className='d-flex flex-column mb-4 w-100'>
                                <label htmlFor={`is_percentage`} className='formLabel'>type of value</label>
                                <div className='selectParent'>
                                    <select name="is_percentage " id={`is_percentage`} placeholder='select type of value' value={model?.is_percentage} onChange={(e) => setFormData(e, 'is_percentage')} required >
                                        <option value={''} disabled>select type of value</option>
                                        <option value={1}>percentage</option>
                                        <option value={0}>AED</option>
                                    </select>
                                </div>
                                <div className='text-danger validateToDelete'>
                                    {errors[`is_percentage`]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!loading ?
                    <button className='confirmBtn mt-5' type='submit'>{id ? `save changes` : `add Coupon`}</button>
                    :
                    <button className='confirmBtn mt-5' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
        </section>
    )
}
