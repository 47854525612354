import React from 'react'
import { createGuideArticle } from '../../../Apis/guideArticles';
import Form from './Form';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { getGuideSection } from '../../../Apis/guideSections';
import { useContext } from 'react';
import TitleContext from '../../../Contexts/TitleContext';

export default function CreateGuideArticle() {
  const { id } = useParams();
  const { changeTitle } = useContext(TitleContext);

  useEffect(()=>{
    id && getGuideSection(id).then((res)=>{
      console.log(res.data.data)
      changeTitle(`create article at ${res.data.data?.locales?.en?.name}`);
    })
  },[id])

  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createGuideArticle(data).then(res => {
      if (res.status === 200) {
        console.log(res);
        return 'done';          
      }
    }).catch(err =>{ 
      console.log(err.response);
      if(err.response.status === 422){
        return err.response.data.errors; 
      }else if(err.response.status === 403){
        let obj = {error: 'action is not allowed , error 403'}
        return obj;
      }
    })
}

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
