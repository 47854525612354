import React from 'react'
import { createFaqSection } from '../../../Apis/faq';
import Form from './Form';

export default function CreateFaqSection() {
  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createFaqSection(data).then(res => {
      return 'done';          
    }).catch(err =>{ return err.response.data.errors; })
}

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  )
}
