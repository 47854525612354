import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { getUsers } from "../../Apis/user";
import { basename } from "../../Configurations/config";
import PermissionsContext from "../../Contexts/permissionsContext";
import TitleContext from "../../Contexts/TitleContext";
import details from "../../assets/icons/details.svg";
import search from "../../assets/icons/search.svg";
import noImg from "../../assets/images/noImg.jpg";
import Pagination from "../../Components/Pagination/Pagination";
import Loader from "../../Components/Loader/Loader";
import NotDataFound from '../../Components/NotDataFound/notDataFound';

export default function UserList() {
	const [allusers, setAllusers] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const location = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		search: "",
		identityVerified: "",
	});
	let [searchParams] = useSearchParams();
	const [identityVerified, setIdentityVerified] = useState(
		Number(searchParams.get("identity_verified")) ?? 1
	);
	const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
	const { Permissions } = useContext(PermissionsContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		changeTitle(`Users`);
		// eslint-disable-next-line
	}, [pathname]);
	useEffect(() => {
		getAllUsers(filterObj);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setIdentityVerified(Number(searchParams.get("identity_verified")));
		// eslint-disable-next-line
	}, [location]);

	function getAllUsers(obj) {
		setLoading(true);
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&identity_verified=${filter.identityVerified}`;
		getUsers(params).then((res) => {
			setAllusers(res.data.data);
			let total = res.data?.pagination?.total;
			changeTitle(`Users (${total})`, {
				url: `${basename}/dashboard/users/create`,
				text: "add User",
                // canAdd: Permissions?.user?.create,
                canAdd: false,
			});
			setPageCount(Math.ceil(total / filter.per_page));
			setLoading(false);
		});
	}

	// useEffect(() => {
	// 	console.log("Log");
	// 	// const getUsersInterval=setTimeout(() => {
	// 		getAllUsers(filterObj);
	// 	// },3000);
	// 	// return()=>{
	// 	// 	clearTimeout(getUsersInterval)
	// 	// }
	// 	// eslint-disable-next-line
	// }, [identityVerified]);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		setfilterObj(thisObj);
		getAllUsers(thisObj);
	}

	// function clickDelete(section) {
	//     setDeletedSection(section.id)
	//     setDeletedName(section?.username)
	// }

	function changeStatus(identityVerified) {
		let thisObj = { ...filterObj };
		thisObj.identityVerified = identityVerified;
		thisObj.page = 1;
		setfilterObj(thisObj);
		getAllUsers(thisObj);
		setIdentityVerified(identityVerified);
		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = thisObj.per_page;
		thisObj2.search = thisObj.search;
		setfilterObj2(thisObj2);
		navigate(
			`${basename}/dashboard/users?identity_verified=${identityVerified}`
		);
		if (
			identityVerified !== 0 &&
			identityVerified !== 1 &&
			identityVerified !== 2 &&
			identityVerified !== 3
		)
			navigate(`${basename}/dashboard/users`);
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getAllUsers(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		setfilterObj2(thisObj2);
	};

	return (
		<section className="componentGlobalWrapper d-flex flex-column">
			<div>
				<div
					className="tabs-container nav nav-tabs d-flex align-items-center"
					id="myTab"
					role="tablist"
				>
					<div
						onClick={() => changeStatus("")}
						className={`tablinks active`}
						id="all-tab"
						data-bs-toggle="tab"
						data-bs-target="#all"
					>
						All{" "}
						<span className="tabcounter">
							{/* ({usersCount["authorized"]}) */}
						</span>
					</div>
					<span className="separator"></span>
					<div
						onClick={() => changeStatus(1)}
						className={`tablinks ${identityVerified === 1 && `active`}`}
						id="Gallery-tab"
						data-bs-toggle="tab"
						data-bs-target="#allnews"
					>
						authorized{" "}
						<span className="tabcounter">
							{/* ({usersCount["authorized"]}) */}
						</span>
					</div>
					<span className="separator"></span>

					<div
						onClick={() => changeStatus(2)}
						className={`tablinks ${identityVerified === 2 && `active`}`}
						id="need-tab"
						data-bs-toggle="tab"
						data-bs-target="#need-Img"
					>
						need actions{" "}
						<span className="tabcounter">
							{/* ({usersCount["need_actions"]}) */}
						</span>
					</div>
					<span className="separator"></span>

					<div
						onClick={() => changeStatus(3)}
						className={`tablinks ${identityVerified === 3 && `active`}`}
						id="published-tab"
						data-bs-toggle="tab"
						data-bs-target="#published-Img"
					>
						rejected{" "}
						<span className="tabcounter">
							{/* ({usersCount["rejected"]}) */}
						</span>
					</div>
				</div>
				<div className="searchActionsBar w-100">
					<div className="searchActionsBar mt-0 mb-0">
						{/*** search input ***/}
						<div className="searchInputWrapper">
							<div>
								<input
									type="text"
									className="barSearchInput"
									name="search"
									onChange={changeFilterObj}
								/>
							</div>
							<img src={search} alt="search-icon" className="barSearchIcon" />
						</div>
					</div>

					{/* show select */}
					<div className="perPageContainer">
						<label htmlFor="selectShow" className="perPageLabel">
							show
						</label>
						<select
							name="per_page"
							onChange={changeFilterObj}
							id={`selectShow`}
							className="form-select selectShow"
							value={filterObj2?.per_page}
						>
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="15">15</option>
							<option value="20">20</option>
						</select>
					</div>
					{/*** apply search btn  ***/}
					<button className="applySearchBtn" onClick={getFiltered}>
						search
					</button>
				</div>

				{Permissions?.user?.view && (
					<div className="tableWrapper">
						<table className="w-100 table table-striped ">
							<thead>
								<tr>
									<th>image</th>
									<th>username</th>
									<th>skills</th>
									<th>bio</th>
									<th>actions</th>
								</tr>
							</thead>

							{loading ? (
								<tbody>
									<td className="w-100" colspan="5">
										<Loader />
									</td>
								</tbody>
							) : (
								<tbody>
									{allusers && allusers?.length != 0 ?
										allusers.map((user, index) => {
											// console.log("USER ::: ", user);
											return (
												<tr key={index}>
													<td className="sliderImg">
														<img
															src={`${
																user?.media !== null ? user?.media?.url : noImg
															}`}
															alt="slider"
														/>
													</td>
													<td>{user?.username ?? "no data added"}</td>
													<td>
														{user?.skills && user?.skills > 0
															? user?.skills?.map((skill, i) => {
																	return (
																		<span key={i}>
																			{" "}
																			{skill?.locales?.en?.name}{" "}
																			{i !== user?.skills.length - 1 && ","}{" "}
																		</span>
																	);
															  })
															: "no data added"}
													</td>
													<td>
														{user?.bio
															? user?.bio?.substring(0, 20)
															: "no data added"}
													</td>
													<td>
														<Tooltip title="details">
															<Link
																to={`${basename}/dashboard/users/${user.id}`}
															>
																<img
																	className="iconActions"
																	src={details}
																	alt="details"
																/>
															</Link>
														</Tooltip>
													</td>
												</tr>
											);
										}): <td className='w-100' colSpan={6}><NotDataFound /></td>}
								</tbody>
							)}
						</table>
					</div>
				)}
				{
					<div className="d-flex justify-content-end mt-auto">
						<Pagination
							pagesNumber={pageCount}
							page={filterObj.page - 1}
							pageChangeHandler={handlepageChange}
						/>
					</div>
				}
				{/* <DeleteModal onSubmitDelete={onSubmitDelete}  name={deletedName}  ></DeleteModal> */}
			</div>
		</section>
	);
}
