import React, { useRef } from 'react'
import Form from './Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { createBudget } from '../../../Apis/budgets';

export default function CreateBudget(props) {
  const closeCreateModalRef = useRef();
  const onSubmit = async (e, data) => {
    e.preventDefault();
    console.log(data);
    //to send request
    return await createBudget(data).then(res => {
      closeCreateModalRef.current.click();
      props.getNewBudgets(props.obj);
      return 'done';          
    }).catch(err =>{ return err.response.data.errors; })
}

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
          <div className="modal-header">
              <h5 className='pageSubtitle mb-0'>create new budget</h5>
              <div ref={closeCreateModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" >
                <FontAwesomeIcon icon={faXmark}/>
              </div>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit} id='create' />
          </div>
        </div>
    </div>
  )
}
