import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import "../tables.scss";
import "../forms.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import { deleteSubSection, getSubSections } from "../../Apis/subSections";
import DeleteModal from "../../Utilities/deleteModal";
import moment from "moment";
import { basename } from "./../../Configurations/config";
import TitleContext from "../../Contexts/TitleContext";
import { getSection } from "../../Apis/sections";
import PermissionsContext from "../../Contexts/permissionsContext";

export default function SubSections() {
	const { id } = useParams();
	const [allSubSections, setAllSubSections] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		search: "",
		section: id,
	});
	const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "" });
	const [deletedSubSection, setDeletedSubSection] = useState("");
	const [deletedName, setDeletedName] = useState("subsection");
	const { Permissions } = useContext(PermissionsContext);
	const [sectionName, setSectionName] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line
		changeTitle(`Subsections at`);
	}, [pathname]);

	function getAllSubSections(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&page=${filter.page}&section=${filter.section}&search=${filter?.search}`;
		console.log(params);
		getSubSections(params)
			.then((res) => {
				setAllSubSections(res.data.data);
				let total = res.data?.pagination?.total;
				getSection(id).then((res2) => {
					let name = res2.data.data.locales.en.name;
					setSectionName(name);
					changeTitle(`Subsections at ${name} (${total})`, {
						url: `${basename}/dashboard/sections/${id}/subsections/create`,
						text: "add Subsection",
						canAdd: Permissions?.subsection?.create,
					});
				});
				setPageCount(Math.ceil(total / filter.per_page));
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		getAllSubSections(filterObj);
		// eslint-disable-next-line
	}, [Permissions]);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		setfilterObj(thisObj);
		getAllSubSections(thisObj);
	}

	async function onSubmitDelete() {
		return await deleteSubSection(deletedSubSection)
			.then((res) => {
				getAllSubSections(filterObj);
				return "done";
			})
			.catch((err) => {
				console.log(err.response);
				return err.response.data.errors.error;
			});
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getAllSubSections(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		setfilterObj2(thisObj2);
	};

	function clickDelete(item) {
		setDeletedSubSection(item.id);
		setDeletedName(item?.locales?.en?.name.substring(0, 25));
	}

	return (
		<>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						<div className="searchActionsBar w-100">
							<div className="searchActionsBar mt-0 mb-0">
								{/*** search input ***/}
								<div className="searchInputWrapper">
									<div>
										<input
											type="text"
											className="barSearchInput"
											name="search"
											onChange={changeFilterObj}
										/>
									</div>
									<img
										src={search}
										alt="search-icon"
										className="barSearchIcon"
									/>
								</div>
							</div>

							{/* show select */}
							<div className="perPageContainer">
								<label htmlFor="selectShow" className="perPageLabel">
									show
								</label>
								<select
									name="per_page"
									onChange={changeFilterObj}
									id={`selectShow`}
									className="form-select selectShow"
									value={filterObj2?.per_page}
								>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
								</select>
							</div>
							{/*** apply search btn  ***/}
							<button className="applySearchBtn" onClick={getFiltered}>
								search
							</button>
						</div>
						{Permissions?.subsection?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>subsection name</th>
											<th>no. of projects</th>
											<th>no. of services</th>
											<th>section</th>
											<th>created at</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allSubSections &&
											allSubSections.map((Subsection, index) => {
												console.log(allSubSections);
												return (
													<tr key={index}>
														<td>
															{Subsection?.locales?.en?.name
																? Subsection?.locales?.en?.name
																: "no data added"}
														</td>
														<td>
															{Subsection?.projects_count
																? Subsection?.projects_count
																: "no data added"}
														</td>
														<td>
															{Subsection?.services_count
																? Subsection?.services_count
																: "no data added"}
														</td>
														<td>{sectionName ?? "no data added"}</td>
														<td>
															{Subsection?.created_at
																? moment(Subsection?.created_at).format(
																		"DD-MM-YYYY"
																  )
																: "no data added"}
														</td>
														<td>
															<div>
																{Permissions?.subsection?.edit && (
																	<Tooltip title="edit">
																		<Link
																			to={`${basename}/dashboard/sections/${id}/subsections/${Subsection.id}/update`}
																		>
																			<img
																				className="iconActions"
																				src={edit_icon}
																				alt="edit"
																			/>
																		</Link>
																	</Tooltip>
																)}
																{Permissions?.subsection?.delete && (
																	<Tooltip title="delete this Subsection">
																		<img
																			data-bs-toggle="modal"
																			onClick={() => clickDelete(Subsection)}
																			data-bs-target="#deleteModal"
																			className="iconActions"
																			src={delete_icon}
																			alt="edit"
																		/>
																	</Tooltip>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>
					{
						<div className="d-flex justify-content-end mt-auto">
							<Pagination
								pagesNumber={pageCount}
								page={filterObj.page - 1}
								pageChangeHandler={handlepageChange}
							/>
						</div>
					}
					<DeleteModal
						onSubmitDelete={onSubmitDelete}
						name={deletedName}
					></DeleteModal>
				</section>
			}
		</>
	);
}
