import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import search from "../../assets/icons/search.svg";
import details from "../../assets/icons/details.svg";
// import noImg from '../../assets/images/noImg.jpg';
import "../tables.scss";
import "../forms.scss";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import {
    acceptOrderCancelRequest,
    rejectOrderCancelRequest,
  getOrderCancelRequests,
} from "../../Apis/orders";
import moment from "moment";
import { basename } from "../../Configurations/config";
import TitleContext from "../../Contexts/TitleContext";
/* import PermissionsContext from '../../Contexts/permissionsContext'; */
import PermissionsContext from "../../Contexts/permissionsContext";
import NotDataFound from '../../Components/NotDataFound/notDataFound';
import Loader from '../../Components/Loader/Loader'
import acceptIcon from "../../assets/icons/accept.svg";
import rejectIcon from "../../assets/icons/reject.svg";
import { Badge } from "@mui/material";

export default function OrderCancelRequestCancelRequests() {
  const [allOrderCancelRequests, setallOrderCancelRequests] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const { pathname } = useLocation();
  const location = useLocation();
  const { changeTitle } = useContext(TitleContext);
  let [searchParams] = useSearchParams();
  // const { status } = useParams();
  const [status, setStatus] = useState('2,3');
  const [date, setDate] = useState('');

  const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: "", stats: ''});
  const [filterObj2, setfilterObj2] = useState({ per_page: 10, search: "", stats: '' });
  const { Permissions } = useContext(PermissionsContext);
  const [ordersCount, setOrderCancelRequestsCount] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
    changeTitle(`OrderCancelRequests`);
  }, [pathname]);
  
  function getallOrderCancelRequests(obj) {
    setLoading(true)
    let filter = { ...obj };
    let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&status=${filter.status}`;
    getOrderCancelRequests(params).then((res) => {
      setallOrderCancelRequests(res.data.data);
        console.log(res?.data)
      let total = res?.data?.overview?.total;
      changeTitle(`OrderCancelRequests (${total})`);
      setPageCount(Math.ceil(res?.data?.data?.length / filter.per_page));
      setOrderCancelRequestsCount(res?.data?.overview);
      setLoading(false)
    });
  }

  useEffect(() => {
    getallOrderCancelRequests({...filterObj, status});
    // eslint-disable-next-line
  }, [status]);

  function changeFilterObj(e) {
    let thisObj = { ...filterObj2 };
    thisObj[e.target.name] = e.target.value.trim();
    setfilterObj2(thisObj);
  }

  function getFiltered() {
    let thisObj = { ...filterObj };
    thisObj.page = 1;
    thisObj.per_page = filterObj2.per_page;
    thisObj.search = filterObj2.search;
    console.log(thisObj)
    setfilterObj(thisObj);
    getallOrderCancelRequests(thisObj);
  }

  function changeStatus(s) {
    navigate(`${basename}/dashboard/order-cancel-requests?status=${s}`);
    setStatus(s);
  }

  const handlepageChange = async (data) => {
    let filter = { ...filterObj };
    filter.page = data.selected + 1;
    setfilterObj(filter);
    getallOrderCancelRequests(filter);

    let thisObj2 = { ...filterObj2 };
    thisObj2.per_page = filter.per_page;
    thisObj2.search = filter.search;
    setfilterObj2(thisObj2);
  };

  
  function acceptOrderCancelRequestHandler(id) {
    acceptOrderCancelRequest(id)
      .then((res) => {
        getallOrderCancelRequests(filterObj);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
  
  function rejectOrderCancelRequestHandler(id) {
    rejectOrderCancelRequest(id)
      .then((res) => {
        getallOrderCancelRequests(filterObj);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  return (
    <>
      {
        <section className="componentGlobalWrapper d-flex flex-column">
          <div>
            <div
              className="tabs-container nav nav-tabs d-flex align-items-center"
              id="myTab"
              role="tablist"
            >
              <div
                onClick={() => changeStatus(1)}
                className={`tablinks ${status === 1 && `active`}`}
                id="Gallery-tab"
                data-bs-toggle="tab"
                data-bs-target="#allnews"
              >
                active{" "}
                <span className="tabcounter">({(ordersCount?.["status_progress_count"] ?? 0) + (ordersCount?.['status_submission_count'] ?? 0)})</span>
              </div>
              <span className="separator"></span>

              <div
                onClick={() => changeStatus(2)}
                className={`tablinks ${status === 2 && `active`}`}
                id="published-tab"
                data-bs-toggle="tab"
                data-bs-target="#published-Img"
              >
                Pending{" "}
                <span className="tabcounter">
                  {" "}
                  ({ordersCount?.["status_pending_count"]}){" "}
                </span>
              </div>
              <span className="separator"></span>
            </div>
            <div className="searchActionsBar w-100">
              {/*** search input ***/}
              <div className="searchActionsBar mt-0 mb-0">
                {/*** search input ***/}
                <div className="searchInputWrapper">
                  <div>
                    <input
                      type="text"
                      className="barSearchInput"
                      name="search"
                      onChange={changeFilterObj}
                    />
                  </div>
                  <img
                    src={search}
                    alt="search-icon"
                    className="barSearchIcon"
                  />
                </div>
              </div>

              {/* show select */}
              <div className="perPageContainer">
                <label htmlFor="selectShow" className="perPageLabel">
                  show
                </label>
                <select
                  name="per_page"
                  onChange={changeFilterObj}
                  id={`selectShow`}
                  className="form-select selectShow"
                  value={filterObj2?.per_page}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
              {/*** apply search btn  ***/}
              <button className="applySearchBtn" onClick={getFiltered}>
                search
              </button>
            </div>
            {Permissions?.order?.view && (
              <div className="tableWrapper">
                <table className="w-100 table table-striped serviceTable">
                  <thead>
                    <tr>
                      {/* <th>image</th> */}
                      <th>user</th>
                      <th>order id</th>
                      <th>created at</th>
                      <th>actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    loading? <td className='w-100' colSpan={6}><Loader/></td>
                    :allOrderCancelRequests && allOrderCancelRequests?.length !== 0 ?
                      allOrderCancelRequests.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td> {item?.user?.username ?? "no data added"}</td>
                            <td>
                              <Link to={`/dashboard/orders/${item?.order?.id}`}>
                                #{item?.order?.id}
                              </Link>
                            </td>
                            <td>
                              {item?.created_at
                                ? moment(item?.created_at).format("DD-MM-YYYY")
                                : "no data added"}
                            </td>
                            <td>
                              <div className="d-flex align-items-center ">
                                <Tooltip title="details">
                                  <Link
                                    to={`${basename}/dashboard/orders/${item.order?.id}`}
                                  >
                                    <img
                                      className="iconActions"
                                      src={details}
                                      alt="details"
                                    />
                                  </Link>
                                </Tooltip>

                                {(item?.status?.id === 2) && (
                                  <>
                                    {Permissions?.['order-cancel-request']?.accept && (
                                      <Tooltip title="accept">
                                        <div
                                          className="d-flex"
                                          onClick={() =>
                                            acceptOrderCancelRequestHandler(item.id)
                                          }
                                        >
                                          <img
                                            className="iconActions"
                                            src={acceptIcon}
                                            alt="accept"
                                          />
                                        </div>
                                      </Tooltip>
                                    )}
                                    {Permissions?.['order-cancel-request']?.reject && (
                                      <Tooltip title="reject">
                                        <div
                                          className="d-flex"
                                          onClick={() =>
                                            rejectOrderCancelRequestHandler(item.id)
                                          }
                                        >
                                          <img
                                            className="iconActions"
                                            src={rejectIcon}
                                            alt="accept"
                                          />
                                        </div>
                                      </Tooltip>
                                    )}
                                  </>
                                )}

                                {(item?.status?.id === 1) && (
                                    <Badge badgeContent="accepted" color="success" className="d-block ms-4"></Badge>
                                )}

                                {(item?.status?.id === 3) && (
                                    <Badge badgeContent="rejected" color="error" className="d-block ms-4"></Badge>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      }):<td className='w-100' colSpan={6}><NotDataFound /></td>}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end mt-auto">
            <Pagination
              pagesNumber={pageCount}
              page={filterObj.page - 1}
              pageChangeHandler={handlepageChange}
            />
          </div>
        </section>
      }
    </>
  );
}
