import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import "../tables.scss";
import "../forms.scss";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import { deleteBudget, getAllBudgets } from "../../Apis/budgets";
import DeleteModal from "../../Utilities/deleteModal";
import moment from "moment";
import PermissionsContext from "../../Contexts/permissionsContext";
import UpdateBudget from "./Form/Update";
import CreateBudget from "./Form/Create";
import TitleContext from "../../Contexts/TitleContext";

export default function Budgets() {
	const [allBudgets, setAllBudgets] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();
	const { changeTitle } = useContext(TitleContext);
	const [filterObj, setfilterObj] = useState({
		per_page: 10,
		page: 1,
		from: "",
		to: "",
	});
	const [filterObj2, setfilterObj2] = useState({
		per_page: 10,
		from: "",
		to: "",
	});
	const [selectedBudget, setSelectedBudget] = useState("");
	const [deletedBudget, setDeletedBudget] = useState("");
	const [deletedName, setDeletedName] = useState("budget");

	const { Permissions } = useContext(PermissionsContext);

	useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line
		changeTitle(`Budgets`);
	}, [pathname]);

	function getBudgets(obj) {
		let filter = { ...obj };
		let params = `per_page=${filter.per_page}&page=${filter.page}&from=${filter.from}&to=${filter.to}`;
		getAllBudgets(params).then((res) => {
			setAllBudgets(res.data.data);
			console.log(res.data.data);
			let total = res.data.pagination.total;
			changeTitle(`Budgets (${total})`);
			setPageCount(Math.ceil(total / filter.per_page));
		});
	}

	useEffect(() => {
		getBudgets(filterObj);
		// eslint-disable-next-line
	}, []);

	function changeFilterObj(e) {
		let thisObj = { ...filterObj2 };
		thisObj[e.target.name] = e.target.value.trim();
		//thisObj.page = 1
		setfilterObj2(thisObj);
	}

	function getFiltered() {
		let thisObj = { ...filterObj };
		thisObj.page = 1;
		thisObj.per_page = filterObj2.per_page;
		thisObj.search = filterObj2.search;
		thisObj.from = filterObj2.from;
		thisObj.to = filterObj2.to;
		setfilterObj(thisObj);
		getBudgets(thisObj);
	}

	async function onSubmitDelete() {
		return await deleteBudget(deletedBudget)
			.then((res) => {
				getBudgets(filterObj);
				return "done";
			})
			.catch((err) => {
				console.log(err.response.data.errors.error);
				return err.response.data.errors.error;
			});
	}

	const handlepageChange = async (data) => {
		let filter = { ...filterObj };
		filter.page = data.selected + 1;
		setfilterObj(filter);
		getBudgets(filter);

		let thisObj2 = { ...filterObj2 };
		thisObj2.per_page = filter.per_page;
		thisObj2.search = filter.search;
		thisObj2.from = filter.from;
		thisObj2.to = filter.to;
		setfilterObj2(thisObj2);
	};

	function clickDelete(item) {
		setDeletedBudget(item.id);
		setDeletedName(`${item?.from} : ${item?.to}`);
	}

	return (
		<>
			{
				<section className="componentGlobalWrapper d-flex flex-column">
					<div>
						<div className="searchActionsBar w-100">
							<div className="searchActionsBar mt-0 mb-0">
								{/*** search input ***/}
								{/* price from */}
								<div className="cityFilter">
									<input
										type="number"
										placeholder="price from"
										min={0}
										className="priceFromInput"
										onKeyDown={(e) =>
											["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
										}
										name="from"
										onChange={changeFilterObj}
									/>
								</div>

								{/* price to */}
								<div className="cityFilter">
									<input
										type="number"
										placeholder="price to"
										min={0}
										className="priceFromInput"
										onKeyDown={(e) =>
											["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
										}
										name="to"
										onChange={changeFilterObj}
									/>
								</div>
							</div>

							{/* show select */}
							<div className="perPageContainer">
								<label htmlFor="selectShow" className="perPageLabel">
									show
								</label>
								<select
									name="per_page"
									onChange={changeFilterObj}
									id={`selectShow`}
									className="form-select selectShow"
									value={filterObj2?.per_page}
								>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
								</select>
							</div>

							{/*** apply search btn  ***/}
							<button className="applySearchBtn" onClick={getFiltered}>
								search
							</button>
							{Permissions?.budget?.create && (
								<div className="addModalBtn ms-auto">
									<div
										className={`add_new_showroom`}
										data-bs-toggle="modal"
										data-bs-target="#createModal"
									>
										Add new budget
									</div>
								</div>
							)}
						</div>
						{Permissions?.budget?.view && (
							<div className="tableWrapper">
								<table className="w-100 table table-striped ">
									<thead>
										<tr>
											<th>from</th>
											<th>to</th>
											<th>created at</th>
											<th>no. of projects</th>
											<th>actions</th>
										</tr>
									</thead>
									<tbody>
										{allBudgets &&
											allBudgets.map((budget, index) => {
												return (
													<tr key={index}>
														<td>{budget?.from ? budget?.from : "-"}</td>

														<td>{budget?.to ? budget?.to : "-"}</td>

														<td>
															{budget?.created_at
																? moment(budget?.created_at).format(
																	"DD-MM-YYYY"
																)
																: "-"}
														</td>
														<td>{budget?.projects_count ?? "-"}</td>
														<td>
															<div>
																{Permissions?.budget?.edit && (
																	<Tooltip title="edit">
																		<img
																			onClick={() =>
																				setSelectedBudget(budget.id)
																			}
																			data-bs-toggle="modal"
																			data-bs-target="#updateModal"
																			className="iconActions"
																			src={edit_icon}
																			alt="edit"
																		/>
																	</Tooltip>
																)}
																{Permissions?.budget?.delete && (
																	<Tooltip title="delete this budget">
																		<img
																			onClick={() => clickDelete(budget)}
																			data-bs-toggle="modal"
																			data-bs-target="#deleteModal"
																			className="iconActions"
																			src={delete_icon}
																			alt="delete"
																		/>
																	</Tooltip>
																)}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</div>

					{
						<div className="d-flex justify-content-end mt-auto">
							<Pagination
								pagesNumber={pageCount}
								page={filterObj.page - 1}
								pageChangeHandler={handlepageChange}
							/>
						</div>
					}

					<DeleteModal
						onSubmitDelete={onSubmitDelete}
						name={deletedName}
					></DeleteModal>

					<div
						className="modal fade"
						id="createModal"
						tabIndex={-1}
						aria-hidden="true"
					>
						<CreateBudget getNewBudgets={getBudgets} obj={filterObj} />
					</div>

					<div
						className="modal fade"
						id="updateModal"
						tabIndex={-1}
						aria-hidden="true"
					>
						<UpdateBudget
							id={selectedBudget}
							getNewBudgets={getBudgets}
							obj={filterObj}
						/>
					</div>
				</section>
			}
		</>
	);
}
