import React from 'react'
import Form from './Form';
import { useParams } from 'react-router';
import { updateMainFaq } from '../../../Apis/faq';

export default function UpdateMainFaqs() {
  const { id ,faq_id } = useParams();
    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateMainFaq(faq_id ,data ,id ? 'faqs':'section-faqs').then(res => {
            return 'done';          
        }).catch(err => {
            return err.response.data.errors;
        })
    };

  return (
    <div>
        <Form onSubmit={onSubmit} />
    </div>
  )
}
