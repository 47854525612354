import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getFees(obj){
    return await https.get(`${api_url}dashboard/fees?${obj}`);
}

export async function getFee(id){
    return await https.get(`${api_url}dashboard/fees/${id}`);
}

export async function updateFee(id, data){
    return await https.put(`${api_url}dashboard/fees/${id}`, data);
}

