export const setAdminData = (model, setModel, ele, property) => {
    // if (ele.target.name === 'image') {
    //     if(ele.target.files.length){
    //         newData['image'] = ele.target.files[0];
    //     }
    // }else{
    // }
    let newData = Object.assign({}, model);
    newData[property] = ele.target.value;
    setModel(newData);
}