export const setData = (model, setModel, ele, property ,locale=false, language=null) =>{
    let newData = Object.assign({}, model);
    if(locale){
        newData.locales[language] = {}
        newData.locales[language][property] = ele.target.value;
    }else{
        newData[property] = ele.target.value;
    }
    console.log(newData)
    setModel(newData);
}