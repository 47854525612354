import { https } from './https';
import { api_url } from '../Configurations/config';

export async function getSections(obj){
    return await https.get(`${api_url}dashboard/sections?${obj}`);
}

export async function getSection(id){
    return await https.get(`${api_url}dashboard/sections/${id}`);
}

export async function deleteSection(id){
    return await https.delete(`${api_url}dashboard/sections/${id}`);
}

export async function updateSection(id, data){
    return await https.patch(`${api_url}dashboard/sections/${id}`, data);
}

export async function createSection(data){
    return await https.post(`${api_url}dashboard/sections`, data);
}